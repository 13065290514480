import React, { Component } from 'react';
import { connect } from 'react-redux';

import { requestData } from 'core/ducks/list';
import { Pagination } from 'table';
import { LocationModal } from '../../modals';
import { Answers } from '../../../components';

class VariableAnswers extends Component {

	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			isLocationModalOpen: false,
			activeChoice: null,
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.page !== this.state.page)
			this.fetchData();
	}

	componentWillUnmount() {
		this.setState({isLocationModalOpen: false});
	}

	fetchData = () => {
		const { attribute, variable } = this.props;
		let url = `data/scope/list/page/${this.state.page}/attribute/${attribute}/variable/${variable}`;
		if (this.props.filter)
			url += `/within/${this.props.filter}`;
		this.props.dispatch(
			requestData('answers', url)
		);
	}

	handlePageChange = (page) => {
		if (page !== this.state.page)
			this.setState({page});
	}

	handleLocationSelect = (activeChoice) => {
		this.props.toggleOpenModal(true);
		this.setState({isLocationModalOpen: true, activeChoice});
	}

	handleCloseModal = () => {
		this.props.toggleOpenModal(false);
		this.setState({isLocationModalOpen: false, activeChoice: null});
	}

	render() {
		const { list } = this.props;
		const { isLocationModalOpen, activeChoice } = this.state;
		if (!list.answers || list.answers.pending || list.answers.status !== 200 || Object.keys(list.answers.data.values).length === 0)
			return null;

		const users = Object.keys(list.answers.data.values);

		return (
			<div className={this.props.className ? `variable-answers ${this.props.className}` : 'variable-answers'}>
				{ Object.values(list.answers.data.values).map((userData, index) => (
					<Answers key={`answer_${users[index]}`} profile={userData.profile} list={userData.answers} handleLocationSelect={this.handleLocationSelect}/>
				))}
				<Pagination
					className="mx-auto"
					page={list.answers.data.page}
					total={list.answers.data.total_pages}
					onClick={this.handlePageChange}
				/>
				{ isLocationModalOpen &&
					<LocationModal
						isOpen={isLocationModalOpen}
						toggle={this.handleCloseModal}
						choice={activeChoice}
					/>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	list: state.list,
});

VariableAnswers = connect(mapStateToProps)(VariableAnswers);

export default VariableAnswers;
