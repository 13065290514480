import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Input, FormGroup, Label, Row, Col, } from 'reactstrap';

class InputArray extends Component {

	constructor(props) {
		super(props);
		this.state = {
			values: props.value.length === 0 ? [''] : props.value,
		};

		this.InputRef = React.createRef();

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleArrayLengthChange = this.handleArrayLengthChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	handleInputChange(event, index) {
		const { value } = event.target;
		const values = value !== '' ? [
			...this.state.values.slice(0, index),
			value,
			...this.state.values.slice(index + 1)
		] : [
			...this.state.values.slice(0, index),
			...this.state.values.slice(index + 1)
		];
		this.setState({
			values
		});
		this.props.onChange({
			...event,
			target: {
				...event.target,
				name: this.props.name,
				value: values,
			}
		});
	}

	handleArrayLengthChange() {
		const { values } = this.state;
		if (values[values.length - 1] !== '') {
			this.setState({
				values: [
					...values,
					''
				]
			}, () => {
				this.InputRef.current.focus();
			});
		} else {
			this.InputRef.current.focus();
		}
	}

	handleKeyPress(event) {
		if (event.keyCode === 9) {
			event.preventDefault();
			this.handleArrayLengthChange();
		}
	}

	componentDidMount() {
		ReactDOM.findDOMNode(this).addEventListener("keydown", this.handleKeyPress, false);
	}

	componentWillUnmount() {
		ReactDOM.findDOMNode(this).removeEventListener("keydown", this.handleKeyPress, false);
	}

	render() {

		const { attributes, type } = this.props;
		const { values } = this.state;

		return (
			<div>
				<Label>Choices</Label>
				<Row>
					<Label>{attributes.label}</Label>
					{ values.map((value, index) => (
						<Col sm="6" className="py-0" key={`array_for_${type}_choice_${index}`}>
							<FormGroup>
								<Input
									placeholder={`Choice ${index + 1}`}
									type="text"
									value={value}
									onChange={(e) => {this.handleInputChange(e, index)}}
									innerRef={index === values.length - 1 ? this.InputRef : null}
								/>
							</FormGroup>
						</Col>
					))}
				</Row>
				<i className="fa fa-plus-square float-right" role="button" title="Add choice" onClick={this.handleArrayLengthChange}/>
			</div>
		);
	}
}

export default InputArray;
