import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
	Card, CardBody, CardGroup, CardHeader, CardImg, CardText, CardTitle, Row, Col, Button,
	Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { Loading } from 'core/components';
import { requestData } from 'core/ducks/list';
import { postData } from 'core/ducks/update';
import { pushNotification } from 'core/ducks/notifications';
import { buildPath } from 'core/model/lib/urlTools';
import { fetchAppProfile } from '../../ducks/categoriesprofile';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';

import T from 'modules/i18n';

import '../../style/general.scss';

class OtherCategories extends Component {

	constructor(props) {
		super(props);
		this.state = {
			ready: false,
			autosubscribe_categories: false,
			isModalOpen: false,
		};

		this.handleAutoSubscribe = this.handleAutoSubscribe.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(fetchAppProfile());
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.categoriesProfile.pending && !this.props.categoriesProfile.pending && this.props.categoriesProfile.status === 200 )
			this.props.dispatch(requestData('category'));

		if ((prevProps.categories.pending && !this.props.categories.pending) || (prevProps.categories.refreshing && !this.props.categories.refreshing) && this.props.categories.status === 200)
			this.processCategories();
	}

	processCategories = () => {
		const { enrolled } = this.props.categoriesProfile;
		const categories = this.props.categories.data;
		let profile = enrolled.map(elem => elem.token);
		let show = categories
			.filter(elem => (elem.autosubscribe && elem.active))
			.map(elem => ({...elem, enrolled: profile.includes(elem.token)}));
		this.setState({
			autosubscribe_categories: [...show],
			ready: true,
		});
	}

	handleAutoSubscribe() {
		const { chosenCategory } = this.state;
		this.props.dispatch(
			postData('enrolment', {category: chosenCategory}, false)
		).then((e) => {
			this.props.dispatch(fetchAppProfile(true))
				.then(() => this.props.history.push(buildPath(DynamicRoutes.Category, {category: this.props.categoriesProfile.enrolled.find(elem => elem.token === chosenCategory).mname})));
		}).catch(e => {
			this.props.dispatch(pushNotification({
				body: "autosubscribe error",
				type: "warning"
			}))
		});
	}

	toggleModal() {
		this.setState({
			isModalOpen: false,
			chosenCategory: null,
		});
	}

	render() {

		const { messages } = this.props.i18n || {messages: {}};

		if (!this.state.ready)
			return (<Loading/>);

		return (
			<div className="ppcity animated fadeIn">
				{this.state.autosubscribe_categories.length === 0 ? <div><T>No categories to subscribe</T></div> : ''}
				<Row>
					{ this.state.autosubscribe_categories.map((category) => (
						<Col style={{maxWidth: '30em'}} key={`category_card_${category.token}`}>
							<Button
								id={category.token}
								disabled={category.enrolled}
								title={
									category.enrolled ? `${messages['already enrolled'] || 'Already enrolled'}` : `${messages['click to enroll'] || 'Click to enroll'}`
								}
								onClick={() => this.setState({isModalOpen: true, chosenCategory: category.token})}
							>
								<Card>
									<CardImg src={category.summary_img} alt={category.summary_img_alt}/>
									<CardBody>
										<CardTitle><h4>{category.title}</h4></CardTitle>
										<CardText>{category.summary}</CardText>
									</CardBody>
									{ category.enrolled &&
										<div className="already-subscribed-banner p-2 mx-2 font-weight-bold">
											<T>already_subscribed_banner_msg</T>
											<Link lang="el" to={StaticRoutes.Categories}><T>categories</T></Link>
										</div>
									}
								</Card>
							</Button>
						</Col>
					))}
				</Row>
				<Modal isOpen={this.state.isModalOpen} className="modal-md">
					<ModalHeader toggle={this.toggleModal}><T>do autosubscribe</T></ModalHeader>
					<ModalBody>
						<T>do you wish to subscribe</T>
					</ModalBody>
					<ModalFooter>
						<Button color="success" onClick={this.handleAutoSubscribe}>
							<T>yes</T>
						</Button>
						<Button color="danger" onClick={this.toggleModal}>
							<T>no</T>
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile,
	categoriesProfile: state.categoriesprofile,
	categories: state.list.category,
	i18n: state.i18n,
});

OtherCategories = connect(mapStateToProps)(OtherCategories);

export default OtherCategories;
