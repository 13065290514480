import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Label, Row, Col, FormGroup, Input, Modal, ModalHeader, ModalBody, InputGroup, InputGroupAddon, Button,
} from 'reactstrap';

import { getData } from 'core/ducks/update';

class LibraryInput extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isGalleryOpen: false,
			isPreviewOpen: false,
			items: [],
			library: [],
		};
	}

	componentDidMount() {
		if (this.props.value !== '')
			this.setState({items: JSON.parse(this.props.value).map(elem => elem.uuid)}, () => this.getLibraryData(this.state.items));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.library !== this.state.library)
			this.props.onChange({target: {type: this.props.type, value: JSON.stringify(this.state.library), name: this.props.name}});
	}

	getModalValues = (items) => {
		this.setState({isGalleryOpen: false, items: [...items]});
		this.getLibraryData(items);
	}

	getLibraryData = (items) => {
		this.props.dispatch(
			getData(`library/uuid/${items.join(';')}`)
		).then(library => this.setState({
			library: library.map(elem => {
				const { tags, ...other } = elem;
				return other;
			})
		})).catch(err => console.warn(err));
	}

	render() {
		const { attributes, type, Gallery, Element, T, dispatch, ...otherProps } = this.props;
		const { isGalleryOpen, isPreviewOpen, items, library } = this.state;

		return (
			<>
				<FormGroup className="py-0">
					<Label style={{opacity: this.props.value==='' ? 0 : 1, fontSize: "85%"}} className="animated fadeIn fadeOut my-0">
						{attributes.label}
					</Label>
					<InputGroup>
						<Input
							required
							autoComplete="off"
							placeholder={attributes.label}
							className="w-75"
							placeholder={`${items.length} photos`}
							value={items.length > 0 ? `${items.length} photos` : ''}
							onChange={() => {}}
							onClick={() => this.setState({isGalleryOpen: true})}
						/>
						<InputGroupAddon addonType="append">
							<Button disabled={items.length === 0} onClick={() => this.setState({isPreviewOpen: true})}><T>preview</T></Button>
						</InputGroupAddon>
					</InputGroup>
				</FormGroup>
				{ isGalleryOpen &&
					<Modal isOpen={isGalleryOpen} className="modal-xl" scrollable>
						<ModalHeader toggle={() => this.setState({isGalleryOpen: false})}><T>select from library</T></ModalHeader>
						<ModalBody>
							<Gallery isModal={true} getValues={this.getModalValues} items={items}/>
						</ModalBody>
					</Modal>
				}
				{ isPreviewOpen &&
					<Modal isOpen={isPreviewOpen} className="modal-lg" scrollable>
						<ModalHeader toggle={() => this.setState({isPreviewOpen: false})}><T>preview</T></ModalHeader>
						<ModalBody>
							{ library.map((elem, index) => (
								<Element key={`element_${elem.uuid}`} {...elem} index={index}/>
							))}
						</ModalBody>
					</Modal>
				}
			</>
		);
	}

}

LibraryInput = connect(null)(LibraryInput);

export default LibraryInput;
