import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Button, Modal, ModalBody, Spinner } from 'reactstrap';

import { getData, postData, deleteData, updateData } from 'core/ducks/update';
import { pushNotification } from 'core/ducks/notifications';
import { initContext } from 'core/ducks/context';
import { Alert } from 'core/views/modals';
import { Table, Thead, Tbody, Tr, Td } from 'css-table';
import T from 'modules/i18n';

class LibraryExport extends Component {

	constructor(props) {
		super(props);
		this.state = {
			backups: {},
			ready: false,
			waiting: false,
			whichAlertIsOpen: false,
			activeBackup: null,
			hiddenValue: '',
		};
		this.hiddenValueRef = React.createRef();
		this.fields = ['created', 'size', 'items', 'source', 'restored_date', 'api_client', 'api_secret'];
		this.handleExport = this.handleExport.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(initContext({fetchData: this.fetchData}));
		this.fetchData();
	}

	fetchData() {
		this.props.dispatch(getData('libraryExport'))
			.then(backups => this.setState({backups, ready: true}));
	}

	handleExport() {
		this.setState({waiting: true}, () => {
			this.props.dispatch(postData('libraryExport', null, false))
				.then(() => {
					this.setState({waiting: false});
					this.fetchData();
				})
				.catch(httpStatus => {
					this.setState({waiting: false});
					console.warn(httpStatus);
				});
		});
	}

	handleDelete(filename) {
		this.props.dispatch(deleteData(`libraryExport/filename/${filename}`))
			.then(() => this.fetchData());
	}

	handleRestore = (filename) => {
		this.setState({waiting: true}, () => {
			this.props.dispatch(updateData(`libraryExport/scope/restore-from-server-file/filename/${filename}`, null, false))
				.then(() => {
					this.setState({waiting: false});
					this.props.dispatch(pushNotification({body: "library restored", type: "success"}));
				})
				.catch(httpStatus => {
					this.setState({waiting: false});
					this.props.dispatch(pushNotification({body: "library restore failed", type: "danger"}));
				});
		});
	}

	handleEnableAPI = (filename) => {
		this.props.dispatch(updateData(`libraryExport/scope/enable-api/filename/${filename}`))
			.then(() => this.fetchData());
	}

	handleDisableAPI = (filename) => {
		this.props.dispatch(updateData(`libraryExport/scope/disable-api/filename/${filename}`))
			.then(() => this.fetchData());
	}

	handleCopy = (hiddenValue) => {
		this.setState({hiddenValue}, () => {
			const elem = this.hiddenValueRef.current;
			elem.select();
			elem.setSelectionRange(0, 99999);
			document.execCommand('copy');
			this.props.dispatch(pushNotification({body: "api secret copied to clipboard", type: "success"}));
		});
	}

	render() {
		const { backups, waiting, whichAlertIsOpen, activeBackup } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<div>
				<input ref={this.hiddenValueRef} style={{position: 'absolute', left: '-99999px'}} value={this.state.hiddenValue} onChange={() => {}}/>
				<div className="text-right pb-3">
					<Button onClick={this.handleExport} className="mr-2" color="primary"><T>export</T></Button>
				</div>
				<Card>
					<CardBody>
						<Table>
							<Thead>
								<Tr>
									{ this.fields.map(header =>
										<Td key={`header_${header}`}><T>{header}</T></Td>
									)}
									<Td/>
								</Tr>
							</Thead>
							<Tbody>
								{ Object.keys(backups).map((filename, index) => {
									const row = backups[filename];
									return (
										<Tr key={`backup_row_${index}`}>
											{ this.fields.map(field => (
												<Td key={`backup_row_${index}_col_${field}`}>
													{ field !== 'api_secret' ?
														row[field]
														:
														row[field] &&
														<i
															className="fa fa-clipboard"
															role="link"
															title={messages['copy to clipboard'] || 'Copy to clipboard'}
															onClick={() => this.handleCopy(row[field])}
														/>
													}
												</Td>
											))}
											<Td className="text-right">
												<Button
													color="success"
													outline
													size="sm"
													className="mr-2"
													title={messages.download || 'Download'}
												>
													<a href={`/api/libraryExport/download/${filename}`}>
														<i className="fa fa-download"/>
													</a>
												</Button>
												<Button
													color="primary"
													outline
													size="sm"
													className="mr-2"
													title={messages.restore || 'Restore'}
													onClick={() => this.setState({whichAlertIsOpen: 'restore', activeBackup: filename})}
												>
													<i className="fa fa-window-restore"/>
												</Button>
												<Button
													color="warning"
													outline
													size="sm"
													className="mr-2"
													title={messages['create api key'] || 'Create api key'}
													onClick={() => this.setState({
														whichAlertIsOpen: row.api_secret ? 'disable_api' : 'enable_api',
														activeBackup: filename
													})}
												>
													<i className="fa fa-key"/>
												</Button>
												<Button
													color="danger"
													outline
													size="sm"
													className="mr-2"
													title={messages.delete || 'Delete'}
													onClick={() => this.setState({whichAlertIsOpen: 'delete', activeBackup: filename})}
												>
													<i className="fa fa-trash"/>
												</Button>
											</Td>
										</Tr>
									);
								})}
							</Tbody>
						</Table>
					</CardBody>
				</Card>
				<Modal isOpen={waiting}>
					<ModalBody>
						<div className="text-center">
							<Spinner type="grow" color="warning" />
						</div>
						<div className="text-center">
							<T>please wait for the process to complete</T>
						</div>
					</ModalBody>
				</Modal>
				{ whichAlertIsOpen === 'delete' &&
					<Alert
						toggle={() => this.setState({whichAlertIsOpen: false, activeBackup: null})}
						message="library_export_drop_confirm_msg"
						title="drop confirm"
						onConfirm={() => this.handleDelete(activeBackup)}
					/>
				}
				{ whichAlertIsOpen === 'restore' &&
					<Alert
						toggle={() => this.setState({whichAlertIsOpen: false, activeBackup: null})}
						message="library_restore_confirm_msg"
						title="restore confirm"
						onConfirm={() => this.handleRestore(activeBackup)}
					/>
				}
				{ whichAlertIsOpen === 'enable_api' &&
					<Alert
						toggle={() => this.setState({whichAlertIsOpen: false, activeBackup: null})}
						message="enable_api_confirm_msg"
						title="enable api"
						onConfirm={() => this.handleEnableAPI(activeBackup)}
					/>
				}
				{ whichAlertIsOpen === 'disable_api' &&
					<Alert
						toggle={() => this.setState({whichAlertIsOpen: false, activeBackup: null})}
						message="disable_api_confirm_msg"
						title="disable api"
						onConfirm={() => this.handleDisableAPI(activeBackup)}
					/>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
});

LibraryExport = connect(mapStateToProps)(LibraryExport);

export default LibraryExport;
