import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Row, Col} from 'reactstrap';
import { bindActionCreators } from 'redux';

import {
	Filter, FilterGroup, Table, Title, Button, Tbody, Thead, Toolbox, Tr, Search, Pagination
} from 'table';
import { Loading } from 'core/components';
import { requestData } from 'core/ducks/list';
import T from 'modules/i18n';

class InvitationsTable extends Component {

	constructor(props) {
		super(props);

		this.initialState = {
			query: '',
			sort: 'created',
			sort_method: 'desc',
			accepted: '',
			with_oauth: '',
		};
		this.state = {...this.initialState, page: 1, refreshing: false, data: []};

		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleToolboxReset = this.handleToolboxReset.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
		this.createUrl = this.createUrl.bind(this);

		this.layout = {
			firstname: {sortable: true},
			lastname: {sortable: true},
			email: {sortable: true},
			enrolment: {type: 'array'},
			created: {type: 'date', sortable: true},
			accepted: {type: 'boolean', sortable: true},
			with_oauth: {type: 'boolean', sortable: true},
		};
		this.fields = Object.keys(this.layout);
		this.badge_colors = {
			true: 'success',
			false: 'danger',
		}
	}

	handleFilterChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
			page: 1
		});
	}

	handleToolboxReset() {
		this.setState({
			...this.initialState,
			page: 1
		}, this.requestData);
	}

	handlePageChange(page) {
		this.setState({ page }, this.requestData);
	}

	handleSortChange(sort) {
		if (sort===this.state.sort) {
			this.setState({ sort_method: this.state.sort_method==='asc' ? 'desc' : 'asc'}, this.requestData);
		} else {
			this.setState({ sort }, this.requestData);
		}
	}

	requestData() {
		const url = this.createUrl();
		let promise = new Promise((resolve, reject) => {
			this.setState({refreshing: true}, () => {
				this.props.dispatch(requestData('invitation', url))
					.then(() => {
						this.setState({refreshing: false});
						resolve();
					}).catch(err=> {
						reject();
					});
			});
		});
		return promise;
	}

	createUrl() {
		let { query, sort, sort_method, accepted, with_oauth, page } = this.state;
		let url = ['invitation', `sort/${sort}`, `sort_method/${sort_method}`]
		if (page !== 1)
			url.push(`page/${page}`);
		if (query !== '')
			url.push(`query/${query}`);
		let filters = [];
		if (accepted !== '')
			filters.push(`accepted:${accepted}`);
		if (with_oauth !== '')
			filters.push(`with_oauth:${with_oauth}`);
		filters = filters.join(';');
		if (filters !== '')
			url.push(`fq/${filters}`);
		url = url.join('/');
		return url;
	}

	componentDidMount() {
		this.requestData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.list.invitation && ((prevProps.list.invitation.pending && !this.props.list.invitation.pending) || (prevProps.list.invitation.refreshing && !this.props.list.invitation.refreshing))) {
			const data = this.props.list.invitation.data.values.map(row => {
				let enrolment = JSON.parse(row.enrolment);
				enrolment = Object.keys(enrolment).map(key => `${key}: ${enrolment[key].role}`);
				return {...row, enrolment};
			});
			this.setState({data});
		}

		if (prevState.page !== this.state.page || prevState.query !== this.state.query || prevState.sort !== this.state.sort || prevState.sort_method !== this.state.sort_method || prevState.accepted !== this.state.accepted || prevState.with_oauth !== this.state.with_oauth)
			this.requestData();
	}

	render() {
		const { messages } = this.props.i18n || {messages: {}};
		if (!this.props.list.invitation || this.props.list.invitation.pending)
			return <Loading />;
		const { values, ...info } = this.props.list.invitation.data;

		return (
			<Row>
				<Col xs="12" lg="12">
					<Table>
						<Title>
							<T>invitations</T>
							<Button type="toolbox" title="filters" className="float-right"/>
						</Title>
						<Toolbox onReset={this.handleToolboxReset}>
							<Row>
								<Col xs="12" lg="8" className="form-group text-right py-0">
									<Search placeholder={`${messages.search || 'search'}...`} onChange={this.handleFilterChange} name="query"/>
								</Col>
								<Col xs="12" lg="4" className="py-0">
									<FilterGroup>
										<Filter onChange={this.handleFilterChange} name="accepted" defaultValue={this.state.accepted} >
											<option value="">{`${messages.accepted || 'accepted'}`}</option>
											<option value="true">{messages.yes || 'yes'}</option>
											<option value="false">{messages.no || 'no'}</option>
										</Filter>
									</FilterGroup>
									<FilterGroup>
										<Filter onChange={this.handleFilterChange} name="with_oauth" defaultValue={this.state.with_oauth} >
											<option value="">{`${messages.with_oauth || 'with oauth'}`}</option>
											<option value="true">{messages.yes || 'yes'}</option>
											<option value="false">{messages.no || 'no'}</option>
										</Filter>
									</FilterGroup>
								</Col>
							</Row>
							<Row>
								<Col className="py-0 text-right">
									<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
								</Col>
							</Row>
						</Toolbox>
						<Thead>
							<Tr
								data={[...this.fields]}
								sortBy={this.state.sort}
								sortMethod={this.state.sort_method}
								onClick={this.handleSortChange} layout={this.layout}
							/>
						</Thead>
						<Tbody refreshing={this.state.refreshing}>
							<Tr data={this.state.data} layout={this.layout} badge_colors={this.badge_colors} order={this.fields}/>
						</Tbody>
						<Pagination
							className="mx-auto"
							page={info.page}
							total={info.total_pages}
							onClick={(page) => {
								if (page !== info.page)
									this.handlePageChange(page);
							}}
						/>
					</Table>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	list: state.list,
	i18n: state.i18n,
});

InvitationsTable = connect(mapStateToProps)(InvitationsTable);

export default InvitationsTable;
