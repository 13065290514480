import * as roles from 'core/model/roles';

const Categories = '/participate';
const OtherCategories = '/enroll';
const AdminDataStatic = '/admin/data';

export const StaticRoutes = {
	Categories,
	OtherCategories,
	AdminDataStatic,
};

const Category = OtherCategories + '/category/:category?';
const QuestionnaireDetails = Categories + '/category/:category/project/:project';
const AdminDataDynamic = AdminDataStatic + '/category/:category/project/:project';
const AdminData = AdminDataStatic + '/(category)?/:category?';
const InvitationAccept = '/invitation/accept/:hash'

export const DynamicRoutes = {
	Category,
	QuestionnaireDetails,
	AdminDataDynamic,
	AdminData,
};

export const routes = [
	{
		url: Categories,
		name: 'categories',
		to: AdminDataStatic,
		role: roles.REVIEWER,
		exact: true,
	},
	{
		url: Categories,
		name: 'categories',
		component: 'Categories',
		role: [roles.AUTHORIZED],
		exact: true,
	},
	{
		url: Category,
		name: 'category',
		component: 'Category',
		role: [roles.AUTHORIZED],
	},
	{
		url: OtherCategories,
		name: 'othercategories',
		component: 'OtherCategories',
		role: [roles.AUTHORIZED],
	},
	{
		url: QuestionnaireDetails,
		name: 'questionnaire',
		to: AdminDataDynamic,
		role: roles.REVIEWER,
		contextComponent: 'QuestionnaireSidebar',
	},
	{
		url: QuestionnaireDetails,
		name: 'questionnaire',
		component: 'Questionnaire',
		role: [roles.AUTHORIZED],
		contextComponent: 'QuestionnaireSidebar',
	},
	{
		url: AdminDataStatic,
		name: 'categories',
		component: 'Categories',
		role: roles.REVIEWER,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: AdminData,
		name: 'categories',
		component: 'Categories',
		role: roles.REVIEWER,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: AdminDataDynamic,
		name: 'data',
		component: 'Questionnaire',
		role: roles.REVIEWER,
		contextComponent: 'QuestionnaireSidebar',
	},
	{
		url: InvitationAccept,
		name: 'invitation',
		to: '/',
		role: roles.AUTHORIZED,
	},
	{
		url: InvitationAccept,
		name: 'invitation',
		component: 'InvitationAccept',
		role: roles.GUEST,
	}
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
