import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Card, Button, CardImg, CardBody, CardTitle, CardText} from 'reactstrap';
import {DynamicRoutes} from '../model/routes';
import {buildPath} from 'core/model/lib/urlTools';
import * as roles from 'core/model/roles';

class ProjectCard extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const {categoryToken, categoryMname, projectTitle, projectMname, projectSummary, icon, icon_alt, role } = this.props;
        const url = role === roles.AUTHORIZED
            ? buildPath(DynamicRoutes.QuestionnaireDetails, [categoryMname, projectMname])
            : buildPath(DynamicRoutes.AdminDataDynamic, [categoryMname, projectMname]);

        return (
            <Link to={url}>
                <Card>
                    <CardImg src={icon} alt={icon_alt}/>
                    <CardBody>
                        <CardTitle><h4>{projectTitle}</h4></CardTitle>
                        <CardText>{projectSummary}</CardText>
                    </CardBody>
                </Card>
            </Link>
        );
    }
}

ProjectCard.propTypes = {
    categoryToken: PropTypes.node,
    categoryMname: PropTypes.node,
    projectToken: PropTypes.node,
    projectMname: PropTypes.node,
    projectTitle: PropTypes.node,
    projectSummary: PropTypes.node,
    icon: PropTypes.node,
    icon_alt: PropTypes.string
};

export default ProjectCard;
