import { Api } from '../api';
import { requestProfile, checkCookie } from './profile';
import { pushNotification } from './notifications';


// Actions

const REQUESTING_DATA = 'api/data/REQUESTING';
const SENDING_DATA = 'api/data/SENDING';
const UPDATED_DATA = 'api/data/UPDATED';
const FETCHED_DATA = 'api/data/FETCHED';
const POSTED_DATA = 'api/data/POSTED';
const DELETED_DATA = 'api/data/DELETED';
const DELETE_FAILED = 'api/data/DELETE_FAILED';
const UPDATE_FAILED = 'api/data/UPDATE_FAILED';
const CLEAR_STATE = 'update/data/CLEARED';


// Initial state

const initialState = {
	pending: false,
	sending: false,
	status: '',
	response: {},
	message: {},
	errorMsg: ''
};


// Reducer

export default (state=initialState, action) => {
	switch (action.type) {
		case SENDING_DATA:
			return {
				...state,
				sending: action.sending,
			}

		case UPDATED_DATA:
		case POSTED_DATA:
			return {
				...state,
				sending: action.sending,
				status: action.status,
				message: action.message,
				errorMsg: action.message,
			}

		case UPDATE_FAILED:
		case DELETE_FAILED:
			return {
				...state,
				sending: action.sending,
				status: action.status,
				errorMsg: action.errorMsg,
			}

		case REQUESTING_DATA:
		case FETCHED_DATA:
			return {
				...state,
				pending: action.pending,
				status: action.status,
				response: action.response,
				errorMsg: action.errorMsg
			};

		case DELETED_DATA:
			return {
				...state,
				status: action.status,
				errorMsg: action.errorMsg,
			}

		case CLEAR_STATE:
			return {
				...state,
				...initialState
			}

		default:
			return state;
	}
}


// Action creators

const sendingData = () => ({
	type: SENDING_DATA,
	...initialState,
	sending: true
});

const requestingData = () => ({
	type: REQUESTING_DATA,
	status: initialState.status,
	pending: true
});

const updatedData = (status, response) => ({
	type: UPDATED_DATA,
	sending: false,
	status,
	message: response,
	errorMsg: ''
});

const fetchedData = (status, response) => ({
	type: FETCHED_DATA,
	pending: false,
	status,
	response,
	errorMsg: ''
});

const postedData = (status, response) => ({
	type: POSTED_DATA,
	sending: false,
	status,
	message: response,
	errorMsg: ''
});

const deletedData = (status) => ({
	type: DELETED_DATA,
	status,
	errorMsg: ''
});

const deleteFailed = (status, errorMsg) => ({
	type: DELETE_FAILED,
	sending: false,
	status,
	errorMsg,
});

const updateFailed = (status, errorMsg) => ({
	type: UPDATE_FAILED,
	sending: false,
	status,
	errorMsg
});

export const clearState = () => ({
	type: CLEAR_STATE,
});


// Thunk action creators

export const updateData = (scope, data, notify=true) => (dispatch, getState) => {
	let promise = new Promise((resolve, reject) => {
		dispatch(checkCookie()).then(() => {
			dispatch(sendingData());
			let a = new Api(scope);
			let status = '';
			a.Put(data).then((response) => {
				status = response.status;
				return response.json();
			}).then((json) => {
				if (status === 200) {
					dispatch(updatedData(status, json));
					if (notify)
						dispatch(pushNotification({body: 'successful update', type: 'success'}));
					if (scope === 'profile')
						dispatch(requestProfile());
					resolve(json);
				} else {
					dispatch(updateFailed(status, json.Error));
					if (notify)
						dispatch(pushNotification({body: 'action denied', type: 'warning'}));
					reject(status);
				}
			});
		});
	});

	return promise;
}

export const getData = (scope, api=true, type='json') => (dispatch) => {
	let promise = new Promise((resolve, reject) => {
		dispatch( checkCookie() ).then(() => {
			dispatch(requestingData());
			let a = new Api(scope, api);
			let status = '';
			a.Get().then((response) => {
				status = response.status;
				return type === 'json' ? response.json() : response.blob();
			}).then((json) => {
				if (status === 200) {
					dispatch(fetchedData(status, json));
					resolve(json);
				} else {
					dispatch(updateFailed(status, json.Error));
					dispatch(pushNotification({body: 'action denied', type: 'warning'}));
					reject(status);
				}
			});
		});
	});

	return promise;
}

export const postData = (scope, data, notify=true) => (dispatch) => {
	let promise = new Promise((resolve, reject) => {
		dispatch( checkCookie() ).then(() => {
			dispatch(sendingData());
			let a = new Api(scope);
			let status = '';
			a.Post(data).then((response) => {
				status = response.status;
				return response.json();
			}).then((json) => {
				if (status === 200) {
					dispatch(postedData(status, json));
					if (notify)
						dispatch(pushNotification({body: 'data posted', type: 'success'}));
					resolve(json);
				} else if (status === 409) {
					dispatch(updateFailed(status, json.Error));
					reject(status);
				} else {
					dispatch(updateFailed(status, json.Error));
					if (notify)
						dispatch(pushNotification({body: 'action denied', type: 'warning'}));
					reject(status);
				}
			});
		});
	});

	return promise;
}

export const deleteData = (scope, notify=true) => (dispatch) => {
	let promise = new Promise((resolve, reject) => {
		dispatch( checkCookie() ).then(() => {
			let a = new Api(scope);
			let status = '';
			a.Delete().then((response) => {
				status = response.status;
				return response.json;
			}).then((json) => {
				if (status === 200) {
					dispatch(deletedData(status));
					if (notify)
						dispatch(pushNotification({body: 'data deleted', type: 'success'}));
					resolve(json);
				} else {
					dispatch(deleteFailed(status, json.Error));
					if (notify)
						dispatch(pushNotification({body: 'action denied', type: 'warning'}));
					reject(status);
				}
			});
		});
	});

	return promise;
}
