import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody, Row, Col, Button, Input, Form, FormText } from 'reactstrap';

import { getData, updateData, deleteData } from 'core/ducks/update';
import { Alert, UploadImage } from 'core/views/modals';
import { uploadData } from 'core/ducks/upload';
import { ProfileQuestion } from '../modals';

import T from 'modules/i18n';

class ConsultationProfile extends Component {

	constructor(props) {
		super(props);

		this.emptyOpenInput = {scope: null, id: null};
		this.emptyNewChoice = {icon: null, content: '', weight: ''};
		this.state = {
			isNewQuestionModalOpen: false,
			pending: true,
			data: [],
			role: 'normal',
			openInput: {...this.emptyOpenInput},
			isNewChoiceOpen: null,
			newChoiceValues: { ...this.emptyNewChoice },
			previewUrl: null,
			isDropAlertOpen: null,
			isChoiceAlertOpen: null,
		};

		this.inputRef = React.createRef();
		this.iconInputRef = React.createRef();

		this.fetchData = this.fetchData.bind(this);
		this.updateData = this.updateData.bind(this);
		this.updateMainData = this.updateMainData.bind(this);
		this.getModalValues = this.getModalValues.bind(this);
		this.updateQuestionOrder = this.updateQuestionOrder.bind(this);
		this.updateChoiceOrder = this.updateChoiceOrder.bind(this);
		this.addChoice = this.addChoice.bind(this);
		this.handleNewChoiceChange = this.handleNewChoiceChange.bind(this);
		this.dropQuestion = this.dropQuestion.bind(this);
	}

	componentDidMount() {
		const { activeCategory } = this.props;
		if (activeCategory && activeCategory !== '')
			this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.activeCategory !== this.props.activeCategory)
			this.fetchData();

		if ((!prevState.openContent && this.state.openContent) || (!prevState.openWeight && this.state.openWeight))
			this.inputRef.current.focus();

		if (prevState.role !== this.state.role)
			this.fetchData();

		if (prevState.newChoiceValues.icon && !this.state.newChoiceValues.icon) {
			this.setState({previewUrl: null});
		} else if (prevState.newChoiceValues.icon !== this.state.newChoiceValues.icon) {
			this.setState({previewUrl: URL.createObjectURL(this.state.newChoiceValues.icon)});
		}

	}

	fetchData() {
		this.props.dispatch(
			getData(`consultationProfile/category/${this.props.activeCategory}/role/${this.state.role}`)
		)
		.then(data => this.setState({data, pending: false, openInput: {...this.emptyOpenInput}}))
		.catch(err => {
			console.warn(err);
			this.setState({pending: false});
		});
	}

	updateData(id, data) {
		this.props.dispatch(
			updateData(`consultationWeight/id/${id}`, data)
		).then(this.fetchData);
	}

	updateMainData(token, data) {
		this.props.dispatch(
			updateData(`consultationProfile/token/${token}`, data)
		).then(this.fetchData);
	}

	getModalValues(values) {
		const { files } = values;
		const url = `consultationWeight/id/${this.state.openInput.id}`;
		const formData = new FormData();
		formData.append('_method', 'PUT');
		formData.append('icon', files[0]);
		this.props.dispatch( uploadData(url, formData) )
			.then(this.fetchData)
			.finally(() => this.setState({openInput: {...this.emptyOpenInput}}));
	}

	updateQuestionOrder(token, order) {
		const tokens = this.state.data.map(question => question.token);
		const index = tokens.indexOf(token);
		tokens.splice(index, 1);
		tokens.splice(order, 0, token);
		this.props.dispatch(
			updateData('consultationProfile/sortOrder/', tokens, false)
		).then(this.fetchData);
	}

	updateChoiceOrder(question, choice, order) {
		const indices = this.state.data.find(elem => elem.token === question).choices.map(elem => elem.id);
		const index = indices.indexOf(choice);
		indices.splice(index, 1);
		indices.splice(order, 0, choice);
		this.props.dispatch(
			updateData('consultationWeight/sortOrder/', indices, false)
		).then(this.fetchData);
	}

	addChoice(event) {
		event.preventDefault();
		const { newChoiceValues, isNewChoiceOpen } = this.state;
		const formData = new FormData();
		formData.append('question', isNewChoiceOpen);
		Object.keys(newChoiceValues).forEach(key => formData.append(key, newChoiceValues[key]));
		this.props.dispatch(
			uploadData('consultationWeight', formData)
		).then(() => {
			this.setState({isNewChoiceOpen: null, newChoiceValues: {...this.emptyNewChoice}});
			this.fetchData();
		});
	}

	handleNewChoiceChange(event) {
		const { name, type, value, files } = event.target;
		this.setState({
			newChoiceValues: {
				...this.state.newChoiceValues,
				[name]: type==='file' ? files[0] : value,
			}
		});
	}

	dropQuestion() {
		this.props.dispatch(
			deleteData(`consultationProfile/token/${this.state.isDropAlertOpen}`)
		).then(() => this.fetchData());
	}

	dropChoice() {
		this.props.dispatch(
			deleteData(`consultationWeight/id/${this.state.isChoiceAlertOpen}`)
		).then(() => this.fetchData());
	}

	render() {
		const { activeCategory } = this.props;
		const { isNewQuestionModalOpen, data, openInput, role, isNewChoiceOpen, newChoiceValues, previewUrl, isDropAlertOpen, isChoiceAlertOpen } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		if (!activeCategory || activeCategory === '')
			return (
				<Card className="animated fadeIn">
					<CardBody>
						<T>please check or create a category</T>
					</CardBody>
				</Card>
			);

		return (
			<Card className="ppcity-admin animated fadeIn">
				<CardHeader>
					<T>consultation profile</T>
					<span className="float-right">
						<span className="mr-3" style={{textTransform: 'capitalize', fontVariant: 'small-caps'}}><T>for role</T>:</span>
						<Input
							style={{display: 'inline-block', width: 'inherit'}}
							type="select"
							onChange={(e) => this.setState({role: e.target.value})}
							value={role}
						>
							<option value="expert">{`${messages.expert || 'expert'}`}</option>
							<option value="normal">{`${messages.normal || 'normal'}`}</option>
						</Input>
					</span>
				</CardHeader>
				<CardBody>
					<FormText>
						<T>click on element to edit</T>
					</FormText>
					{ data.map((content, sortOrder) => (
						<Row key={`question_${content.token}`} className="border rounded m-2">
							<Col>
								<Row>
									<Col className="py-0">
										<FormText
											className="mr-2 float-right d-block"
											onClick={() => this.setState({openInput: {scope: 'questionWeight', id: content.token}})}
										>
										<T>weight</T>
											{ (openInput.scope === 'questionWeight' && openInput.id === content.token) ?
												<input
													ref={this.inputRef}
													className="d-inline-block form-control w-75 float-right"
													defaultValue={content.weight}
													onKeyDown={(e) => {
														if (e.key === 'Escape') this.setState({openInput: {...this.emptyOpenInput}});
														if (e.key === 'Enter')
															this.updateMainData(content.token, {weight: this.inputRef.current.value});
													}}
												/>
												:
												<span>{`: ${content.weight}`}</span>
											}
										</FormText>
									</Col>
								</Row>
								<Row>
									<Col className="py-0 mx-2 border-bottom">
										<span className="float-left mb-1">
											{ (openInput.scope === 'question' && openInput.id === content.token) ?
												<input
													ref={this.inputRef}
													className="d-inline-block form-control"
													defaultValue={content.question}
													onKeyDown={(e) => {
														if (e.key === 'Escape') this.setState({openInput: {...this.emptyOpenInput}});
														if (e.key === 'Enter')
															this.updateMainData(content.token, {question: this.inputRef.current.value});
													}}
												/>
												:
												<b onClick={() => this.setState({openInput: {scope: 'question', id: content.token}})}>
													{content.question}
												</b>
											}
										</span>
										<span className="float-right mb-1">
											<i
												className="fa fa-trash-o mr-2"
												role="button"
												onClick={() => this.setState({isDropAlertOpen: content.token})}
											/>
											{ sortOrder !== 0 &&
												<i
													className="fa fa-long-arrow-up mx-0 px-0"
													role="button"
													onClick={() => this.updateQuestionOrder(content.token, sortOrder - 1)}
												/>
											}
											{ sortOrder !== data.length - 1 &&
												<i
													className="fa fa-long-arrow-down mx-0 px-0"
													role="button"
													onClick={() => this.updateQuestionOrder(content.token, sortOrder + 1)}
												/>
											}
										</span>
										<span className="clearfix"/>
									</Col>
								</Row>
								{ content.choices.map((choice, choiceSortOrder) => (
									<Row key={`${content.token}_choice_${choice.id}`} className="pl-5">
										<Col className="mb-2">
											<img
												width="72"
												height="72"
												src={choice.icon}
												alt={`choice ${choice.id}`}
												onClick={() => this.setState({openInput: {scope: 'icon', id: choice.id}})}
											/>
											{ (openInput.scope === 'content' && openInput.id === choice.id) ?
												<input
													ref={this.inputRef}
													className="d-inline-block w-75 ml-5 form-control"
													defaultValue={choice.content}
													onKeyDown={(e) => {
														if (e.key === 'Escape') this.setState({openInput: {...this.emptyOpenInput}});
														if (e.key === 'Enter')
															this.updateData(choice.id, {content: this.inputRef.current.value});
													}}
												/>
												:
												<em className="ml-5" onClick={() => this.setState({openInput: {scope: 'content', id: choice.id}})}>
													{choice.content}
												</em>
											}
											<span className="float-right">
												<i
													className="fa fa-trash-o"
													role="button"
													onClick={() => this.setState({isChoiceAlertOpen: choice.id})}
												/>
												{ choiceSortOrder !== 0 &&
													<i
														className="fa fa-long-arrow-up mx-0 px-0"
														role="button"
														onClick={() => this.updateChoiceOrder(content.token, choice.id, choiceSortOrder - 1)}
													/>
												}
												{ choiceSortOrder !== content.choices.length - 1 &&
													<i
														className="fa fa-long-arrow-down mx-0 px-0"
														role="button"
														onClick={() => this.updateChoiceOrder(content.token, choice.id, choiceSortOrder + 1)}
													/>
												}
											</span>
											{ (openInput.scope === 'weight' && openInput.id === choice.id) ?
												<input
													ref={this.inputRef}
													className="float-right d-inline-block form-control"
													style={{width: '100px'}}
													type="number"
													defaultValue={choice.weight}
													onKeyDown={(e) => {
														if (e.key === 'Escape') this.setState({openInput: {...this.emptyOpenInput}});
														if (e.key === 'Enter')
															this.updateData(choice.id, {weight: this.inputRef.current.value});
													}}
												/>
												:
												<span
													className="float-right mr-5"
													style={{fontVariant: 'small-caps', fontSize: '80%', color: 'gray'}}
													onClick={(e) => this.setState({openInput: {scope: 'weight', id: choice.id}})}
												>
													<T>weight</T>: {choice.weight}
												</span>
											}
										</Col>
									</Row>
								))}
								{ (isNewChoiceOpen && isNewChoiceOpen === content.token) ?
									<Form onSubmit={this.addChoice}>
										<Row className="pl-5 mb-2">
											<Col className="pb-0 mb-0">
												<div
													className="d-inline-block border"
													style={{width: '72px', height: '72px', fontSize: '70%', padding: 'inherit'}}
													onClick={() => this.iconInputRef.current.click()}
												>
													{ previewUrl ?
														<img src={previewUrl} className="w-100 h-auto" alt="Uploaded" />
														:
														<T>click to browse</T>
													}
													<input
														ref={this.iconInputRef}
														className="d-none"
														type="file"
														accepts="image/*"
														name="icon"
														onChange={this.handleNewChoiceChange}
													/>
												</div>
												<Input
													required
													className="d-inline-block w-75 ml-5"
													placeholder={`${messages.choice || 'choice'}`}
													name="content"
													value={newChoiceValues.content}
													onChange={this.handleNewChoiceChange}
												/>
												<Input
													required
													className="d-inline-block ml-5"
													style={{width: '100px'}}
													type="number"
													placeholder={`${messages.weight || 'weight'}`}
													name="weight"
													value={newChoiceValues.weight}
													onChange={this.handleNewChoiceChange}
												/>
												<span style={{position: 'absolute', left: '-2rem', display: 'inline-grid'}}>
													<Button style={{backgroundColor: 'transparent', padding: '5px', margin: '1px 5px', width: 'fit-content'}}>
														<i
															style={{fontSize: '120%', width: '1rem'}}
															className="fa fa-check text-success mr-0"
															title={`${messages.submit || 'Submit'}`}
														/>
													</Button>
													<Button style={{backgroundColor: 'transparent', padding: '5px', margin: '1px 5px', width: 'fit-content'}} onClick={() => this.setState({isNewChoiceOpen: null, newChoiceValues: {...this.emptyNewChoice}})}>
														<i
															style={{fontSize: '120%', width: '1rem'}}
															className="fa fa-times text-danger mr-0"
															title={`${messages.cancel || 'Cancel'}`}
														/>
													</Button>
												</span>
											</Col>
										</Row>
									</Form>
									:
									<Row>
										<Col className="text-right mb-2">
											<i
												style={{fontSize: '150%'}}
												className="fa fa-plus-square-o mr-2"
												title={`${messages['add choice'] || 'Add choice'}`}
												onClick={() => this.setState({isNewChoiceOpen: content.token})}
											/>
										</Col>
									</Row>
								}
							</Col>
						</Row>
					))}
					<Row>
						<Col className="text-right mb-3 mr-2">
							<Button color="success" onClick={() => this.setState({isNewQuestionModalOpen: true})}>
								<i className="fa fa-plus"/>{' '}
								<T>add question</T>
							</Button>
						</Col>
					</Row>
				</CardBody>
				{ isNewQuestionModalOpen &&
					<ProfileQuestion
						isOpen={isNewQuestionModalOpen}
						toggle={() => {
							this.setState({isNewQuestionModalOpen: !isNewQuestionModalOpen}, this.fetchData);
						}}
						category={activeCategory}
						role={role}
						content={this.state.content}
					/>
				}
				{ openInput.scope === 'icon' &&
					<UploadImage
						isModalOpen={openInput.scope === 'icon'}
						getValues={this.getModalValues}
						toggle={() => this.setState({openInput: {...this.emptyOpenInput}})}
						values={{file: ''}}
						hasAlt={false}
					/>
				}
				{ isDropAlertOpen &&
					<Alert
						toggle={() => this.setState({isDropAlertOpen: null})}
						message="question_drop_confirm_msg"
						title="drop confirm"
						onConfirm={() => this.dropQuestion()}
					/>
				}
				{ isChoiceAlertOpen &&
					<Alert
						toggle={() => this.setState({isChoiceAlertOpen: null})}
						message="choice_drop_confirm_msg"
						title="drop confirm"
						onConfirm={() => this.dropChoice()}
					/>
				}
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({
	activeCategory: state.project.activeCategory,
	i18n: state.i18n,
});

ConsultationProfile = connect(mapStateToProps)(ConsultationProfile);

export default ConsultationProfile;
