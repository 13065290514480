import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';

import { Table, Thead, Tbody, Tr, Td } from 'css-table';
import { File } from 'input-fields';
import { uploadData } from 'core/ducks/upload';

import T from 'modules/i18n';

class ProfileQuestion extends Component {

	constructor(props) {
		super(props);
		this.emptyChoice = {content: '', icon: {}, weight: 0};
		this.state = {
			question: '',
			weight: '',
			choices: [this.emptyChoice],
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChoiceChange = this.handleChoiceChange.bind(this);
	}

	handleSubmit(event) {
		event.preventDefault();
		const { question, weight, choices } = this.state;
		const formData = new FormData();
		formData.append('question', question);
		formData.append('category', this.props.category);
		formData.append('role', this.props.role);
		formData.append('weight', weight);
		choices.forEach((choice, index) => {
			Object.keys(choice).forEach(attr => {
				if (attr !== 'icon') {
					formData.append(`choices[${index}][${attr}]`, choice[attr]);
				} else {
					formData.append(`icons_${index}`, choice[attr]);
				}
			});
		});

		this.props.dispatch( uploadData('ConsultationProfile', formData) )
			.then(response => {
				if (typeof this.props.toggle === 'function')
					this.props.toggle();
			})
			.catch(err => console.warn(err));
	}

	handleChange(event) {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	}

	handleChoiceChange(event, index) {
		const { value, name, type } = event.target;
		const { choices } = this.state;
		const choice = {
			...this.state.choices[index],
			[name]: type === 'file' ? event.target.files[0] : value,
		};
		this.setState({
			choices: [
				...choices.slice(0, index),
				choice,
				...choices.slice(index + 1),
			]
		});
	}

	render() {
		const { isOpen, toggle } = this.props;
		const { question, weight, choices } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<Modal className="ppcity-admin modal-lg" isOpen={isOpen}>
				<ModalHeader toggle={toggle}><T>new question</T></ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						<FormGroup tag="fieldset" className="p-2">
							<legend><T>question</T></legend>
							<Input required name="question" value={question} onChange={this.handleChange}/>
							<Input
								required
								type="number"
								name="weight"
								value={weight}
								className="w-50 m-2 float-right"
								onChange={this.handleChange}
								placeholder={`${messages.weight || 'weight'}`}
							/>
						</FormGroup>
						<FormGroup tag="fieldset" className="p-2">
							<legend><T>choices</T></legend>
							<Table>
								<Thead>
									<Tr>
										<Td><T>choice</T></Td>
										<Td><T>icon</T></Td>
										<Td><T>weight</T></Td>
										<Td/>
									</Tr>
								</Thead>
								{ choices.map((choice, index) => (
									<Tr key={`choice_${index}`}>
										<Td>
											<Input required name="content" value={choice.content} onChange={(e) => this.handleChoiceChange(e, index)}/>
										</Td>
										<Td>
											<File required={true} name="icon" value={choice.icon} accept="image/*" multiple={false} onChange={(e) => this.handleChoiceChange(e, index)}/>
										</Td>
										<Td>
											<Input required type="number" name="weight" value={choice.weight} onChange={(e) => this.handleChoiceChange(e, index)}/>
										</Td>
										<Td>
											<i
												role="button"
												className="fa fa-minus"
												onClick={() => {
													this.setState({
														choices: [...choices.slice(0, index), ...choices.slice(index + 1)]
													});
												}}
											/>
										</Td>
									</Tr>
								))}
							</Table>
							<div className="float-right">
								<i
									role="button"
									className="fa fa-plus mr-2"
									onClick={() => {
										this.setState({choices: [...choices, this.emptyChoice]});
									}}
								/>
							</div>
						</FormGroup>
						<div className="text-right">
							<Button color="warning" className="m-2"><T>submit</T></Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
});

ProfileQuestion = connect(mapStateToProps)(ProfileQuestion);

export default ProfileQuestion;
