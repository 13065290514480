import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalHeader, Modal, ModalBody, Button, FormGroup } from 'reactstrap';
import uniqid from 'uniqid';

import File from 'input-fields/components/file';
import { requestData } from 'core/ducks/list';
import { uploadData } from 'core/ducks/upload';

import '../../style/general.scss';

class SelectMarker extends Component {

	constructor(props) {
		super(props);
		this.state = {
			files: [],
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
	}

	componentDidMount() {
		this.props.dispatch( requestData('markers', 'marker') );
	}

	handleChange(event) {
		const { files } = event.target;
		this.setState({
			files,
		}, this.handleSubmit);
	}

	handleSubmit() {

		const data = new FormData();
		data.append('file', this.state.files[0]);
		this.props.dispatch( uploadData('marker', data) )
			.then((response) => {
				this.props.onChange(response.url);
				this.props.toggle();
			})
			.catch(() => {

			});
	}

	handleSelect(value) {
		this.props.onChange(value);
		this.props.toggle();
	}

	render() {

		const { isOpen, toggle, markers, pending } = this.props;

		if (pending)
			return null;

		return (
			<Modal isOpen={isOpen} toggle={toggle} className="ppcity-admin modal-lg">
				<ModalHeader toggle={toggle}>Select Marker</ModalHeader>
				<ModalBody>
					<div className="marker-preview-container">
						{ Object.values(markers).filter(elem => elem.type !== 'directory').map((icon, index) => (
							<img
								key={`marker_${index}`}
								className="marker-preview"
								src={icon.url}
								alt={`Icon ${index}`}
								onClick={() => this.handleSelect(icon.url)}
							/>
						))}
					</div>
					<FormGroup>
						<File
							id={uniqid()}
							name="file"
							label="Upload marker"
							accept="image/svg+xml"
							value={this.state.files}
							onChange={this.handleChange}
							multiple={false}
						/>
					</FormGroup>
					<FormGroup className="text-right">
						<Button color="success">Upload</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	markers: state.list.markers.data,
	pending: state.list.markers.pending,
});

SelectMarker = connect(mapStateToProps)(SelectMarker);

export default SelectMarker;
