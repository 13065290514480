const applicationTypes = {
	'pdf': 'fa-file-pdf-o',
	'msword': 'fa-file-word-o',
	'vnd.openxmlformats-officedocument.wordprocessingml.document': 'fa-file-word-o',
	'x-gzip': 'fa-file-archive-o',
	'tar+gzip': 'fa-file-archive-o',
	'java-archive': 'fa-file-archive-o',
	'json': 'fa-file-code-o',
	'ld+json': 'fa-file-code-o',
	'vnd.oasis.opendocument.presentation': 'fa-file-powerpoint-o',
	'vnd.oasis.opendocument.spreadsheet': 'fa-file-excel-o',
	'vnd.oasis.opendocument.text': 'fa-file-word-o',
	'vnd.ms-powerpoint': 'fa-file-powerpoint-o',
	'vnd.openxmlformats-officedocument.presentationml.presentation': 'fa-file-powerpoint-o',
	'x-rar-compressed': 'fa-file-archive-o',
	'rtf': 'fa-file-text-o',
	'x-sh': 'fa-file-code-o',
	'x-tar': 'fa-file-archive-o',
	'xhtml+xml': 'fa-html5',
	'vnd.ms-excel': 'fa-file-excel-o',
	'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'fa-file-excel-o',
	'zip': 'fa-file-archive-o',
	'x-7z-compressed': 'fa-file-archive-o',
	'x-compressed-tar': 'fa-file-archive-o',
	'x-xz-compressed-tar': 'fa-file-archive-o',
	'x-xz': 'fa-file-archive-o',
	'javascript': 'fa-file-code-o',
	'x-php': 'fa-file-code-o',
	'vnd.oasis.opendocument.graphics': 'fa-pencil-square-o'
};

const textTypes = {
	html5: 'fa-html5',
	calendar: 'fa-calendar-o',
	javascript: 'fa-file-code-o',
	'x-php': 'fa-file-code-o',
};

const type2class = (mimetype) => {
	const mimetypeExploded = mimetype.split('/');
	const type = mimetypeExploded[0];
	const subtype = mimetypeExploded[1];

	switch (type) {
		case 'application':
			return applicationTypes[subtype] || 'fa-file-o';

		case 'text':
			return textTypes[subtype] || 'fa-file-text-o';

		case 'audio':
			return 'fa-music';

		case 'video':
			return 'fa-youtube-play';

		case 'image':
			return 'fa-file-image-o';

		case 'directory':
			return 'fa-folder-o';

		default:
			return 'fa-file-o';
	}

};

export default type2class;
