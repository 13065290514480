import { Api } from '../api';
import { pushNotification } from './notifications';
import { checkCookie } from './profile';


// Actions

const REQUESTING_VALIDATION = 'api/forms/validation/REQUESTING';
const FETCHED_VALIDATION = 'api/forms/validation/FETCHED';
const FAILED_REQUEST = 'api/forms/validation/FAILED';
const SET_VALIDATION_MSGS = 'forms/validation/validation_msgs/SET';
const VALIDATE = 'forms/validation/VALIDATE';
const UNSAVED_DATA = 'forms/UNSAVED_DATA';
const SET_CONTENT = 'forms/content/SET';
const GET_CONTENT = 'forms/content/GET';
const REQUESTING_CONTENT = 'forms/content/REQUESTING';
const RESET = 'forms/content/RESET';
const SEND_CONTENT = 'forms/content/SEND';
const SUBMIT_CONTENT = 'forms/content/SUBMITTED';
const GET_FAILED = 'forms/content/GET/FAILED';
const CLEAR_MESSAGES = 'forms/validation/validation_msgs/CLEAR';


// Initial state

const initialState = {
	validation: {
		pending: false,
		rules: {},
		status: '',
		errorMsg: '',
		scope: '',
	},
	initialValues: {},
	validation_msgs: {},
	empty_msgs: {},
	unsaved_data: false,
	valid: false,
	content: {},
	pending: false,
	submitting: {},
	post: {},
	status: ''
};


// Reducer

export default (state=initialState, action) => {
	switch (action.type) {
		case REQUESTING_VALIDATION:
			return {
				...state,
				validation: {
					...initialState.validation,
					pending: action.pending
				}
			};

		case FETCHED_VALIDATION:
		case FAILED_REQUEST:
			return {
				...state,
				validation: {
					pending: action.pending,
					rules: action.rules,
					status: action.status,
					errorMsg: action.errorMsg,
					scope: action.scope,
				}
			};

		case UNSAVED_DATA:
			return {
				...state,
				unsaved_data: action.unsaved_data
			};

		case SET_VALIDATION_MSGS:
			return {
				...state,
				validation_msgs: action.validation_msgs
			};

		case VALIDATE:
			return {
				...state,
				validation_msgs: {...state.validation_msgs, ...action.validation_msgs},
				valid: action.valid
			};

		case SET_CONTENT:
			let this_content = (!state.content[action.scope] || state.content[action.scope] instanceof Array)
				? { [action.scope]: action.content }
				: { [action.scope]: { ...state.content[action.scope], ...action.content } };
			let this_state = {
				content: Object.assign(
					{},
					state.content,
					this_content
				)
			}
			if (this_content !== state.content[action.scope]) {
				this_state.post = Object.assign({}, state.post, {[action.scope]: true});
				// this_state.initialValues = {
				// 	...state.initialValues,
				// 	[action.scope]: action.content
				// };
			}
			return {
				...state,
				...this_state
			};

		case RESET:
			let content = Object.keys(state.content)
				.filter(scope => scope !== action.scope)
				.reduce((obj, scope) => ({
					...obj,
					[scope]: state.content[scope]
				}), {});
			let post = Object.keys(state.post)
				.filter(scope => scope !== action.scope)
				.reduce((obj, scope) => ({
					...obj,
					[scope]: state.post[scope]
				}), {});
			return {
				...state,
				content,
				post,
			};

		case REQUESTING_CONTENT:
			return {
				...state,
				pending: action.pending
			};

		case GET_CONTENT:
			return {
				...state,
				content: Object.assign({}, state.content, {[action.scope]: action.content}),
				pending: action.pending,
				post: Object.assign({}, state.post, {[action.scope]: false}),
				initialValues: Object.assign({}, state.initialValues, {[action.scope]: action.content})
			};

		case GET_FAILED:
			return {
				...state,
				content: {...action.content},
				pending: action.pending,
				initialValues: {...action.content},
				status: action.status,
			};

		case SEND_CONTENT:
			return {
				...state,
				submitting: {...state.submitting, [action.scope]: true}
			};

		case SUBMIT_CONTENT:
			content = action.status===200 ? {...state.content[action.scope], ...action.content} : state.initialValues[action.scope];
			return {
				...state,
				post: Object.assign({}, state.post, {[action.scope]: (state.post[action.status] && action.status!==200)}),
				initialValues: Object.assign({}, state.initialValues, {[action.scope]: content}),
				status: action.status,
				submitting: {...state.submitting, [action.scope]: false},
			};

		case CLEAR_MESSAGES:
			return {
				...state,
				validation_msgs: {
					...state.validation_msgs,
					[action.scope]: '',
				}
			};

		default:
			return state;
	}
}


// Action creators

const requestingValidation = () => ({
	type: REQUESTING_VALIDATION,
	pending: true
});

const fetchedValidation = (scope, rules) => ({
	type: FETCHED_VALIDATION,
	pending: false,
	scope,
	rules,
	status: 200,
	errorMsg: ''
});

const failedRequest = (status, errorMsg) => ({
	type: FAILED_REQUEST,
	pending: false,
	scope: '',
	rules: {},
	status,
	errorMsg
});

const setValidationMsgs = (msgs) => ({
	type: SET_VALIDATION_MSGS,
	validation_msgs: msgs
});

const requestingContent = () => ({
	type: REQUESTING_CONTENT,
	pending: true
});

const completeValidation = (validation_msgs, valid) => ({
	type: VALIDATE,
	validation_msgs,
	valid
});

const failedGetContent = (status) => ({
	type: GET_FAILED,
	content: {},
	pending: false,
	status,
});

export const clearMessages = (scope) => ({
	type: CLEAR_MESSAGES,
	scope,
});

export const unsavedData = (cond) => ({
	type: UNSAVED_DATA,
	unsaved_data: cond
});

export const setContent = (scope, content) => ({
	type: SET_CONTENT,
	scope,
	content
});

export const resetContent = (scope) => ({
	type: RESET,
	scope
});

/**
 * Replace null values with empty string.
 * The replacement occurs only in first level.
 *
 * @param  {object} data
 * @return {object}
 */
const convertNull = (data) => {
	if (data instanceof Array)
		return data;
	let nulls = Object.keys(data)
		.filter((key) => data[key] === null)
		.reduce((obj, key) => ({
			...obj,
			[key]: ''
		}), {});
	return {...data, ...nulls};
};


const checkUniqueness = (scope, field, value) => (dispatch) => {

	let promise = new Promise((resolve, reject) => {
		if (value === '')
			resolve(false);

		let a = new Api(`checkIfExists/scope/${encodeURI(scope)}/field/${encodeURI(field)}/value/${encodeURI(value)}`);
		let status;
		a.Get().then(response => {
			status = response.status;
			if (status !== 200)
				reject();
			return response.json();
		}).then(exists => {
			resolve(!exists);
		});
	});

	return promise;
};

// Thunk action creators

export const validate = (data, rules, scope=null, excludes=null) => (dispatch, getState) => {
	let validation_msgs = {};
	let valid = true;
	const promises = [];
	Object.keys(rules).forEach((key) => {
		const rule = rules[key];
		const value = data[key] || '';
		if (value !== '' || rule.required) {
			let thisIsValid = true;
			let msg = '';
			if (rule.required)
				msg = value === '' ? (getState().i18n.messages['This value is required'] || 'This value is required.') : msg;
			if (msg === '' && rule.min_size)
				msg = value.length < rule.min_size ? `Minimum size is ${rule.min_size}.` : msg;
			if (msg === '' && rule.max_size)
				msg = value.length > rule.max_size ? `Maximum size is ${rule.max_size}.` : msg;
			if (msg === '' && rule.validation) {
				let regex = new RegExp('^' + rule.validation + '$');
				thisIsValid = regex.test(value);
			}
			thisIsValid = (msg !== '') ? false : thisIsValid;
			if (thisIsValid && scope && rule.unique) {
				if (!excludes || (excludes && excludes[key] !== value)) {
					try {
						let newPromise = dispatch(checkUniqueness(scope, key, value));
						promises.push(newPromise);
						newPromise.then(isUnique => {
							msg = isUnique ? msg : (getState().i18n.messages['This value already exists'] || 'This value already exists!');
							validation_msgs[key] = msg;
							valid = (isUnique && valid);
						});
					} catch {

					}
				}
			}
			validation_msgs[key] = msg;
			valid = (thisIsValid && valid);
		}
	});

	Promise.all(promises).then(() => {
		if (!valid)
			dispatch(pushNotification({body: 'form contains non valid elements', type: 'warning'}));
		dispatch(completeValidation(validation_msgs, valid));
	});

	return Promise.all(promises);
};

export const getContent = (request, scope) => (dispatch, getState) => {
	let promise = new Promise((resolve, reject) => {
		dispatch( checkCookie() ).then(() => {
			dispatch(requestingContent());
			let a = new Api(request);
			let status;
			a.Get().then((response) => {
				status = response.status;
				return response.json();
			}).then((initial_content) => {
				if (status === 200) {
					let content = convertNull(initial_content);
					dispatch({
						type: GET_CONTENT,
						scope,
						content,
						pending: false
					});
					resolve(status);
				} else {
					dispatch(failedGetContent(status));
					reject(status);
				}
			});
		});
	});

	return promise;
};

export const getValidation = (scope, fields='') => (dispatch, getState) => {
	let promise = new Promise((resolve, reject) => {
		dispatch(requestingValidation());
		let url = `validation/scope/${scope}`;
		url += fields!=='' ? '/fields/' + fields : '';
		let a = new Api(url);
		let status;
		a.Get().then((response) => {
			status = response.status;
			return response.json();
		}).then((json) => {
			if (status === 200) {
				let keys = {};
				Object.keys(json).forEach((key) => {keys[key] = ''});
				dispatch(setValidationMsgs(keys));
				dispatch(fetchedValidation(scope, json));
				resolve();
			} else {
				dispatch(failedRequest(status, json.Error));
				reject(json.Error);
			}
		});
	});

	return promise;
};

export const submit = (request, scope, method=undefined) => (dispatch, getState) => {
	let promise = new Promise((resolve, reject) => {
		dispatch( checkCookie() ).then(() => {
			dispatch({
				type: SEND_CONTENT,
				scope,
			});
			let a = new Api(request);
			let requestPromise;
			let data = getState().forms.content[scope];
			if ( (method && method==='Put') || !getState().forms.post[scope] ) {
				const initialValues = getState().forms.initialValues[scope];
				if (data instanceof Array) {
					data = data.filter((entry, index) =>
						entry!==initialValues[index]
					);
				} else {
					data = Object.keys(data)
						.filter(key => data[key]!==initialValues[key])
						.reduce((obj, key) => ({
							...obj,
							[key]: data[key]
						}), {});
				}
			}
			if ((data instanceof Array && data.length === 0) || (data instanceof Object && Object.keys(data).length === 0)) {
				resolve();
				return promise;
			}
			if ((method && method==='Post') || method==='Put') {
				requestPromise = a[method](data);
			} else {
				requestPromise = getState().forms.post[scope] ? a.Post(data) : a.Put(data);
			}
			requestPromise.then((response) => {
				dispatch({
					type: SUBMIT_CONTENT,
					scope,
					content: data,
					status: response.status,
				});
				if (response.status === 200) {
					dispatch(pushNotification({body: 'successful update', type: 'success'}));
				} else {
					dispatch(pushNotification({body: 'action denied', type: 'warning', status: response.status}));
				}
				resolve();
			});
		});
	});

	return promise;
};
