import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import Social from './social';

const Item = (props) => {
	switch (props.type) {
		case 'photo':
			return <img src={props.value}/>

		case 'document':
			return <a href={props.value} target="_blank"/>

		default:
			return <q>{props.value}</q>
	}
}

class Answers extends Component {

	render() {
		const { profile, list } = this.props;

		return (
			<div className=" w-100 p-3 m-2 border-bottom border-primary rounded">
				<div className="p-2 ml-1 d-inline-block">
					{ Object.values(profile).map(elem => (
						<img key={`img_${elem.id}`} className="mx-1" src={elem.icon} height="30px" title={elem.content}/>
					))}
				</div>
				<div>
					{ Object.keys(list).map(choice => (
						<div key={`answer_${choice}`}>
							{ Object.keys(list[choice]).map(type => (
								<blockquote key={`blockquote_${choice}_${type}`}>
									<Item type={type} value={list[choice][type].value}/>
									<Social token={list[choice][type].token} data={list[choice][type]} scope="data" isOwner={list[choice][type].isOwner} />
								</blockquote>
							))}
							<Button
								className="mt-0 mb-2 float-right"
								outline
								color="primary"
								onClick={() => this.props.handleLocationSelect(choice)} style={{fontSize: '150%'}}
							>
								<i className="fa fa-map-marker text-light"/>
							</Button>
							<div className="clearfix"/>
						</div>
					))}
				</div>
			</div>
		);
	}
}

Answers.propTypes = {
	profile: PropTypes.array.isRequired,
	list: PropTypes.object.isRequired,
	handleLocationSelect: PropTypes.func.isRequired,
};

export default Answers;
