import React, { Component } from 'react';
import {
	ModalHeader,
	Modal,
	ModalBody,
	Row,
	Col,
	Card,
	CardBody
} from 'reactstrap';
import { connect } from 'react-redux';
import ProfileForm from 'core/views/forms/profileForm';
import T from 'modules/i18n';

class EditUser extends Component {

	render() {
		const { modalOpen, toggle } = this.props;

		return (
			<Modal isOpen={modalOpen} toggle={toggle} className="modal-xl">
				<ModalHeader toggle={toggle}>{<T>edit</T>}</ModalHeader>
				<ModalBody>
					<Row>
						<Col xs="12" lg="12">
							<Card>
								<CardBody>
									<ProfileForm data={this.props.data} />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	modalOpen: state.ui.modal.modalOpen
});

EditUser = connect(mapStateToProps)(EditUser);

export default EditUser;
