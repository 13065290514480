import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, FormText, Input, Card, CardBody, CardText, Button, Container, Row, Col } from 'reactstrap';

import { getData, updateData } from 'core/ducks/update';
import { Loading } from 'core/components';
import { ErrorPage } from 'core/views/pages';
import { pushNotification } from 'core/ducks/notifications';
import RegistrationForm from 'login/views/forms/registrationForm';
import { StaticRoutes } from '../../model/routes';
import T from 'modules/i18n';

class InvitationAccept extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: null,
			httpCode: null,
			ready: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		const { hash } = this.props.match.params;
		this.props.dispatch(
			getData(`invitation/hash/${hash}`)
		).then(data => this.setState({data, ready: true, httpCode: 200}))
		.catch(httpCode => this.setState({httpCode, ready: true}));
	}

	handleSubmit(data) {
		const { hash } = this.props.match.params;
		this.props.dispatch(
			updateData(`invitation/hash/${hash}`, data, false)
		).then(() => this.props.history.replace(StaticRoutes.Categories))
		.catch(httpStatus => {
			let body;
			switch (httpStatus) {
				case 409:
					body = 'email does not correspond to invitation';
					break;

				default:
					body = 'action denied';
			}
			this.props.dispatch(pushNotification({body, type: 'danger'}));
		});
	}

	render() {
		const { data, httpCode, ready, email } = this.state;

		if (!ready) return (<Loading/>);
		if (httpCode !== 200 ) return (<ErrorPage status={httpCode}/>)

		return (
			<div className="app-container flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Col md="8">
							<Card>
								<CardBody>
									<CardText className="text-center" style={{fontSize: '120%'}}><T>welcome</T>!</CardText>
									<RegistrationForm
										prefilled={data}
										instructions={{email: 'write your email where you received the invitation'}}
										submitted={false}
										onSubmit={this.handleSubmit}
									>
										<div className="text-right">
											<Button color="warning" className="mr-2"><T>accept</T></Button>
										</div>
									</RegistrationForm>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

InvitationAccept = connect(mapStateToProps)(InvitationAccept);

export default InvitationAccept;
