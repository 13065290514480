import React, { Component } from 'react';
import { Input, FormGroup, Label, Row, Col, } from 'reactstrap';

class Checkbox extends Component {

	constructor(props) {
		super(props);

		this.state = {
			values: props.value !== '' ? props.value : [],
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		const { name, checked } = event.target;
		const values = checked ? [
			...this.state.values,
			name,
		] : [
			...this.state.values.slice(0, this.state.values.indexOf(name)),
			...this.state.values.slice(this.state.values.indexOf(name) + 1)
		];
		this.setState({
			values,
		});
		this.props.onChange({
			...event,
			target: {
				...event.target,
				type: 'array',
				name: this.props.name,
				value: values
			}
		});
	}

	render() {

		const { attributes, type } = this.props;
		const { values } = this.state;

		return (
			<>
				<Label className="my-0">{attributes.label}</Label>
				<Row>
					{attributes.options.map(label => (
						<Col key={`${type}_option_${label}_for_${attributes.label}`} xs="3" md="2" className="py-0">
							<FormGroup check >
								<Label check >
									<Input type={type} checked={values.indexOf(label) !== -1} name={label} onChange={this.handleChange}/>
									{label}
								</Label>
							</FormGroup>
						</Col>
					))}
				</Row>
			</>
		);
	}
}

export default Checkbox;
