import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Card, CardHeader, CardBody, TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';

import { requestData } from 'core/ducks/list';
import { Stats, VariableStats, VariableAnswers } from '../answers';
import { QuestionnaireContext } from '../../layout/questionnaire';
import T from 'modules/i18n';

import '../../../style/answers.scss';

class Answers extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: 0,
			isSomeModalOpen: false,
		};
		this.containerRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		const { context } = this.props;
		this.props.dispatch(requestData('variable', `variable/attribute/${context.activeAttribute.token}`));
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = (e) => {
		if (this.state.isSomeModalOpen)
			return;
		if (this.containerRef.current && !this.containerRef.current.contains(e.target))
			this.props.toggle();
	}

	toggleTab = (activeTab) => {
		if (this.state.activeTab !== activeTab)
			this.setState({activeTab});
	}

	render() {

		const { variables, context } = this.props;
		const { activeTab } = this.state;

		if (this.props.pending)
			return null;

		return (
			<div ref={this.containerRef} className="answers full-content animated fadeIn fadeOut">
				<header>
					<i className="fa fa-times toggle" onClick={() => this.props.toggle()}/>
					<Nav tabs>
						<NavItem>
							<NavLink
								className={activeTab===0 ? 'active text-info p-2' : 'border border-secondary p-2'}
								onClick={() => this.toggleTab(0)}
							>
								<T>total</T>
							</NavLink>
						</NavItem>
						{ variables.map((variable, index) => (
							<NavLink
								key={`navlink_${variable.token}`}
								className={activeTab===index+1 ? 'active text-info p-2' : 'border border-secondary p-2'}
								onClick={() => this.toggleTab(index+1)}
							>
								{variable.description || variable.mname}
							</NavLink>
						))}
					</Nav>
				</header>
				<TabContent activeTab={activeTab} className="px-4">
					<TabPane tabId={0}>
						<Stats className="bg-secondary shadow rounded m-5 p-2" attribute={context.activeAttribute.token} filter={context.filter}/>
					</TabPane>
					{ variables.map((variable, index) => (
						<TabPane key={`tabpane_${variable.token}`} tabId={index + 1}>
							<VariableStats
								className="bg-secondary shadow rounded m-5 p-2"
								attribute={context.activeAttribute.token}
								variable={variable.mname}
								filter={context.filter}
							/>
							<VariableAnswers
								className="bg-secondary shadow rounded m-5 p-2"
								attribute={context.activeAttribute.token}
								variable={variable.mname}
								filter={context.filter}
								toggleOpenModal={(state) => this.setState({isSomeModalOpen: state})}
							/>
						</TabPane>
					))}
				</TabContent>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	variables: state.list.variable.data,
	pending: state.list.variable.pending,
});

Answers = connect(mapStateToProps)(Answers);

export default props => (
	<QuestionnaireContext>
		{context => <Answers {...props} context={context} />}
	</QuestionnaireContext>
);
