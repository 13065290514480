import React, { Component } from 'react';

import { Table, Thead, Tbody, Tr, Td } from 'css-table';

class InvitationsStatusTable extends Component {

	render() {
		const { T, data } = this.props;
		const header = ['firstname', 'lastname', 'email', 'enrolment', 'comment', 'invite']

		return (
			<Table>
				<Thead>
					<Tr>
						{header.map(elem => (<Td key={`header_${elem}`}><T>{elem}</T></Td>))}
					</Tr>
				</Thead>
				<Tbody>
					{ data.map((row, index) => (
						<Tr key={`row_${index}`} className={!row.valid ? 'text-danger' : ''}>
							<Td>{row.firstname}</Td>
							<Td>{row.lastname}</Td>
							<Td>{row.email}</Td>
							<Td>
								{Object.keys(row.enrolment).map(category => (
									<span className="d-block" key={`enrolment_${index}_${category}`}>{`${category}: ${row.enrolment[category]}`}</span>
								))}
							</Td>
							<Td>
								{row.comment.map((elem, commentIndex) => (
									<span className="d-block" key={`comment_${index}_${commentIndex}`}>{elem}</span>
								))}
							</Td>
							<Td>
								{(row.invite && row.valid) ? <i className="fa fa-check-square-o"/> : <i className="fa fa-square-o"/>}
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		);
	}
}

export default InvitationsStatusTable;
