import React, { Component } from 'react';
import ReactTags from 'react-tag-autocomplete';

import '../scss/style.scss';

class TagInput extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tags: props.defaultValue ? JSON.parse(props.defaultValue).map(name => ({id: name, name})) : [],
			suggestions: [],
		};

		this.handleDelete = this.handleDelete.bind(this);
		this.handleAddition = this.handleAddition.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {
		if (!this.props.options || !this.props.options.tagList) {
			this.handleInputChange('');
		} else {
			const suggestions = this.props.options.tagList.map(name => ({
				id: name,
				name
			}));
			this.setState({suggestions});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevState.tags) !== JSON.stringify(this.state.tags)) {
			const value = this.state.tags.map(tag => tag.name);
			if (typeof this.props.onChange === 'function')
				this.props.onChange({target: {name: this.props.name, value: JSON.stringify(value)}});
		}
	}

	handleDelete(i) {
		const tags = this.state.tags.slice(0);
		tags.splice(i, 1);
		this.setState({tags: [...tags] });
	}

	handleAddition(tag) {
		const { tags } = this.state;
		const index = this.state.tags.findIndex(elem => elem.name === tag.name);
		let newTags = index !== -1 ? [...tags.slice(0, index), ...tags.slice(index + 1)] : [...tags];
		this.setState({
			tags: [...newTags, {id: tag.name, name: tag.name}],
			suggestions: [],
		});
	}

	handleInputChange(query) {
		this.props.handleInputChange(query)
			.then(suggestions => this.setState({suggestions}));
	}

	render() {
		const { onChange, name, options, handleInputChange, defaultValue, ...otherProps } = this.props;

		return (
			<ReactTags
				tags={this.state.tags}
				suggestions={this.state.suggestions}
				handleDelete={(i) => this.handleDelete(i)}
				handleAddition={(tag) => this.handleAddition(tag)}
				allowBackspace={false}
				allowNew={true}
				maxSuggestionsLength={8}
				minQueryLength={0}
				handleInputChange={this.handleInputChange}
				{...otherProps}
			/>
		);
	}
}

export default TagInput;
