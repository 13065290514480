import { Api } from 'core/api';
import { checkCookie } from 'core/ducks/profile';

const DETAILS_SET = 'library/DETAILS_SET';
const DATA_REQUEST = 'api/library/DATA_REQUEST';
const FAILED_REQUEST = 'api/library/FAILED_REQUEST';
const SLIDESHOW = 'library/SLIDESHOW';
const SET_GLOBAL_TAGS = 'library/SET_GLOBAL_TAGS';

const initialState = {
	activePhoto: null,
	details: {},
	pending: false,
	httpStatus: null,
	isSlideShowOpen: false,
	tags: '',
};

export default (state = initialState, action) => {
	switch (action.type) {

		case DETAILS_SET:
			return {
				...state,
				pending: action.pending,
				httpStatus: action.httpStatus,
				details: action.details,
			};

		case DATA_REQUEST:
		case FAILED_REQUEST:
			return {
				...state,
				activePhoto: action.activePhoto,
				pending: action.pending,
				httpStatus: action.httpStatus,
			};

		case SLIDESHOW:
			return {
				...state,
				isSlideShowOpen: action.isSlideShowOpen,
			};

		case SET_GLOBAL_TAGS:
			return {
				...state,
				tags: action.tags,
			};

		default:
			return state;
	}
};

const requestData = (activePhoto) => ({
	type: DATA_REQUEST,
	activePhoto,
	pending: true,
	httpStatus: null,
});

const requestFailed = (httpStatus) => ({
	type: FAILED_REQUEST,
	activePhoto: null,
	pending: false,
	httpStatus,
});

const setDetails = (details) => ({
	type: DETAILS_SET,
	pending: false,
	httpStatus: 200,
	details,
});

export const slideShow = (isSlideShowOpen) => ({
	type: SLIDESHOW,
	isSlideShowOpen,
});

export const setGlobalTags = (tags) => ({
	type: SET_GLOBAL_TAGS,
	tags,
});

export const selectPhoto = (uuid, index) => (dispatch, getState) => {
	let promise = new Promise((resolve, reject) => {
		const { details } = getState().library;
		if (details.uuid && details.uuid === uuid) {
			dispatch(setDetails(details));
			resolve(details);
			return;
		}
		dispatch( checkCookie() ).then(() => {
			dispatch(requestData(index));
			let a = new Api(`library/uuid/${uuid}`);
			let status;
			a.Get().then((response) => {
				status = response.status;
				return response.json();
			}).then((json) => {
				if (status === 200) {
					dispatch(setDetails(json));
					resolve(json);
				} else {
					dispatch(requestFailed(status));
					reject(status);
				}
			});
		});
	});

	return promise;
}