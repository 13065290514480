import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip';

class RelativeDocuments extends Component {

	render() {
		const { documents, token, title } = this.props;

		if (!documents || documents.length === 0)
			return null;

		return (
			<Tooltip className="text-left" place="left" id={`relative_documents_${token}`} delayShow={100} delayUpdate={5000} type="light">
				<h5 className="pb-2">{title}</h5>
				<ul>
					{ documents.map((doc, index) => (
						<li key={`doc_${token}_${index}`}>
							<a href={doc.file} target="_blank" rel="noopener noreferrer">{doc.title}</a>
						</li>
					))}
				</ul>
			</Tooltip>
		);
	}
}

RelativeDocuments.propTypes = {
	title: PropTypes.node.isRequired,
	token: PropTypes.string.isRequired,
	documents: PropTypes.array.isRequired,
};

export default RelativeDocuments;
