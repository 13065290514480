import * as roles from 'core/model/roles';
/**
 * Static routes
 */

// Admin
const Administration = '/admin/administration';
const Project = Administration + '/project';
const Category = Administration + '/category';
const ProjectList = Project + '/list';
const ProjectAdd = Project + '/add';
const CategoryAdd = Category + '/add';
const Participants = Administration + '/members';
const Enroll = Administration + '/enroll';
const Profile = Administration + '/profile';
const Invitations = Administration + '/invitations';

export const StaticRoutes = {
	Administration,
	ProjectList,
	ProjectAdd,
	Category,
	CategoryAdd,
	Participants,
	Enroll,
	Profile,
	Invitations,
};

/**
 * Dynamic routes
 */

const ProjectPlan = Project + '/:project/plan';
const ProjectEdit = Project + '/:project/edit';
const CategoryDetails = Category + '/:category';
const CategoryEdit = Category + '/:category/edit';
const Topic = Project + '/:project/topic';
const TopicAdd = Topic + '/add';
const TopicEdit = Topic + '/:topic/edit';
const Attribute = Project + '/:project/topic/:topic/attribute';
const AttributeAdd = Attribute + '/add';
const AttributeEdit = Attribute + '/:attribute/edit';
const Variable = Project + '/:project/topic/:topic/attribute/:attribute/variable';
const VariableAdd = Variable + '/add';
const VariableEdit = Variable + '/:variable/edit';
const ParticipantsWithRole = Participants + '/(role)?/:role?';
const EnrollWithSystemRole = Enroll + '/(system-role)?/:role?';

export const DynamicRoutes = {
	ProjectPlan,
	ProjectEdit,
	CategoryDetails,
	CategoryEdit,
	Topic,
	TopicAdd,
	TopicEdit,
	Attribute,
	AttributeAdd,
	AttributeEdit,
	Variable,
	VariableAdd,
	VariableEdit,
	ParticipantsWithRole,
	EnrollWithSystemRole,
};

export const routes = [
	{
		url: Project,
		name: 'administration',
		to: ProjectList,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: ProjectList,
		name: 'Projects',
		component: 'ListProject',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: ProjectAdd,
		name: 'add',
		component: 'EditProject',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: ProjectEdit,
		name: 'edit',
		component: 'EditProject',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: Category,
		name: 'category',
		to: CategoryAdd,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: CategoryAdd,
		name: 'add',
		component: 'EditCategory',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: CategoryDetails,
		name: 'details',
		to: CategoryEdit,
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: ProjectPlan,
		name: 'plan',
		component: 'PlanProject',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: CategoryEdit,
		name: 'edit',
		component: 'EditCategory',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: Topic,
		name: 'topic',
		to: TopicAdd,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: TopicAdd,
		name: 'add',
		component: 'AddTopic',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: TopicEdit,
		name: 'edit',
		component: 'AddTopic',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: Attribute,
		name: 'attribute',
		to: AttributeAdd,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: AttributeAdd,
		name: 'add',
		component: 'Attribute',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: AttributeEdit,
		name: 'edit',
		component: 'Attribute',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: Variable,
		name: 'variable',
		to: AttributeAdd,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: VariableAdd,
		name: 'add',
		component: 'Variable',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: VariableEdit,
		name: 'edit',
		component: 'Variable',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: ParticipantsWithRole,
		name: 'members',
		component: 'Participants',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: EnrollWithSystemRole,
		name: 'enroll',
		component: 'Enroll',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: Profile,
		name: 'consultation profile',
		component: 'ConsultationProfile',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'CategoriesSidebar',
	},
	{
		url: Invitations,
		name: 'invitations',
		component: 'Invitations',
		role: roles.EDITOR,
		exact: true,
		contextComponent: 'InvitationsSidebar',
	},
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
