import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Modal, ModalHeader, ModalBody, Form, FormGroup, Input, Button
} from 'reactstrap';

import { postData } from 'core/ducks/update';

import T from 'modules/i18n';

class NewThread extends Component {

	constructor(props) {
		super(props);
		this.state = {
			values: {
				title: props.item.title,
				raw: '',
			}
		};
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: value,
			}
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.dispatch(
			postData(`${this.props.scope}/createThread/${this.props.item.token}`, this.state.values)
		).then(() => this.props.toggle());
	}

	render() {
		const { isOpen, toggle, scope, item } = this.props;
		const { values } = this.state;

		return (
			<Modal isOpen={isOpen} className="ppcity-admin modal-md">
				<ModalHeader toggle={toggle}>Create forum thread</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						<FormGroup>
							<Input name="title" value={values.title} onChange={this.handleChange} minLength="15" required/>
						</FormGroup>
						<FormGroup>
							<Input
								placeholder="Write your message..."
								type="textarea"
								name="raw"
								value={values.raw}
								onChange={this.handleChange}
								minLength="20"
								required
								rows="10"
							/>
						</FormGroup>
						<div className="p-2 text-right">
							<Button color="success"><T>submit</T></Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		);
	}

}

NewThread = connect(null)(NewThread);

export default NewThread;
