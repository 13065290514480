import React, { Component } from 'react';
import SelectMarker from '../../views/modals/selectMarker';

class Marker extends Component {

	constructor(props) {
		super(props);
		this.state = {
			value: '',
			isOpen: false
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(value) {
		this.setState({
			value,
		}, () => this.props.onChange({
			target: {
				name: this.props.name,
				value: this.state.value,
			}
		}));
	}

	render() {

		return (
			<>
				<span className="icon-box d-block" title="Click to add a marker." onClick={() => {this.setState({isOpen: true})}}>
					{ this.state.value !== '' ?
						<img
							style={{width: '100%', maxWidth: '100%', height: 'auto', maxHeight: '100%'}}
							src={this.state.value}
							alt="marker"
						/>
						:
						<span className="text">marker</span>
					}
				</span>
				{ this.state.isOpen &&
					<SelectMarker isOpen={this.state.isOpen} toggle={() => {this.setState({isOpen: !this.state.isOpen})}} onChange={this.handleChange} />
				}
			</>
		);
	}
}

export default Marker;
