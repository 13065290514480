import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Card, CardHeader, CardBody, Form, FormGroup, UncontrolledTooltip as Tooltip,
	Row, Col, Label, CustomInput, NavLink, Button, Progress, Modal, ModalBody,
	InputGroup, InputGroupAddon, InputGroupText, FormFeedback
} from 'reactstrap';
import { Table, Thead, Tbody, Tr, Td } from 'css-table';
import { format } from 'date-fns';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';

import { Input, Loading } from 'core/components';
import { getValidation, validate, getContent, clearMessages } from 'core/ducks/forms';
import { characterConverter } from 'core/model/lib';
import { DateInput } from 'input-fields';
import { toggleModal } from 'core/ducks/ui/modal';
import { getData } from 'core/ducks/update';
import { buildPath } from 'core/model/lib/urlTools';
import { ErrorPage } from 'core/views/pages';
import { UploadImage } from 'core/views/modals';
import { uploadData, cancelUpload } from 'core/ducks/upload';
import { getBaseUrl } from 'core/model/lib/urlTools';
import { DynamicRoutes as frontRoutes } from 'ppcity/model/routes'
import { DynamicRoutes } from '../../model/routes';
import T from 'modules/i18n';

import '../../style/general.scss';

class EditProject extends Component {

	constructor(props) {
		super(props);

		let today = new Date();
		let tomorrow = new Date();
		tomorrow.setDate(today.getDate() + 1);

		this.initialValues = {
			mname: '',
			title: '',
			summary: '',
			summary_img: '',
			summary_img_alt: '',
			start_date: format(today, 'yyyy-MM-dd'),
			end_date: format(tomorrow, 'yyyy-MM-dd'),
			max_bytes: '',
			active: false,
			public_date: format(tomorrow, 'yyyy-MM-dd'),
			zoom: props.mapSettings.zoom || 14,
			center: props.mapSettings.center || [37.983810, 23.727539],
			wms_services: [],
			legislation: []
		};

		this.state = {
			shortname: '',
			values: { ...this.initialValues },
			files: [],
			underSubmit: false,
			previewUrl: null,
			showProgress: false,
			submitted: false,
			project: props.match.params.project ? props.match.params.project : null,
			makeActive: false,
			httpStatus: 200,
			pending: true,
			category: '',
			allowPublic: true,
		};

		this.emptyWMSService = { title: '', WMSurl: '', layers: ''};
		this.emptylegislation = { title: null, file: null};
		this.mapRef = React.createRef();
		this.actions = bindActionCreators({toggleModal}, props.dispatch);

		this.handleShortnameChange = this.handleShortnameChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.getModalValues = this.getModalValues.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.resetForm = this.resetForm.bind(this);
		this.uploadData = this.uploadData.bind(this);
		this.fetchContent = this.fetchContent.bind(this);
		this.handleWmsChange = this.handleWmsChange.bind(this);
		this.handleLegislationChange = this.handleLegislationChange.bind(this);
	}

	componentDidMount() {
		if (this.state.project) {
			this.fetchContent(this.state.project);
		} else {
			if (this.props.activeCategory) {
				this.props.dispatch(
					getData(`category/token/${this.props.activeCategory}/fields/mname`)
				).then(category => this.setState({category, pending: false}));
			} else {
				this.setState({pending: false});
			}
		}
		this.props.dispatch( getValidation('project') );
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.project !== this.props.match.params.project) {
			const { project } = this.props.match.params;
			this.setState({
				project,
			});
			this.fetchContent(project);
		}

		if ( !prevState.underSubmit && this.state.underSubmit === 'post')
			this.uploadData('post');

		if ( !prevState.underSubmit && this.state.underSubmit === 'put')
			this.uploadData('put');

		if (!prevState.submitted && this.state.submitted)
			this.setState({
				submitted: false,
			});

		if (prevProps.progress < 100 && this.props.progress === 100)
			this.setState({
				showProgress: false,
				submitted: true,
			});

		if (prevProps.validationPending && !this.props.validationPending) {
			this.initialValues = {
				...this.initialValues,
				max_bytes: this.props.rules.max_bytes.max_size,
			};
			this.setState({
				values: {
					...this.initialValues
				}
			});
		}
	}

	fetchContent(project) {
		this.props.dispatch( getContent(`project/token/${project}`, 'project') )
		.then(status => {
			this.props.dispatch(
				getData(`category/uuid/${this.props.content.project.category}/fields/mname`)
			).then(category => this.setState({category}));
			this.initialValues = Object.keys(this.initialValues)
				.reduce((obj, key) => ({
					...obj,
					[key]: key==='center'
						? (this.props.content.project[key] ? JSON.parse(this.props.content.project[key]) : this.state.values.center)
						: key==='wms_services' ? (this.props.content.project[key] ? JSON.parse(this.props.content.project[key]) : this.state.values.wms_services)
						: key==='legislation' ? (this.props.content.project[key] ? JSON.parse(this.props.content.project[key]) : this.state.values.legislation) : this.props.content.project[key]
				}), {});

			this.setState({
				values: {...this.initialValues},
				shortname: this.initialValues.mname,
				pending: false,
				allowPublic: this.initialValues.public_date !== '',
			});
		})
		.catch(status => {
			this.setState({httpStatus: status, pending: false});
		});
	}

	uploadData(method) {
		let formData = new FormData();
		const { values } = this.state;
		let empty = true;
		Object.keys(values).forEach(key => {
			if (this.initialValues[key] !== values[key])
				empty = false;
			if (key !== 'summary_img' && key !== 'center' && key !== 'wms_services' && key !== 'legislation')
				formData.append(key, values[key]);
			if (key === 'center' || key === 'wms_services' || key === 'legislation')
				formData.append(key, JSON.stringify(values[key]));
		});
		if (this.state.files[0]) {
			empty = false;
			formData.append('summary_img', this.state.files[0]);
		}
		if (this.props.activeCategory)
			formData.append('category', this.props.activeCategory);

		if (!empty || method === 'post') {
			this.setState({showProgress: true}, () => {
				if (method === 'put')
					formData.append('_method', 'put');
				const url = method === 'put' ? `project/token/${this.state.project}` : 'project';
				this.props.dispatch( uploadData(url, formData) )
					.then(response => {
						this.initialValues = {
							...this.state.values,
						};
						this.setState({underSubmit: false});
						if (method === 'post')
							this.props.history.push(buildPath(DynamicRoutes.ProjectEdit, [response.token]));
						if (typeof this.props.toggle === 'function')
							this.props.toggle();
					})
					.catch(error => {
						console.warn(error);
					});
			});
		} else {
			this.setState({underSubmit: false});
		}
	}

	handleShortnameChange(event) {
		const { value } = event.target;
		const { messages, dispatch } = this.props;
		if (messages.mname !== '')
			dispatch( clearMessages('mname') );
		this.setState({
			shortname: value,
			values: {
				...this.state.values,
				mname: characterConverter(value),
			}
		});
	}

	handleChange(event) {
		const { name, type, value } = event.target;
		const { messages, dispatch } = this.props;
		if (messages[name] !== '')
				dispatch( clearMessages(name) );
		this.setState({
			values: {
				...this.state.values,
				[name]: (type === 'checkbox' || type === 'switch') ? event.target.checked : value
			},
			underSubmit: false
		});
	}

	handleWmsChange(event, index) {
		const { value, name } = event.target;
		const { wms_services } = this.state.values;
		const wms = {
			...this.state.values.wms_services[index],
			[name]: value,
		};
		this.setState(prevState => ({
			values: {
				...prevState.values,
				wms_services: [
					...wms_services.slice(0, index),
					wms,
					...wms_services.slice(index + 1),
				]
			}
		}));
	}

	handleLegislationChange(event, index) {
		const { value, name } = event.target;
		const { legislation } = this.state.values;
		const doc = {
			...this.state.values.legislation[index],
			[name]: value,
		};
		this.setState(prevState => ({
			values: {
				...prevState.values,
				legislation: [
					...legislation.slice(0, index),
					doc,
					...legislation.slice(index + 1),
				]
			}
		}));
	}

	getModalValues(values) {
		const { file, alt, files } = values;
		this.setState({
			values: {
				...this.state.values,
				summary_img: file,
				summary_img_alt: alt,
			},
			files,
			previewUrl: files[0] ? URL.createObjectURL(files[0]) : null,
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		const method = this.state.project ? 'put' : 'post';
		dispatch(validate(this.state.values, rules, 'project', this.initialValues)).then(() => {
			if (this.props.valid)
				this.setState({underSubmit: method});
		});
	}

	resetForm() {
		this.setState({
			values: {...this.initialValues},
			shortname: this.initialValues.mname,
			files: [],
			previewUrl: null,
		});
	}

	render() {

		const { values, project, httpStatus, pending, allowPublic } = this.state;
		const { rules, messages, activeCategory } = this.props;

		if (this.props.validationPending || this.props.validationScope !== 'project' || pending)
			return (<Loading />);

		if (httpStatus !== 200)
			return (<ErrorPage status={httpStatus} />);

		if (!project && (!activeCategory || activeCategory === ''))
			return (
				<Card className="animated fadeIn">
					<CardBody>
						<T>please check or create a category</T>
					</CardBody>
				</Card>
			);

		return (
			<Form className="ppcity-admin animated fadeIn" onSubmit={this.handleSubmit}>
				<Card>
					<CardHeader className="font-weight-bold">
						{project ? 'Edit Project' : 'New Project'}
					</CardHeader>
					<CardBody className="mx-3">
						<FormGroup row>
							<Col className="p-0">
								<NavLink target="_blank" disabled={!values.active || values.mname === ''} href={getBaseUrl() + buildPath(frontRoutes.QuestionnaireDetails, [this.state.category, values.mname])}>
									{getBaseUrl() + buildPath(frontRoutes.QuestionnaireDetails, [this.state.category, values.mname])}
								</NavLink>
							</Col>
							<Col className="p-0 text-right">
								<CustomInput
									id="active_input"
									type="switch"
									checked={values.active}
									name="active"
									onChange={this.handleChange}
									label="Visible"
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col className="py-0" sm="9" lg="10">
								<FormGroup>
									<Input
										style={{fontSize: 150+'%'}}
										name="title"
										autoComplete="off"
										value={values.title}
										onChange={this.handleChange}
										placeholder="Title"
										pattern={rules.title.validation}
										valid={messages.title === ''}
									/>
									<FormFeedback><T>{messages.title || rules.title.message}</T></FormFeedback>
								</FormGroup>
							</Col>
							<Col className="py-1" sm="3" lg="2" style={{margin: '-1.2rem 0 0 0'}}>
								<FormGroup>
									<Label
										style={{opacity: this.state.shortname==='' ? 0 : 1, fontSize: "75%"}}
										className="animated fadeIn fadeOut my-0"
									>
										Short name
									</Label>
									<Input
										style={{fontSize: 75+'%'}}
										name="shortname"
										autoComplete="off"
										value={this.state.shortname}
										onChange={this.handleShortnameChange}
										placeholder="Short name"
										className="d-inline-block"
										minLength={rules.mname.min_size}
										maxLength={rules.mname.max_size}
										valid={messages.mname === ''}
									/>
									<FormFeedback><T>{messages.mname || rules.mname.message}</T></FormFeedback>
								</FormGroup>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col sm="4" className="p-0 border" onClick={() => {
								this.actions.toggleModal(true,
									<UploadImage
										getValues={this.getModalValues}
										toggle={() => {this.actions.toggleModal()}}
										values={{file: values.summary_img, alt: values.summary_img_alt}}
									/>
								);
							}}>
								{ (this.state.previewUrl || values.summary_img) ?
									<img
										style={{width: '100%', maxWidth: '100%', height: 'auto'}}
										src={this.state.previewUrl || values.summary_img}
										alt="Uploaded"
									/>
									:
									<FormGroup>
										<div style={{margin: 0, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
											Click to browse a summary image.
										</div>
										<Input className="d-none" value={values.summary_img} valid={messages.summary_img === ''} onChange={() => {}}/>
										<FormFeedback><T>{messages.summary_img || rules.summary_img.message}</T></FormFeedback>
									</FormGroup>
								}
							</Col>
							<Col sm="8" className="py-0">
								<FormGroup>
									<Input
										type="textarea"
										name="summary"
										placeholder="Summary"
										rows={10}
										value={values.summary}
										onChange={this.handleChange}
										valid={messages.summary === ''}
									/>
									<FormFeedback><T>{messages.summary || rules.summary.message}</T></FormFeedback>
								</FormGroup>
							</Col>
						</FormGroup>
						<Row>
							<Col sm="6">
								<FormGroup>
									<Label>Active <i id="activeHelp" className="fa fa-question-circle"/>
										<InputGroup className="mb-1 ml-1">
											<InputGroupAddon addonType="prepend" title="Start Date.">
												<InputGroupText><i className="fa fa-hourglass-start" /></InputGroupText>
											</InputGroupAddon>
											<DateInput
												id="start_date_input"
												name="start_date"
												value={values.start_date}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</Label>
									<InputGroup className="ml-1">
										<InputGroupAddon addonType="prepend" title="End Date.">
											<InputGroupText><i className="fa fa-hourglass-end" /></InputGroupText>
										</InputGroupAddon>
										<DateInput
											id="end_date_input"
											name="end_date"
											value={values.end_date}
											onChange={this.handleChange}
											min={values.start_date}
										/>
									</InputGroup>
								</FormGroup>
								<Tooltip target="activeHelp" placement="right">
									[[Placeholder]]
								</Tooltip>
							</Col>
							<Col sm="6">
								<FormGroup>
									<Label>Public date <i id="publicDateHelp" className="fa fa-question-circle"/>
										<InputGroup className="ml-1">
											<InputGroupAddon addonType="prepend" title="Date when results will be public.">
												<InputGroupText><i className="fa fa-hourglass" /></InputGroupText>
											</InputGroupAddon>
											<DateInput
												id="public_date_input"
												name="public_date"
												value={values.public_date}
												onChange={this.handleChange}
												disabled={!allowPublic}
											/>
										</InputGroup>
									</Label>
									<Tooltip target="publicDateHelp" placement="right">
										[[Placeholder]]
									</Tooltip>
									<Label check className="ml-5">
										<Input
											type="checkbox"
											value={allowPublic}
											checked={allowPublic}
											onChange={(e) => {
												const { checked } = e.target;
												let public_date = checked ? format(new Date(), 'yyyy-MM-dd') : null;
												this.setState({
													values: {...this.state.values, public_date},
													allowPublic: checked,
												});
											}}/>
										Results will be published
									</Label>
								</FormGroup>
								<FormGroup>
									<Label>Maximum Upload Size (Mb) <i id="maxBytesHelp" className="fa fa-question-circle"/>
										<InputGroup className="ml-1">
											<InputGroupAddon addonType="prepend">
												<InputGroupText><i className="fa fa-upload" /></InputGroupText>
											</InputGroupAddon>
											<Input
												type="number"
												min={rules.max_bytes.min_size}
												max={rules.max_bytes.max_size}
												name="max_bytes"
												value={values.max_bytes}
												onChange={this.handleChange}
											/>
										</InputGroup>
										<Tooltip target="maxBytesHelp" placement="right">
											[[Placeholder]]
										</Tooltip>
									</Label>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label>Adjust map zoom level and center</Label>
										<LeafletMap
											style={{width: '100%', height: '400px'}}
											center={values.center}
											zoom={values.zoom}
											ref={this.mapRef}
											onZoomEnd={() => {
												this.handleChange({target: {name: 'zoom', value: this.mapRef.current.leafletElement.getZoom()}});
											}}
											onMoveEnd={() => {
												const center = this.mapRef.current.leafletElement.getCenter();
												this.handleChange({target: {name: 'center', value: [center.lat, center.lng]}});
											}}
										>
											<TileLayer
												url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
												attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
											/>
										</LeafletMap>
								</FormGroup>
							</Col>
						</Row>

						<Row>
							<Col sm="12">
								<FormGroup tag="fieldset" className="p-2">
									<Label>Basemaps</Label>
									<Table>
										<Thead>
											<Tr>
												<Td><T>title</T></Td>
												<Td><T>WMSurl</T>{' '}<i id="wmsUrlHelp" className="fa fa-question-circle"/></Td>
												<Td><T>layers</T>{' '}<i id="wmsLayersHelp" className="fa fa-question-circle"/></Td>
												<Td/>
											</Tr>
										</Thead>
										<Tbody>
											{
												this.state.values.wms_services ? this.state.values.wms_services.map((service, index) => (
													<Tr  key={`wms_services_${index}`}>
														<Td>
															<Input required name="title" value={service.title} onChange={(e) => this.handleWmsChange(e, index)}/>
														</Td>
														<Td>
															<Input required name="WMSurl" value={service.WMSurl} onChange={(e) => this.handleWmsChange(e, index)}/>
														</Td>
														<Td>
															<Input required name="layers" value={service.layers} onChange={(e) => this.handleWmsChange(e, index)}/>
														</Td>
														<Td>
															<i
																role="button"
																className="fa fa-trash-o"
																onClick={() => {
																	this.setState(prevState => ({
																		values: {
																			...prevState.values,
																			wms_services: [...prevState.values.wms_services.slice(0, index), ...prevState.values.wms_services.slice(index + 1)]
																		}
																	}));
																}}
															/>
														</Td>
													</Tr>
												)) : ''
											}
										</Tbody>
									</Table>
									<div className="float-right">
										<i
											role="button"
											className="fa fa-plus mr-2"
											onClick={() => {
												this.setState(prevState => ({
													values: {
														...prevState.values,
														wms_services: [...prevState.values.wms_services, this.emptyWMSService]
													}
												}));
											}}
										/>
									</div>
								</FormGroup>
								<Tooltip target="wmsUrlHelp" placement="right">
									https://.../geoserver/wms
								</Tooltip>
								<Tooltip target="wmsLayersHelp" placement="right">
									layer1,layer2,...,layerN
								</Tooltip>
							</Col>
						</Row>

						<Row>
							<Col sm="12">
								<FormGroup tag="fieldset" className="p-2">
									<Label><T>relative_documents</T></Label>
									<Table>
										<Thead>
											<Tr>
												<Td><T>title</T></Td>
												<Td>URL</Td>
												<Td/>
											</Tr>
										</Thead>
										<Tbody>
											{
												this.state.values.legislation ? this.state.values.legislation.map((doc, index) => (
													<Tr  key={`legislation_${index}`}>
														<Td>
															<Input required name="title" value={doc.title} onChange={(e) => this.handleLegislationChange(e, index)}/>
														</Td>
														<Td>
															<Input required name="file" value={doc.file} onChange={(e) => this.handleLegislationChange(e, index)}/>
														</Td>
														<Td>
															<i
																role="button"
																className="fa fa-trash-o"
																onClick={() => {
																	this.setState(prevState => ({
																		values: {
																			...prevState.values,
																			legislation: [...prevState.values.legislation.slice(0, index), ...prevState.values.legislation.slice(index + 1)]
																		}
																	}));
																}}
															/>
														</Td>
													</Tr>
												)) : ''
											}
										</Tbody>
									</Table>
									<div className="float-right">
										<i
											role="button"
											className="fa fa-plus mr-2"
											onClick={() => {
												this.setState(prevState => ({
													values: {
														...prevState.values,
														legislation: [...prevState.values.legislation, this.emptylegislation]
													}
												}));
											}}
										/>
									</div>
								</FormGroup>
								<Tooltip target="wmsUrlHelp" placement="right">
									https://.../geoserver/wms
								</Tooltip>
								<Tooltip target="wmsLayersHelp" placement="right">
									layer1,layer2,...,layerN
								</Tooltip>
							</Col>
						</Row>

						<Row>
							<Col className="text-right">
								<Button color="success" type="submit" className="mr-2">Submit</Button>
								<Button type="button" onClick={this.resetForm}>Reset</Button>
							</Col>
						</Row>
					</CardBody>
				</Card>
				{ this.state.showProgress &&
					<Modal isOpen={this.state.showProgress} className="modal-md">
						<ModalBody>
							<p>Please wait while uploading...</p>
							<Progress animated value={this.props.progress} />
							<div className="text-right p-1">
								<Button type="button" onClick={() => {
									cancelUpload('Operation canceled by the user.');
									this.setState({showProgress: false});
								}}>
									Cancel
								</Button>
							</div>
						</ModalBody>
					</Modal>
				}
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({
	rules: state.forms.validation.rules,
	validationPending: state.forms.validation.pending,
	contentPending: state.forms.pending,
	content: state.forms.content,
	validationScope: state.forms.validation.scope,
	valid: state.forms.valid,
	messages: state.forms.validation_msgs,
	progress: state.upload.progress,
	activeCategory: state.project.activeCategory,
	mapSettings: state.ui.settings.values.map,
});

EditProject = connect(mapStateToProps)(EditProject);

export default EditProject;
