import React, { Component } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import PhotoGallery from 'react-photo-gallery';
import Tooltip from 'react-tooltip';

import SelectedImage from 'library/components/selectedImage';

class LibraryInput extends Component {

	constructor(props) {
		super(props);
		this.state = {
			photos: JSON.parse(props.items).map(elem => ({
				src: elem.resource,
				width: 2,
				height: 2,
				uuid: elem.uuid,
				title: elem.title,
				description: elem.description,
				documentation: elem.documentation,
			})),
			selected: (props.value && props.value !== '') ? props.value.split(';') : [],
			value: props.value || '',
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selected !== this.state.selected)
			this.setState({value: this.state.selected.join(';')}, () => {
				this.props.onChange({target: {name: this.props.name, value: this.state.value}});
			});

		if (prevProps.value !== this.props.value)
			this.setState({
				value: this.props.value,
				selected: this.props.value.split(';'),
				photos: [...this.state.photos],
			});
	}

	handleChange(event) {
		const target = event.target;
		this.setState({value: target.value});
		this.props.onChange({target: {name: this.props.name, value: target.value}});
	}

	handleSelect(event, { photo }) {
		if (this.props.readOnly)
			return;
		const { selected } = this.state;
		const index = selected.indexOf(photo.uuid);
		if (index === -1) {
			this.setState({
				selected: this.props.allowmultiple ? [...selected, photo.uuid] : [photo.uuid],
				photos: [...this.state.photos],
			});
		} else {
			this.setState({
				selected: this.props.allowmultiple ? [...selected.slice(0, index), ...selected.slice(index + 1)] : [],
				photos: [...this.state.photos],
			});
		}
	}

	imageRenderer = ({index, left, top, key, photo}) => {
		const { src, width, height, uuid } = photo;

		return (
			<React.Fragment key={key}>
				<SelectedImage
					data-tip
					data-for={`img_${photo.uuid}`}
					selected={this.state.selected.includes(photo.uuid)}
					margin="2px"
					index={index}
					photo={{src, width, height, uuid}}
					left={left}
					top={top}
					onClick={this.handleSelect}
				/>
				{ (photo.title || photo.description || photo.documentation) &&
					<Tooltip id={`img_${photo.uuid}`} delayShow={200} delayUpdate={5000}>
						<h5 className="pb-2">{photo.title}</h5>
						<p className="text-justify mx-auto" style={{maxWidth: '300px', wordBreak: 'break-word'}}>{photo.description}</p>
						<div className="text-center">
							<a href={photo.documentation} target="_blank" rel="noopener noreferrer">{photo.documentation}</a>
						</div>
						<div className="text-right">
							<a href={photo.src} target="_blank" rel="noopener noreferrer">
								<i className="fa fa-search-plus mr-2"/>{this.props.messages['larger image'] || 'Larger Image'}
							</a>
						</div>
					</Tooltip>
				}
			</React.Fragment>
		);
	};

	render() {

		const { photos } = this.state;
		const {required, name, readOnly} = this.props;

		return (
			<FormGroup>
				<PhotoGallery photos={photos} renderImage={this.imageRenderer} />
				<input style={{opacity: 0}} value={this.state.value} onChange={() => {}} required={required}/>
			</FormGroup>
		);
	}
}

export default LibraryInput;
