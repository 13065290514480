import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import { requestData } from 'core/ducks/list';
import { updateData } from 'core/ducks/update';
import { Loading } from 'core/components';
import { buildPath } from 'core/model/lib/urlTools';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';
import { setActiveCategory } from '../../ducks/project';
import { DragNdrop, Draggable, Handle } from '../../components/dnd';

import '../../style/toolbox.scss';

class Generic extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: '0',
			tree: null,
			showToolbox: false,
		};

		this.toggle = this.toggle.bind(this);
		this.handleActiveCategoryChange = this.handleActiveCategoryChange.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	componentDidMount() {
		this.props.dispatch( requestData('category') );
	}

	toggle(tab) {
		if (this.state.activeTab !== tab)
			this.setState({activeTab: tab});
	}

	handleActiveCategoryChange(event) {
		const { value, checked, name } = event.target;
		const { dispatch } = this.props;
		if (checked) {
			dispatch( setActiveCategory(value, name) );
		} else {
			dispatch( setActiveCategory('') );
		}
	}

	onDragEnd(result) {
		const { source, destination, draggableId } = result;
		if (!destination)
			return;

		const sortOrder = {};
		Object.values(this.props.categories).forEach((category, index) => {
			if (destination.index === index)
				sortOrder[draggableId] = index;
			if (source.index === index)
				return;
			if (destination.index <= index && source.index >= index) {
				sortOrder[category.token] = index+1;
			} else {
				sortOrder[category.token] = index;
			}
		});
		this.props.dispatch( updateData('category/sortOrder/', sortOrder, false) ).then(() => {
			this.props.dispatch( requestData('category') );
		});
	}

	render() {

		const { pending, categories, activeCategory } = this.props;

		return (
			<div className="animated fadeIn">
				<Nav tabs>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='0' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('0'); }}
						>
							Categories
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={this.state.activeTab} className="scroller mx-2 mt-2">
					<TabPane tabId="0">
						{ pending ?
							<Loading/>
							:
							<DragNdrop onDragEnd={this.onDragEnd} droppableId="categories_droppable_area">
								{ Object.values(categories).map((category, index) => (
									<Draggable
										key={`row_${category.token}`}
										draggableId={category.token}
										index={index}
										onMouseOver={() => {
											if (this.state.showToolbox !== category.token)
												this.setState({showToolbox: category.token});
										}}
										onMouseOut={() => {this.setState({showToolbox: false})}}
										className="row"
									>
										<Col
											className="p-1 mb-2"
											style={{border: activeCategory===category.token ? '1px groove #2196F3' : '0 groove #2196F3'}}
										>
											<img
												className="w-100 h-auto"
												src={category.summary_img}
												alt={category.summary_img_alt}
												title={category.title}
												style={{filter: category.active ? 'none' : 'opacity(40%)'}}
											/>
											<div
												className="toolbox left animated fadeIn fadeOut"
												style={{display: this.state.showToolbox===category.token ? 'inline-block' : 'none'}}
											>
												<Handle/>
											</div>
											<div
												className="toolbox right animated fadeIn fadeOut"
												style={{display: this.state.showToolbox===category.token ? 'inline-block' : 'none'}}
											>
												<i
													className="fa fa-pencil buttons"
													title="Edit"
													onClick={() => {
														this.props.history.push(buildPath(DynamicRoutes.CategoryEdit, [category.token]));
													}}
												/>
												<label className="input-container" title="Active Category">
													<input
														checked={activeCategory === category.token}
														type="checkbox"
														value={category.token}
														name={category.mname}
														onChange={this.handleActiveCategoryChange}
													/>
													<span className="checkmark buttons"/>
												</label>
											</div>
										</Col>
									</Draggable>
								))}
							</DragNdrop>
						}
						<NavLink tag={Link} to={StaticRoutes.CategoryAdd} className="float-right pr-0"><i className="fa fa-plus"/> Add new category</NavLink>
					</TabPane>
				</TabContent>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	pending: state.list.category.pending,
	categories: state.list.category.data,
	activeCategory: state.project.activeCategory,
});

Generic = connect(mapStateToProps)(Generic);

export default withRouter(Generic);
