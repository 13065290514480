import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'library',
		url: StaticRoutes.Library,
		icon: 'fa fa-folder-open',
		role: roles.ADMIN,
		children: [
			{
				name: 'gallery',
				icon: 'fa fa-camera-retro',
				url: StaticRoutes.Gallery
			},
			{
				name: 'upload',
				icon: 'fa fa-cloud-upload',
				url: StaticRoutes.Upload,
			},
			{
				name: 'documentation',
				icon: 'fa fa-book',
				url: StaticRoutes.DocumentationAdmin,
			},
			{
				name: 'views',
				icon: 'fa fa-television',
				url: StaticRoutes.LibraryViewAdmin,
			},
			{
				name: 'backups',
				icon: 'fa fa-download',
				url: StaticRoutes.LibraryExport,
			},
		]
	},
];

export default menu;
