import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Modal, ModalHeader, ModalBody, Form, FormGroup, FormFeedback, FormText,
	InputGroup, InputGroupAddon, InputGroupText, Label, Button,
} from 'reactstrap';

import { Input } from 'core/components';
import { getValidation, validate } from 'core/ducks/forms';
import { Editor } from 'editor';
import { File } from 'input-fields';
import { getBaseUrl } from 'core/model/lib/urlTools';
import { uploadData } from 'core/ducks/upload';
import { StaticRoutes } from '../../model/routes';

import T from 'modules/i18n';

class DocumentationAdmin extends Component {

	constructor(props) {
		super(props);
		this.emptyValues = {
			uri: '',
			title: '',
			comment: '',
			html: '',
			resource: '',
			type: 'html',
		};
		this.initialValues = {...this.emptyValues};
		this.state = {
			values: {...this.initialValues},
			file: {},
			textCopied: null,
			method: 'post',
		};
		this.baseUrl = getBaseUrl() + StaticRoutes.Documentation + '/';
		this.fullUriRef = React.createRef();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
		this.handlePost = this.handlePost.bind(this);
		this.handlePut = this.handlePut.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.copyToClipboard = this.copyToClipboard.bind(this);
	}

	componentDidMount() {
		this.props.dispatch( getValidation('documentation') );
		if (this.props.item && this.props.item !== '') {
			this.initialValues = {...this.props.item};
			this.setState({
				values: {
					...this.state.values,
					...this.initialValues,
				},
				method: 'put',
			});
		}
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		dispatch(validate(this.state.values, rules, 'documentation', this.initialValues)).then(() => {
			if (this.props.valid)
				this.handleUpload();
		});
	}

	handleUpload() {
		if (this.state.method === 'post') {
			this.handlePost();
		} else {
			this.handlePut();
		}
	}

	handlePost() {
		const formData = new FormData();
		const { values, file } = this.state;
		Object.keys(values).forEach(key => {
			if (key === 'resource') {
				if ((values.type === 'resource' && file.name)) {
					formData.append('resource', file)
				} else {
					formData.append('resource', null);
				}
			} else if (key !== 'type') {
				formData.append(key, values[key]);
			}
		});
		this.props.dispatch(
			uploadData('documentation', formData)
		).then(() => this.props.toggle());
	}

	handlePut() {
		const formData = new FormData();
		formData.append('_method', 'PUT');
		const { values, file } = this.state;
		let changed = false;
		Object.keys(values).forEach(key => {
			if (key === 'resource') {
				if ((values.type === 'resource' && file.name)) {
					changed = true;
					formData.append('resource', file)
				}
			} else if (key !== 'type' && values[key] !== this.initialValues[key]) {
				changed = true;
				formData.append(key, values[key]);
			}
		});
		if (changed)
			this.props.dispatch(
				uploadData(`documentation/uuid/${this.props.item.uuid}`, formData)
			).then(() => this.props.toggle());
	}

	handleChange(event) {
		const { name, value, type, files } = event.target;
		const newState = {
			values: {
				...this.state.values,
				[name]: value,
			},
		};
		if (type === 'file')
			newState.file = files[0];
		if (name === 'type') {
			if (value === 'html') newState.file = {};
			if (value === 'resource') newState.values = {
				...newState.values,
				html: '',
			};
		}
		this.setState({...newState});
	}

	handleEditorChange(html) {
		this.setState({
			values: {
				...this.state.values,
				html,
			},
		});
	}

	copyToClipboard() {
		const elem = this.fullUriRef.current;
		elem.select();
		elem.setSelectionRange(0, 99999);
		document.execCommand('copy');
		this.setState({textCopied: elem.value});
	}

	render() {
		const { isOpen, toggle, rules, validationMsg } = this.props;
		const { values, textCopied } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		if (this.props.validationPending || this.props.validationScope !== 'documentation')
			return null;

		return (
			<Modal isOpen={isOpen} className="modal-lg" scrollable>
				<ModalHeader toggle={toggle}><T>documentation</T></ModalHeader>
				<ModalBody>
					<FormGroup className="float-right mb-0">
						<Label>
							<T>type</T>
						<Input disabled={!this.props.item ? false : true} type="select" value={values.type} onChange={this.handleChange} name="type">
							<option value="html">HTML</option>
							<option value="resource">{messages.file || 'File'}</option>
						</Input>
						</Label>
					</FormGroup>
					<div className="clearfix"/>
					<Form onSubmit={this.handleSubmit}>
						<FormGroup>
							<Label className="mb-0" htmlFor="uri_input">URI</Label>
							<InputGroup>
								<InputGroupAddon addonType="prepend" className="pr-0">
									<InputGroupText className="text-muted" style={{paddingRight: '1px', borderRightWidth: 0, backgroundColor: 'white'}}>
										<label htmlFor="uri_input" className="mb-0">{this.baseUrl}</label>
									</InputGroupText>
								</InputGroupAddon>
								<Input
									id="uri_input"
									className="pl-0 no-outline-on-focus border-left-0"
									style={{height: 'inherit'}}
									name="uri"
									value={values.uri}
									onChange={this.handleChange}
									pattern={rules.uri.validation}
									valid={validationMsg.uri === ''}
								/>
								<InputGroupAddon addonType="append">
									<InputGroupText>
										<i
											className="fa fa-clipboard"
											role="button"
											title={textCopied ? `Copied: ${textCopied}` : 'Copy to clipboard'}
											onClick={this.copyToClipboard}
										/>
									</InputGroupText>
								</InputGroupAddon>
								<FormFeedback><T>{validationMsg.uri || rules.uri.message}</T></FormFeedback>
								{ values.type === 'resource' &&
									<FormText color="muted">
										<T>documentation_instruction_for_uri</T>
									</FormText>
								}
							</InputGroup>
							<input style={{position: 'absolute', left: '-9999px'}} value={this.baseUrl + values.uri} onChange={() => {}} ref={this.fullUriRef} />
						</FormGroup>
						<FormGroup>
							<Label className="mb-0 w-100">
								<T>title</T>
								<Input
									value={values.title}
									name="title"
									onChange={this.handleChange}
									pattern={rules.title.validation}
									valid={validationMsg.title === ''}
								/>
								<FormFeedback><T>{validationMsg.title || rules.title.message}</T></FormFeedback>
							</Label>
						</FormGroup>
						<FormGroup>
							<Label className="mb-0 w-100">
								<T>comment</T>
								<Input
									type="textarea"
									value={values.comment}
									name="comment"
									onChange={this.handleChange}
									pattern={rules.comment.validation}
									valid={validationMsg.comment === ''}
								/>
								<FormFeedback><T>{validationMsg.comment || rules.comment.message}</T></FormFeedback>
							</Label>
						</FormGroup>
						{ values.type === 'resource' &&
							<FormGroup>
								<Label className="mb-0 w-100">
									<T>file</T>
									<File
										id="file_input"
										required={this.state.method === 'post'}
										placeholder={messages['choose file']}
										buttonText={messages.browse}
										accept=".pdf,.doc,.docx"
										name="resource"
										value={values.resource}
										onChange={this.handleChange}
										valid={validationMsg.resource === ''}
										feedback={validationMsg.resource || rules.resource.message}
									/>
								</Label>
							</FormGroup>
						}
						{ values.type === 'html' &&
							<FormGroup>
								<Label className="mb-0 w-100">
									<T>content</T>
									<Editor
										locale={'el'}
										value={values.html}
										onChange={this.handleEditorChange}
										name="html"
										valid={validationMsg.html==='' || rules.html.message}
									/>
								</Label>
							</FormGroup>
						}
						<Button className="mr-2 float-right" color="success"><T>submit</T></Button>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	rules: state.forms.validation.rules,
	validationPending: state.forms.validation.pending,
	validationScope: state.forms.validation.scope,
	validationMsg: state.forms.validation_msgs,
	valid: state.forms.valid,
});

DocumentationAdmin = connect(mapStateToProps)(DocumentationAdmin);

export default DocumentationAdmin;
