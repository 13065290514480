import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { uniqueId } from 'lodash';
import { format } from 'date-fns';

import { getData } from 'core/ducks/update';

class ForumIntegration extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isDialogOpen: false,
			forumData: null,
		};
		this.buttonId = uniqueId('forum_button_');
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.isDialogOpen && this.state.isDialogOpen) {
			this.props.dispatch(
				getData(`forumPosts/topicID/${this.props.topicId}`)
			).then(forumData => this.setState({forumData}));
		}
	}

	render() {
		const { scope, topicId, topicUrl, messages } = this.props;
		const { isDialogOpen, forumData } = this.state;

		if (!topicUrl)
			return null;

		return (
			<>
				<Button id={this.buttonId} outline color="success" onClick={() => this.setState({isDialogOpen: !isDialogOpen})}>
					{messages.thread || 'Thread'}
				</Button>
				{ forumData &&
					<Popover placement="left" isOpen={isDialogOpen} target={this.buttonId} toggle={() => this.setState({isDialogOpen: !isDialogOpen})}>
						<PopoverHeader>{forumData.title}</PopoverHeader>
						<PopoverBody>
							{ forumData.posts.map(post => (
								<blockquote key={`post_${post.id}`} className="bg-light p-2">
									<span dangerouslySetInnerHTML={{__html: post.content}}/>
									<footer>
										<img src={post.avatar}/>{' '}<cite>{post.display_username || post.username}</cite>
										<div className="text-right">
											{format(new Date(post.updated_at), 'dd/MM/yyyy, H:mm')}
										</div>
									</footer>
								</blockquote>
							))}
							<div className="text-right">
								<Button outline color="primary">
									<a target="_blank" href={topicUrl} rel="noopener noreferrer">{messages.follow || 'Follow'}</a>
								</Button>
							</div>
						</PopoverBody>
					</Popover>
				}
			</>
		);
	}
}

ForumIntegration = connect(null)(ForumIntegration);

export default ForumIntegration;
