import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';

import { Loading } from 'core/components';
import { requestData } from 'core/ducks/list';
import { buildPath } from 'core/model/lib/urlTools';
import { Table, Tbody, Thead, Tr, Td} from 'css-table';
import { getBaseUrl } from 'core/model/lib/urlTools';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';

import T from 'modules/i18n';

class LibraryViewAdmin extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			page: null,
			query: '',
			pending: true,
			hiddenValue: '',
		};
		this.fullUriRef = React.createRef();
		this.baseUrl = getBaseUrl() + StaticRoutes.LibraryView + '/';

		this.fetchData = this.fetchData.bind(this);
		this.processData = this.processData.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData(page=null) {
		const { query } = this.state;
		if (this.state.page !== page)
			this.setState({page});
		const url = ['libraryView'];
		if (page) url.push(`page/${page}`);
		if (query !== '') url.push(`query/${query}`);
		this.props.dispatch(
			requestData(url[0], url.join('/'))
		).then(() => this.processData(page))
		.catch(err => console.warn(err));
	}

	processData(page) {
		const { list } = this.props;
		if (list.libraryView.data.values.length === 0) {
			this.setState({pending: false});
		} else if (!page) {
			this.setState({
				data: [...list.libraryView.data.values],
			}, () => this.setState({pending: false}));
		} else {
			this.setState({
				data: [
					...this.state.data,
					...list.libraryView.data.values,
				],
			});
		}
	}

	handleCopy(uri) {
		const url = this.baseUrl + uri;
		this.setState({hiddenValue: url}, () => {
			const elem = this.fullUriRef.current;
			elem.select();
			elem.setSelectionRange(0, 99999);
			document.execCommand('copy');
		});
	}

	render() {

		const { pending, data } = this.state;
		if (pending) return (<Loading/>);

		const { page, total_pages } = this.props.list.libraryView.data;

		return (
			<Card>
				<CardBody>
					<Row className="library animated fadeIn">
						<Col className="py-0">
							<Button
								color="primary"
								className="float-right m-2"
								onClick={() => this.props.history.push({
									pathname: StaticRoutes.EditLibraryView,
								})}
							>
								<T>add</T>
							</Button>
							<div className="clearfix"/>
						</Col>
					</Row>
					<input ref={this.fullUriRef} style={{position: 'absolute', left: '-99999px'}} value={this.state.hiddenValue} onChange={() => {}}/>
					<Table>
						<Thead>
							<Tr>
								<Td>URI</Td><Td/><Td><T>title</T></Td>
							</Tr>
						</Thead>
						<Tbody>
							{ data.map(row => (
								<Tr key={`row_${row.uuid}`}>
									<Td>
										<a href={StaticRoutes.LibraryView + '/' + row.uri} target="_blank" title={row.comment}>{row.uri}{' '}</a>
									</Td>
									<Td>
										<a href={StaticRoutes.LibraryView + '/' + row.uri} target="_blank" title="Open link" className="mr-2">
											<i className="fa fa-external-link"/>
										</a>
										<i className="fa fa-clipboard" role="link" title="Copy to clipboard" onClick={() => this.handleCopy(row.uri)}/>
										<i
											className="fa fa-pencil-square-o"
											role="link"
											title="Edit"
											onClick={() => this.props.history.push({
												pathname: buildPath(DynamicRoutes.EditLibraryView, [row.uuid]),
												state: {item: row}
											})}
										/>
									</Td>
									<Td>{row.title}</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
					{ page < total_pages &&
						<div>
							<Button className="d-block mx-auto" color="primary" onClick={() => this.fetchData(page + 1)}><T>load more</T></Button>
						</div>
					}
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({
	list: state.list,
});

LibraryViewAdmin = connect(mapStateToProps)(LibraryViewAdmin);

export default LibraryViewAdmin;
