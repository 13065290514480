import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, Badge } from 'reactstrap';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { Dropzone } from '../../components';
import { Table, Tbody, Td, Thead, Tr } from 'css-table';
import { ls } from '../../ducks/browse';
import type2class from '../../model/filetypes';

import '../../style/contextmenu.scss';
import '../../style/general.scss';

const ImageType = (props) => {
	const className = type2class(props.type);

	return <i className={`fa ${className}`} />;
};

class Browser extends Component {

	constructor(props) {
		super(props);

		this.handleContextClick = this.handleContextClick.bind(this);
		this.handleRowSelect = this.handleRowSelect.bind(this);
	}

	componentDidMount() {
		this.props.dispatch( ls('/') );
	}

	handleContextClick(event) {
		console.log(event);
	}

	handleRowSelect(elem) {
		console.log(elem.type);
		switch (elem.type) {
			case 'directory':
				this.props.dispatch( ls(elem.path) );
				break;

			default:
				break;
		}
	}

	render() {

		return (
			<section className="container">
				<Card className="file-browser">
					<CardHeader>
						{ this.props.tree.breadcrumb && this.props.tree.breadcrumb.map( (elem, index) =>
							<React.Fragment key={`breadcrumb_${index}`}>
								<span>/</span>
								<Badge color="light" className="px-2 py-1 my-1" onClick={() => {this.handleRowSelect(elem)}}>
									{elem.name}
								</Badge>
							</React.Fragment>
						)}
					</CardHeader>
					<CardBody>
						<Dropzone className="w-100">
							<Table>
								<Thead>
									<Tr>
										<Td>Name</Td>
										<Td>File Size</Td>
										<Td>Last modified</Td>
									</Tr>
								</Thead>
								<Tbody>
									{ this.props.tree.content ? this.props.tree.content.map(elem => (
										<React.Fragment key={`row_${elem.name}`}>
												<Tr className="tr-select" onClick={() => {this.handleRowSelect(elem)}}>
													<Td>
														<ContextMenuTrigger id={`context_menu_for_${elem.name}`}>
															<ImageType type={elem.type}/> {elem.name}
														</ContextMenuTrigger>
													</Td>
													<Td>{elem.size || '-'}</Td>
													<Td>{elem.modified || '-'}</Td>
												</Tr>
											<ContextMenu id={`context_menu_for_${elem.name}`}>
												<MenuItem onClick={this.handleContextClick}><i className="fa fa-eye"/> Preview</MenuItem>
												<MenuItem onClick={this.handleContextClick}><i className="fa fa-download"/> Download</MenuItem>
												<MenuItem onClick={this.handleContextClick}><i className="fa fa-trash-o"/> Delete</MenuItem>
											</ContextMenu>
										</React.Fragment>
									)) : <></>}
								</Tbody>
							</Table>
						</Dropzone>
					</CardBody>
				</Card>
			</section>
		);
	}
}

const mapStateToProps = (state) => ({
	tree: state.browse.tree,
});

Browser = connect(mapStateToProps)(Browser);

export default Browser;
