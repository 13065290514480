import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import L from 'leaflet';

import { getData } from 'core/ducks/update';
import Social from '../../components/social';

class Location extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: {},
			ready: false,
		};
	}

	componentDidMount() {
		this.props.dispatch(
			getData(`choice/token/${this.props.choice}`)
		).then(data => this.setState({data: {...data, geojson: JSON.parse(data.geojson)}, ready: true}))
		.catch(err => console.warn(err));
	}

	onMapReady = (mapRef) => {
		if (!mapRef) return;
		let leafletGeoJSON = new L.GeoJSON(this.state.data.geojson);
		leafletGeoJSON.addTo(mapRef.leafletElement);
		const bounds = leafletGeoJSON.getBounds();
		mapRef.leafletElement.fitBounds(bounds, {maxZoom: 18});
	}

	render() {
		const { isOpen, toggle, choice } = this.props;
		const { data, ready } = this.state;

		if (!ready) return null;

		return (
			<Modal isOpen={isOpen} className="modal-md" style={{zIndex: 100000}}>
				<ModalHeader toggle={toggle}></ModalHeader>
				<ModalBody>
					<LeafletMap
						style={{width: '100%', height: '300px'}}
						ref={this.onMapReady}
					>
						<TileLayer
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
						/>
					</LeafletMap>
					{ !data.predefined &&
						<Social token={choice} isOwner={false} scope="choice"/>
					}
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({});

Location = connect(mapStateToProps)(Location);

export default Location;
