import React, { Component } from 'react';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import L from 'leaflet';

class PredefinedStats extends Component {

	onMapReady = (mapRef) => {
		if (!mapRef) return;
		let leafletGeoJSON = new L.GeoJSON(this.props.geojson, {
			onEachFeature: (feature, layer) => {
				const label = L.marker(layer.getBounds().getCenter(), {
					icon: L.divIcon({
						className: 'label',
						html: `<div style="font-weight: 800; font-size: 120%; color: red">${feature.properties.selected}</div>`,
						iconSize: [50, 20],
					})
				}).addTo(mapRef.leafletElement);
			}
		});
		leafletGeoJSON.addTo(mapRef.leafletElement);
		const bounds = leafletGeoJSON.getBounds();
		mapRef.leafletElement.fitBounds(bounds, {maxZoom: 19});
	}

	render() {
		return (
			<LeafletMap
				style={{width: '100%', height: '400px'}}
				ref={this.onMapReady}
			>
				<TileLayer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
				/>
			</LeafletMap>
		);
	}
}

export default PredefinedStats;
