import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const onDropAccepted = (accepted) => {
	console.log(accepted);
};

const Dropzone = (props) => {
	const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
		noClick: true,
		onDropAccepted,
	});
	const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>);

	return (
		<>
			<div {...getRootProps({className: 'dropzone'})}>
				{props.children}
			</div>
			<aside>
				<h4>Files</h4>
				<ul>{files}</ul>
			</aside>
		</>
	);
};

export default Dropzone;
