import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { SecureContent } from 'core/components';
import * as roles from 'core/model/roles';
import Categories from '../layout/categories';
import AdminCategories from './adminCategories';
import T from 'modules/i18n';

class Generic extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: '0',
		};

		this.toggle = this.toggle.bind(this);
	}

	toggle(tab) {
		if (this.state.activeTab !== tab)
			this.setState({activeTab: tab});
	}

	render() {
		return (
			<div className="animated fadeIn">
				<SecureContent role={[roles.AUTHORIZED]}>
					<>
						<Nav tabs>
							<NavItem>
								<NavLink
									className={this.state.activeTab==='0' ? 'active text-info p-2' : 'border border-secondary p-2'}
									onClick={() => { this.toggle('0'); }}
								>
									<T>categories</T>
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={this.state.activeTab} className="scroller mx-2 mt-2">
							<TabPane tabId="0">
								<Categories scope="aside"/>
							</TabPane>
						</TabContent>
					</>
				</SecureContent>
				<SecureContent role={roles.REVIEWER}>
					<>
						<Nav tabs>
							<NavItem>
								<NavLink
									className={this.state.activeTab==='0' ? 'active text-info p-2' : 'border border-secondary p-2'}
									onClick={() => { this.toggle('0'); }}
								>
									<T>data</T>
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={this.state.activeTab} className="scroller mx-2 mt-2">
							<TabPane tabId="0">
								<AdminCategories/>
							</TabPane>
						</TabContent>
					</>
				</SecureContent>
			</div>
		);
	}
}

export default Generic;
