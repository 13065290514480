import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { ContextMenu, MenuItem, SubMenu } from 'react-contextmenu';
import { showMenu } from 'react-contextmenu/modules/actions';
import uniqid from 'uniqid';

import { PseudoLink, Title, ContextMenuTrigger } from '../components';
import { Handle } from '../components/dnd';
import discourseImg from '../img/discourse.png';

class Topic extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: uniqid(),
		};
	}

	render() {

		const { title, handleContextMenuClick, children, isEditOn, token, attributes, onThreadCreation, isNewThreadAllowed, ...otherProps } = this.props;
		const { id } = this.state;

		return (
			<Card {...otherProps}>
				<CardHeader>
					<ContextMenuTrigger isEditOn={isEditOn} id={id}>
						{ isEditOn && <span style={{marginLeft: '-1rem', marginRight: '1rem'}}><Handle/></span> }
						<Title>{title}</Title>
						{ isEditOn &&
							<PseudoLink
								id={id}
								className="float-right"
								icon="bars"
								onClick={(e) => {
									showMenu({
										position: {x: e.clientX, y: e.clientY},
										id,
										target: e.target,
									})
								}}
							/>
						}
					</ContextMenuTrigger>
				</CardHeader>
				<CardBody>
					{children}
					{ isEditOn &&
						<ContextMenu id={id}>
							<MenuItem onClick={() => {handleContextMenuClick('Topic', null)}}><i className="fa fa-cog"/>{' '}Edit</MenuItem>
							<SubMenu title={<span><i className="fa fa-info-circle" />{' '}Attributes</span>}>
								<MenuItem onClick={() => {handleContextMenuClick('Attribute', null)}}><i className="fa fa-plus" />{' '}Add attribute</MenuItem>
								<MenuItem divider />
								{ attributes.map(attribute => (
									<MenuItem key={`menu_item_${attribute.token}`} onClick={() => {handleContextMenuClick('Attribute', attribute)}}>
										{attribute.mname}
									</MenuItem>
								))}
							</SubMenu>
							<MenuItem onClick={() => {handleContextMenuClick('deleteTopic', null)}}>
								<i className="fa fa-trash-o"/>{' '}Delete
							</MenuItem>
							<MenuItem onClick={onThreadCreation} disabled={!isNewThreadAllowed}>
								<i><img src={discourseImg} width="12" height="12" alt=""/></i>{' '}Create thread
							</MenuItem>
						</ContextMenu>
					}
				</CardBody>
			</Card>
		);
	}
}

Topic.propTypes = {
	title: PropTypes.node.isRequired,
	handleContextMenuClick: PropTypes.func.isRequired,
	isEditOn: PropTypes.bool.isRequired,
	attributes: PropTypes.array.isRequired,
};

export default Topic;
