import * as reducers from './ducks';
import { default as routes } from './model/routes';
import { default as menuItems } from './menu';
import * as layout from './views/layout';
import * as sidebars from './views/sidebars';
import { default as GenericAside } from './views/sidebars/generic';
import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const userMenuItems = [{name: 'categories', route: StaticRoutes.Categories, className: 'fa fa-lightbulb-o', role: [roles.AUTHORIZED]}];
export default { reducers, ...routes, menuItems, layout, sidebars, GenericAside, userMenuItems };
