import React, { Component } from 'react';
import { DraggableContextConsumer } from './draggable';

class Handle extends Component {

	render() {

		return (
			<DraggableContextConsumer>
				{(provided) => (
					<i
						className="fa fa-arrows buttons"
						title="Change sort order"
						{...provided.dragHandleProps}
					/>
				)}
			</DraggableContextConsumer>
		);
	}
}

export default Handle;
