import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Card, CardBody, CardHeader, Row, Col, Modal, ModalHeader, ModalBody
} from 'reactstrap';

import { buildPath } from 'core/model/lib/urlTools';
import { getData, updateData, deleteData } from 'core/ducks/update';
import { requestData } from 'core/ducks/list';
import { ErrorPage } from 'core/views/pages';
import { Loading } from 'core/components';
import Alert from 'core/views/modals/alert';
import { toggleModal } from 'core/ducks/ui/modal';
import { DynamicRoutes } from '../../model/routes';
import { PseudoLink, TargetSetting, Topic, Attribute, Variable, DiscourseButton } from '../../components';
import { editSettings } from '../../ducks/project';
import { EditProject, AddTopic, Attribute as AttributeModal, Variable as VariableModal } from '../layout';
import { NewThreadModal } from '../modals';
import { DragDropContext, Droppable, Draggable } from '../../components/dnd';
import '../../style/general.scss';
import '../../style/contextmenu.scss';

class PlanProject extends Component {

	constructor(props) {
		super(props);
		this.state = {
			projectDetails: {},
			showModal: '',
			topicToken: null,
			attributeToken: null,
			variableToken: null,
			httpStatus: 200,
			refreshing: false,
			newThreadModal: {
				isOpen: false,
				scope: '',
				item: null,
			},
		};

		this.projectToken = '';
		this.actions = bindActionCreators({toggleModal, deleteData}, props.dispatch);

		this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
		this.fetchContent = this.fetchContent.bind(this);
		this.handleToggleModal = this.handleToggleModal.bind(this);
		this.handleContextClick = this.handleContextClick.bind(this);
		this.handleRefresh = this.handleRefresh.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	componentDidMount() {
		const { dispatch, match } = this.props;
		window.addEventListener('focus', this.handleVisibilityChange);
		dispatch( getData(`project/mname/${match.params.project}`) )
			.then(response => {
				this.projectToken = response.token;
				this.setState({projectDetails: response});
			})
			.catch(status => {
				this.setState({httpStatus: status});
			});
		this.fetchContent();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.editSettings && this.props.editSettings) {
			const { target, dispatch } = this.props;
			dispatch( editSettings(false) );
			const url = buildPath(DynamicRoutes.ProjectEdit, [this.projectToken]);
			if (target === 'same') {
				this.props.history.push(url);
			} else if (target === 'tab') {
				window.open(url, '_blank').focus();
			} else {
				this.setState({
					showModal: 'Project',
				});
			}
		}

		if (prevState.showModal !== '' && this.state.showModal === '')
			this.handleRefresh();

		if (prevProps.isGeneralModalOpen && !this.props.isGeneralModalOpen)
			this.handleRefresh();
	}

	componentWillUnmount() {
		window.removeEventListener('focus', this.handleVisibilityChange);
	}

	handleVisibilityChange(event) {
		if (event.type === 'focus' && !this.state.refreshing)
			this.handleRefresh();
	}

	fetchContent() {
		const { dispatch, match } = this.props;
		const promises = [];
		promises.push(dispatch( requestData('topic', `node/project/${match.params.project}`) ));
		promises.push(dispatch( requestData('attribute', `attribute/project/${match.params.project}`) ));
		promises.push(dispatch( requestData('variable', `variable/project/${match.params.project}`) ));

		Promise.all(promises).then(() => {
			if (this.state.refreshing)
				this.setState({
					refreshing: false
				});
		});
	}

	handleToggleModal(scope, topic=null, attribute=null, variable=null) {
		const { target, match } = this.props;
		if (target === 'modal') {
			this.setState({
				showModal: scope,
				topicToken: topic ? (attribute ? topic.mname : topic.token) : null,
				attributeToken: attribute ? (variable ? attribute.mname : attribute.token) : null,
				variableToken: variable ? variable.token : null,
			});
		} else {
			let route, params;
			switch (scope) {
				case 'Topic':
					route = topic ? 'TopicEdit' : 'TopicAdd';
					params = topic ? [match.params.project, topic.token] : [match.params.project];
					break;

				case 'Attribute':
					route = attribute ? 'AttributeEdit' : 'AttributeAdd';
					params = attribute ? [match.params.project, topic.mname, attribute.token] : [match.params.project, topic.mname];
					break;

				case 'Variable':
					route = variable ? 'VariableEdit' : 'VariableAdd';
					params = variable ? [match.params.project, topic.mname, attribute.mname, variable.token] : [match.params.project, topic.mname, attribute.mname];
					break;

				default:
					break;
			}
			let url = buildPath(DynamicRoutes[route], params);

			if (target === 'same') {
				this.props.history.push(url);
			} else {
				window.open(url, '_blank').focus();
			}
		}
	}

	handleContextClick(scope, topic, attribute, variable) {
		switch (scope) {

			case 'deleteTopic':
				this.actions.toggleModal(true,
					<Alert
						toggle={() => {this.actions.toggleModal()}}
						title="drop confirm"
						message="do you wish to continue"
						onConfirm={() => {
							this.actions.deleteData(`node/token/${topic.token}`, false);
						}}
					/>
				);
				break;

			case 'toggleAttributeVisibility':
				this.props.dispatch(
					updateData(`attribute/token/${attribute.token}`, {is_visible: !attribute.is_visible}, false)
				).then(this.handleRefresh);
				break;

			case 'deleteAttribute':
				this.actions.toggleModal(true,
					<Alert
						toggle={() => {this.actions.toggleModal()}}
						title="drop confirm"
						message="do you wish to continue"
						onConfirm={() => {
							this.actions.deleteData(`attribute/token/${attribute.token}`, false);
						}}
					/>
				);
				break;

			case 'toggleVariableVisibility':
				this.props.dispatch(
					updateData(`variable/token/${variable.token}`, {is_visible: !variable.is_visible}, false)
				).then(this.handleRefresh);
				break;

			case 'deleteVariable':
				this.actions.toggleModal(true,
					<Alert
						toggle={() => {this.actions.toggleModal()}}
						title="drop confirm"
						message="do you wish to continue"
						onConfirm={() => {
							this.actions.deleteData(`variable/token/${variable.token}`, false);
						}}
					/>
				);
				break;

			default:
				this.handleToggleModal(scope, topic, attribute, variable);
		}
	}

	handleRefresh() {
		this.setState({
			refreshing: true
		}, this.fetchContent);
	}

	closeModal() {
		this.setState({
			showModal: '',
			topicToken: null,
			attributeToken: null,
			variableToken: null,
		});
	}

	onDragEnd = (result) => {
		const { destination, draggableId, type } = result;
		const token = draggableId;
		let data;
		switch (type) {
			case 'variable':
				data = {attribute: destination.droppableId, sort_order: destination.index + 1};
				break;

			case 'attribute':
				data = {node: destination.droppableId, sort_order: destination.index + 1};
				break;

			default:
				data = {sort_order: destination.index + 1};
		}
		this.props.dispatch( updateData(`${type}/project/${this.projectToken}/token/${token}/sortOrder/`, data, false) ).then(() => {
			this.handleRefresh();
		});
	}

	handleThreadCreation = (scope, item) => {
		this.setState({newThreadModal: {
			isOpen: true,
			scope,
			item,
		}});
	}

	render() {

		const { isEditOn, topics, attributes, variables, pending } = this.props;
		const { showModal, httpStatus, refreshing, topicToken, attributeToken, variableToken, projectDetails, newThreadModal } = this.state;

		if (pending && !refreshing)
			return (<Loading />);

		if (httpStatus !== 200)
			return (<ErrorPage status={httpStatus} />);

		return (
			<Card className="ppcity-admin">
				<CardHeader>
					<span style={{fontSize: '125%', fontWeight: 'bold'}}>Plan Project</span>
					<span className="float-right">
						<i className="fa fa-refresh" role="button" onClick={this.handleRefresh} title="Refresh"/>
						<TargetSetting
							onThreadCreation={() => this.handleThreadCreation('project', projectDetails)}
							isNewThreadAllowed={!projectDetails.forum_topic_id ? true : false}
						/>
					</span>
				</CardHeader>
				<CardBody style={{opacity: refreshing ? 0.5 : 'inherit'}}>
					<DiscourseButton className="float-right mr-2" url={projectDetails.forum_topic_url}/>
					<div className="clearfix"/>
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Droppable droppableId="topics_droppable_area" type="node" disabled={!isEditOn}>
							{ Object.values(topics).map((topic, index) => (
								<Draggable
									key={`topic_${topic.token}`}
									draggableId={topic.token}
									index={index}
									disabled={!isEditOn}
								>
									<Topic
										isEditOn={isEditOn}
										title={topic.title}
										handleContextMenuClick={(scope, attribute) => {
											this.handleContextClick(scope, topic, attribute);
										}}
										attributes={Object.values(attributes).filter(elem => (elem.node === topic.token))}
										onThreadCreation={() => this.handleThreadCreation('node', topic)}
										isNewThreadAllowed={!topic.forum_topic_id ? true : false}
									>
										<DiscourseButton className="float-right mr-2" url={topic.forum_topic_url}/>
										<div className="clearfix"/>
										<Droppable droppableId={topic.token} type="attribute" disabled={!isEditOn}>
											{ Object.values(attributes).filter(elem => (elem.node === topic.token)).map((attribute, index2) => (
												<Draggable
													key={`attribute_${attribute.token}`}
													draggableId={attribute.token}
													index={index2}
													disabled={!isEditOn}
												>
													<Attribute
														isEditOn={isEditOn}
														title={attribute.name}
														icon={attribute.icon}
														icon_alt={attribute.icon_alt}
														handleContextMenuClick={(scope) => {
															this.handleContextClick(scope, topic, attribute);
														}}
														isVisible={attribute.is_visible}
													>
														<Droppable droppableId={attribute.token} type="variable" disabled={!isEditOn}>
															{ Object.values(variables)
																.filter(elem => (elem.attribute === attribute.token))
																.map((variable, index3) => (
																	<Draggable
																		key={`variable_${variable.token}`}
																		draggableId={variable.token}
																		index={index3}
																		disabled={!isEditOn}
																	>
																		<Variable
																			isEditOn={isEditOn}
																			mname={variable.mname}
																			type={variable.type}
																			isVisible={variable.is_visible}
																			handleContextMenuClick={(scope) => {
																				this.handleContextClick(scope, topic, attribute, variable)
																			}}
																		/>
																	</Draggable>
																))}
														</Droppable>
													</Attribute>
												</Draggable>
											))}
										</Droppable>
									</Topic>
								</Draggable>
							))}
						</Droppable>
					</DragDropContext>
					{ isEditOn &&
						<Row>
							<Col>
								<PseudoLink className="float-right" icon="plus" onClick={() => {this.handleToggleModal('Topic')}}>
									Add topics
								</PseudoLink>
							</Col>
						</Row>
					}
				</CardBody>
				{ showModal !== '' &&
					<Modal
						isOpen={showModal !== ''}
						toggle={this.closeModal}
						className={showModal==='Variable' ? 'modal-lg ppcity-admin' : 'modal-xl ppcity-admin'}
						scrollable={true}
					>
						<ModalHeader toggle={this.closeModal}>
							{((topicToken || attributeToken || variableToken) || showModal === 'Project') ? 'Edit ' : 'Add '}{showModal}
						</ModalHeader>
						<ModalBody>
							{ showModal==='Project' ?
								<EditProject
									match={{params: {project: this.projectToken}}}
									toggle={this.closeModal}
								/>
							: showModal==='Variable' ?
								<VariableModal
									match={{params: {...this.props.match.params, topic: topicToken, attribute: attributeToken, variable: variableToken}}}
									toggle={this.closeModal}
								/>
							: showModal==='Attribute' ?
								<AttributeModal
									match={{params: {...this.props.match.params, topic: topicToken, attribute: attributeToken}}}
									toggle={this.closeModal}
								/>
							: showModal==='Topic' ?
								<AddTopic
									match={{params: {...this.props.match.params, topic: topicToken}}}
									toggle={this.closeModal}
								/>
							: null}
						</ModalBody>
					</Modal>
				}
				{ newThreadModal.isOpen &&
					<NewThreadModal
						isOpen={newThreadModal.isOpen}
						toggle={() => this.setState({newThreadModal: {isOpen: false, scope: '', item: null}})}
						scope={newThreadModal.scope}
						item={newThreadModal.item}
					/>
				}
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({
	isEditOn: state.project.isEditOn,
	editSettings: state.project.editSettings,
	target: state.project.target,
	topics: state.list.topic.data,
	attributes: state.list.attribute.data,
	variables: state.list.variable.data,
	pending: (state.list.topic.pending || state.list.attribute.pending || state.list.variable.pending),
	isGeneralModalOpen: state.ui.modal.modalOpen,
});

PlanProject = connect(mapStateToProps)(PlanProject);

export default PlanProject;
