import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Address, CheckBox, DateInput, File, GeneralInput, PlainText, PolygonInput, Radio, LibraryInput,
} from 'input-fields';

class Input extends Component {

	render() {
		switch (this.props.type) {
			case 'address':
				return <Address {...this.props}/>

			case 'checkbox':
				return <CheckBox {...this.props}/>

			case 'date':
				return <DateInput {...this.props}/>

			case 'file':
				return <File {...this.props}/>

			case 'geometry':
				return <PolygonInput {...this.props}/>

			case 'radio':
				return <Radio {...this.props}/>

			case 'library':
				return <LibraryInput {...this.props}/>

			default:
				return <GeneralInput {...this.props}/>
		}
	}
}

export default Input;
