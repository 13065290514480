import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, Progress, Button, Card, CardBody } from 'reactstrap';

import { initContext, setContext } from 'core/ducks/context';
import { requestData } from 'core/ducks/list';
import { uploadData, cancelUpload } from 'core/ducks/upload';
import { postData } from 'core/ducks/update';
import { default as T, getLocale } from 'modules/i18n';
import { InvitationsStatusTable } from '../../components';
import InvitationsTable from './invitationsTable';

class Invitations extends Component {

	constructor(props) {
		super(props);
		const locale = props.dispatch(getLocale());
		this.state = {
			locale,
			validation: null,
			showProgress: false,
			report: null,
		};
	}

	componentDidMount() {
		const { locales } = this.props;
		this.props.dispatch(initContext({...this.state, locales, set: this.setContext, handleValidation: this.handleValidation}));
	}

	componentDidUpdate(prevProps, prevState) {

		if (prevProps.progress < 100 && this.props.progress === 100)
			this.setState({
				showProgress: false,
				submitted: true,
			});
	}

	setContext = (newContext) => {
		this.setState({...newContext});
	}

	handleValidation = (data) => {
		this.setState({showProgress: true}, () => {
			this.props.dispatch(uploadData('invitation/scope/validate', data))
				.then(validation => this.setState({validation}))
				.catch(err => {
					console.warn(err);
					this.setState({showProgress: false});
				});
		});
	}

	handleSend = (withOAuth=false) => {
		const url = withOAuth ? 'invitation/scope/sendWithOAuth' : 'invitation/scope/send';
		this.props.dispatch(postData(url, this.state.validation))
			.then((report => {
				this.setState({validation: null});
			}))
			.catch(err => console.warn(err));
	}

	render() {
		const { validation } = this.state;
		return (
			<Card>
				<CardBody>
					{ validation ?
						<>
							<InvitationsStatusTable data={validation.invitations} T={T}/>
							<div className="text-right">
								<Button color="success" className="mr-2" onClick={() => this.handleSend(false)}><T>send</T></Button>
								{ this.props.isOpenAuthEnabled &&
									<Button color="primary" className="mr-2" onClick={() => this.handleSend(true)}><T>send with oauth</T></Button>
								}
								<Button color="warning" className="mr-2" onClick={() => this.setState({validation: null})}><T>cancel</T></Button>
							</div>
						</>
						:
						<InvitationsTable/>
					}
				</CardBody>
				<Modal isOpen={this.state.showProgress} className="modal-md">
					<ModalBody>
						<p>Please wait while uploading...</p>
						<Progress animated value={this.props.progress} />
						<div className="text-right p-1">
							<Button type="button" onClick={() => {
								cancelUpload('Operation canceled by the user.');
								this.setState({showProgress: false});
							}}>
								<T>cancel</T>
							</Button>
						</div>
					</ModalBody>
				</Modal>
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({
	progress: state.upload.progress,
	locales: state.ui.settings.values.locales,
	isOpenAuthEnabled: state.ui.settings.values.isOpenAuthEnabled,
});

Invitations = connect(mapStateToProps)(Invitations);

export default Invitations;
