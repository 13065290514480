export const inputTypes = {
	radio: {
		icon: 'dot-circle-o',
		options: [
			{
				type: 'json',
				name: 'choices',
			},
		],
	},
	checkbox: {
		icon: 'check-square-o',
		options: {
			type: 'json',
			name: 'choices',
		},
	},
	text: {
		icon: 'font',
		options: {},
	},
	string: {
		icon: 'paragraph',
		options: {},
	},
	photo: {
		icon: 'picture-o',
		options: {},
	},
	document: {
		icon: 'file-text-o',
		options: {},
	},
	date: {
		icon: 'calendar-o',
		options: {},
	},
	number: {
		icon: 'sort-numeric-asc',
		options: {},
	},
	resource: {
		icon: 'bookmark-o',
		options: {},
	},
	library: {
		icon: 'book',
		options: {},
	}
};

export const geomTypes = {
	geom_point: {
		icon: 'map-marker',
		options: {},
	},
	geom_line: {
		icon: '',
		options: {},
	},
	geom_polygon: {
		icon: 'map-o',
		options: {},
	},
	geom_radio: {
		icon: 'dot-circle-o',
		options: {},
	},
	geom_select: {
		icon: 'location-arrow',
		options: {},
	}
};

export const inputOptions = {
	required: {
		scope: ['*'],
		type: 'boolean',
		label: 'Required',
		defaultValue: false,
	},
	label: {
		scope: ['*'],
		type: 'text',
		label: 'Label',
		defaultValue: '',
	},
	placeholder: {
		scope: ['text', 'string', 'photo', 'document'],
		type: 'text',
		label: 'Placeholder',
		defaultValue: '',
	},
	maxSize: {
		scope: ['photo', 'document'],
		type: 'number',
		step: 1,
		min: 0,
		label: 'Maximum Size',
		defaultValue: '',
	},
	accepts: {
		scope: ['document'],
		type: 'checkbox',
		options: ['doc', 'docx', 'pdf'],
		label: 'Document types',
		defaultValue: [],
	},
	choices: {
		scope: ['radio', 'checkbox'],
		type: 'array',
		defaultValue: [''],
	},
	minLength: {
		scope: ['text', 'string', 'number'],
		type: 'number',
		step: 1,
		min: 0,
		label: 'Minimum Length / Value',
		defaultValue: '',
	},
	maxLength: {
		scope: ['text', 'string', 'number'],
		type: 'number',
		step: 1,
		min: 0,
		label: 'Maximum Length / Value',
		defaultValue: '',
	},
	numberOfGeometries: {
		scope: ['geom_point', 'geom_line', 'geom_polygon'],
		type: 'number',
		step: 1,
		min: 1,
		label: 'Number of geometries',
		defaultValue: '',
	},
	geometricChoices: {
		scope: ['geom_radio', 'geom_select'],
		type: 'geometry',
		label: 'Geometric Choices',
		defaultValue: [],
	},
	minDate: {
		scope: ['date'],
		type: 'date',
		label: 'Minimum date',
		defaultValue: '',
	},
	maxDate: {
		scope: ['date'],
		type: 'date',
		label: 'Maximum date',
		defaultValue: '',
	},
	marker: {
		scope: ['geom_point'],
		type: 'marker',
		label: 'Marker',
		defaultValue: '',
	},
	color: {
		scope: ['geom_line', 'geom_polygon'],
		type: 'color',
		label: 'Color',
		defaultValue: '#ffffff',
	},
	transparency: {
		scope: ['geom_polygon'],
		type: 'transparency',
		label: 'Transparency',
		defaultValue: 1,
	},
	items: {
		scope: ['library'],
		type: 'library',
		label: 'Items',
		defaultValue: '',
	},
	allowMultiple: {
		scope: ['library'],
		type: 'boolean',
		label: 'Allow multiple',
		defaultValue: false,
	},
};

export const projectRoles = [
	'expert',
	'normal',
	'viewer',
];

export const rolesStyle = {
	[projectRoles[0]]: 'danger',
	[projectRoles[1]]: 'warning',
	[projectRoles[2]]: 'success',
};
