import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Badge, Button } from 'reactstrap';

import { buildPath } from 'core/model/lib/urlTools';
import { slideShow } from '../../ducks/library';
import { DynamicRoutes } from '../../model/routes';

import T from 'modules/i18n';

class Gallery extends Component {

	startSlideShow = () => {
		this.props.dispatch(slideShow(true));
	}

	handleEdit = () => {
		const url = buildPath(DynamicRoutes.Upload, [this.props.library.details.uuid]);
		this.props.history.push(url);
	}

	render() {

		const { details, pending, httpStatus } = this.props.library;

		if (pending || httpStatus !== 200)
			return null;

		return (
			<>
				<Row>
					<Col className="mx-1">
						<div style={{fontSize: '120%', fontWeight: 'bold'}}>
							{details.title}
						</div>
						<div>
							{details.tags.map(tag => (
								<Badge className="d-inline-block m-1 p-1" key={`badge_${tag}`}>{tag}</Badge>
							))}
						</div>
						<img className="w-100" src={details.resource} title={details.tooltip} alt={details.alt_text}/>
						{ details.documentation &&
							<>
								<a className="float-right mr-2" href={details.documentation} target="_blank"><T>documentation</T></a>
								<div className="clearfix"/>
							</>
						}
						{ details.description &&
							<p className="p-2 text-justify">{details.description}</p>
						}
					</Col>
				</Row>
				<Row>
					<Col>
						<Button className="d-block mx-auto" color="success" onClick={this.startSlideShow}>Start Slide Show</Button>
					</Col>
				</Row>
				<Row>
					<Col>
						<Button className="d-block mx-auto" color="primary" onClick={this.handleEdit}><T>edit</T></Button>
					</Col>
				</Row>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	library: state.library,
});

Gallery = connect(mapStateToProps)(Gallery);

export default Gallery;
