import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Card, CardHeader, CardBody, Form, FormGroup, Row, Col, Label, CustomInput,
	NavLink, Button, Progress, Modal, ModalBody, FormFeedback
} from 'reactstrap';

import { Input, Loading } from 'core/components';
import { getValidation, validate, getContent, clearMessages } from 'core/ducks/forms';
import { requestData } from 'core/ducks/list';
import { characterConverter } from 'core/model/lib';
import { toggleModal } from 'core/ducks/ui/modal';
import { buildPath } from 'core/model/lib/urlTools';
import { ErrorPage } from 'core/views/pages';
import { UploadImage } from 'core/views/modals';
import { uploadData, cancelUpload } from 'core/ducks/upload';
import { setActiveCategory } from '../../ducks/project';
import { getBaseUrl } from 'core/model/lib/urlTools';
import { DynamicRoutes as frontRoutes } from 'ppcity/model/routes'
import { DynamicRoutes } from '../../model/routes';
import T from 'modules/i18n';

import '../../style/general.scss';

class EditCategory extends Component {

	constructor(props) {
		super(props);

		this.initialValues = {
			mname: '',
			title: '',
			summary: '',
			summary_img: '',
			summary_img_alt: '',
			active: false,
			autosubscribe: false,
			forum_welcome_message: '',
		};

		this.state = {
			shortname: '',
			values: { ...this.initialValues },
			files: [],
			underSubmit: false,
			previewUrl: null,
			showProgress: false,
			category: props.match.params.category ? props.match.params.category : null,
			makeActive: false,
			httpStatus: 200,
		};

		this.actions = bindActionCreators({toggleModal}, props.dispatch);

		this.handleShortnameChange = this.handleShortnameChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.getModalValues = this.getModalValues.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.resetForm = this.resetForm.bind(this);
		this.uploadData = this.uploadData.bind(this);
		this.fetchContent = this.fetchContent.bind(this);
	}

	componentDidMount() {
		if (this.state.category)
			this.fetchContent(this.state.category);
		this.props.dispatch( getValidation('category') );
	}

	componentDidUpdate(prevProps, prevState) {

		if (prevProps.match.params.category !== this.props.match.params.category) {
			this.setState({
				category: this.props.match.params.category,
			});
			this.fetchContent(this.props.match.params.category);
		}

		if ( !prevState.underSubmit && this.state.underSubmit === 'post')
			this.uploadData('post');

		if ( !prevState.underSubmit && this.state.underSubmit === 'put')
			this.uploadData('put');

		if (prevProps.progress < 100 && this.props.progress === 100)
			this.setState({
				showProgress: false,
			});
	}

	fetchContent(category) {
		this.props.dispatch( getContent(`category/token/${category}`, 'category') )
		.then(status => {
			this.initialValues = Object.keys(this.initialValues)
				.reduce((obj, key) => ({
					...obj,
					[key]: this.props.content.category[key]
				}), {});

			this.setState({
				values: {...this.initialValues},
				shortname: this.initialValues.mname,
			});
		})
		.catch(status => {
			this.setState({httpStatus: status});
		});
	}

	uploadData(method) {
		let formData = new FormData();
		const { values } = this.state;
		let empty = true;
		Object.keys(values).forEach(key => {
			if (key !== 'summary_img' && this.initialValues[key] !== values[key]) {
				empty = false;
				formData.append(key, values[key]);
			}
		});
		if (this.state.files[0]) {
			empty = false;
			formData.append('summary_img', this.state.files[0]);
		}

		if (!empty || method === 'post') {
			this.setState({showProgress: true}, () => {
				if (method === 'put')
					formData.append('_method', 'put');
				const url = method === 'put' ? `category/token/${this.state.category}` : 'category';
				this.props.dispatch( uploadData(url, formData) )
					.then(response => {
						this.props.dispatch( requestData('category') );
						this.initialValues = {
							...this.state.values,
						};
						if (this.state.makeActive)
							this.props.dispatch(setActiveCategory(this.state.category || response.token, this.state.values.mname));
						this.setState({underSubmit: false});
						if (method === 'post') {
							this.setState({showProgress: false});
							this.props.history.push(buildPath(DynamicRoutes.CategoryEdit, [response.token]));
						}
					})
					.catch(error => {
						console.warn(error);
					});
			});
		} else {
			this.setState({underSubmit: false});
		}
	}

	handleShortnameChange(event) {
		const { value } = event.target;
		const { messages, dispatch } = this.props;
		if (messages.mname !== '')
			dispatch( clearMessages('mname') );
		this.setState({
			shortname: value,
			values: {
				...this.state.values,
				mname: characterConverter(value),
			}
		});
	}

	handleChange(event) {
		const { name, type, value } = event.target;
		const { messages, dispatch } = this.props;
		if (messages[name] !== '')
				dispatch( clearMessages(name) );
		this.setState({
			values: {
				...this.state.values,
				[name]: (type === 'checkbox' || type === 'switch') ? event.target.checked : value
			},
			underSubmit: false
		});
	}

	getModalValues(values) {
		const { file, alt, files } = values;
		this.setState({
			values: {
				...this.state.values,
				summary_img: file,
				summary_img_alt: alt,
			},
			files,
			previewUrl: files[0] ? URL.createObjectURL(files[0]) : null,
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		const method = this.state.category ? 'put' : 'post';
		dispatch(validate(this.state.values, rules, 'category', this.initialValues)).then(() => {
			if (this.props.valid)
				this.setState({underSubmit: method});
		});
	}

	resetForm() {
		this.setState({
			values: {...this.initialValues},
			shortname: this.initialValues.mname,
			files: [],
			previewUrl: null,
		});
	}

	render() {

		const { values, category, httpStatus } = this.state;
		const { rules, messages } = this.props;

		if (this.props.validationPending || this.props.validationScope !== 'category')
			return (<Loading />);

		if (httpStatus !== 200)
			return (<ErrorPage status={httpStatus} />);

		return (
			<Form className="ppcity-admin animated fadeIn" onSubmit={this.handleSubmit}>
				<Card>
					<CardHeader className="font-weight-bold">
						{category ? 'Edit Category' : 'New Category'}
					</CardHeader>
					<CardBody className="mx-3">
						<Row>
							<Col>
								<div className="p-0 float-sm-left">
									<NavLink
										disabled={!values.active || values.mname === ''}
										href={getBaseUrl() + buildPath(frontRoutes.AdminData, [values.mname])}
									>
										{getBaseUrl() + buildPath(frontRoutes.AdminData, [values.mname])}
									</NavLink>
								</div>
								<div className="float-sm-right">
									<FormGroup>
										<CustomInput
											id="active_input"
											type="switch"
											checked={values.active}
											name="active"
											onChange={this.handleChange}
											label="Visible"
										/>
									</FormGroup>
									<FormGroup>
										<CustomInput
											id="autosubscribe_input"
											type="switch"
											checked={values.autosubscribe}
											name="autosubscribe"
											onChange={this.handleChange}
											label="Autosubscribe"
										/>
									</FormGroup>
								</div>
							</Col>
						</Row>
						<FormGroup row className="clearfix">
							<Col className="py-0" sm="9" lg="10">
								<FormGroup>
									<Input
										style={{fontSize: 150+'%'}}
										name="title"
										autoComplete="off"
										value={values.title}
										onChange={this.handleChange}
										placeholder="Title"
										pattern={rules.title.validation}
										valid={messages.title === ''}
									/>
									<FormFeedback><T>{messages.title || rules.title.message}</T></FormFeedback>
								</FormGroup>
							</Col>
							<Col className="py-1" sm="3" lg="2" style={{margin: '-1.2rem 0 0 0'}}>
								<FormGroup>
									<Label
										style={{opacity: this.state.shortname==='' ? 0 : 1, fontSize: "75%"}}
										className="animated fadeIn fadeOut my-0"
									>
										Short name
									</Label>
									<Input
										style={{fontSize: 75+'%'}}
										name="shortname"
										autoComplete="off"
										value={this.state.shortname}
										onChange={this.handleShortnameChange}
										placeholder="Short name"
										className="d-inline-block"
										minLength={rules.mname.min_size}
										maxLength={rules.mname.max_size}
										valid={messages.mname === ''}
									/>
									<FormFeedback><T>{messages.mname || rules.mname.message}</T></FormFeedback>
								</FormGroup>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col sm="4" className="p-0 border" onClick={() => {
								this.actions.toggleModal(true,
									<UploadImage
										getValues={this.getModalValues}
										toggle={() => {this.actions.toggleModal()}}
										values={{file: values.summary_img, alt: values.summary_img_alt}}
									/>
								);
							}}>
								{ (this.state.previewUrl || values.summary_img) ?
									<img
										style={{width: '100%', maxWidth: '100%', height: 'auto'}}
										src={this.state.previewUrl || values.summary_img}
										alt="Uploaded"
									/>
									:
									<FormGroup>
										<div style={{margin: 0, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
											Click to browse a summary image.
										</div>
										<Input className="d-none" value={values.summary_img} valid={messages.summary_img === ''} onChange={() => {}}/>
										<FormFeedback><T>{messages.summary_img || rules.summary_img.message}</T></FormFeedback>
									</FormGroup>
								}
							</Col>
							<Col sm="8" className="py-0">
								<FormGroup>
									<Input
										type="textarea"
										name="summary"
										placeholder="Summary"
										rows={10}
										value={values.summary}
										onChange={this.handleChange}
										valid={messages.summary === ''}
									/>
									<FormFeedback><T>{messages.summary || rules.summary.message}</T></FormFeedback>
								</FormGroup>
							</Col>
						</FormGroup>
						{ this.props.isForumEnabled &&
							<FormGroup row>
								<Col className="py-0">
									<FormGroup>
										<Label htmlFor="forum_welcome_message_input">Forum welcome message</Label>
										<Input
											id="forum_welcome_message_input"
											type="textarea"
											name="forum_welcome_message"
											placeholder="Forum welcome message"
											rows={10}
											value={values.forum_welcome_message}
											onChange={this.handleChange}
											valid={messages.forum_welcome_message === ''}
										/>
										<FormFeedback><T>{messages.forum_welcome_message || rules.forum_welcome_message.message}</T></FormFeedback>
									</FormGroup>
								</Col>
							</FormGroup>
						}
						<Row>
							<Col className="text-right">
								<Button color="success" type="submit" className="mr-2">Save</Button>
								<Button color="primary" type="submit" className="mr-2" onClick={() => {this.setState({makeActive: true})}}>
									Save and make Active
								</Button>
								<Button type="button" onClick={this.resetForm}>Reset</Button>
							</Col>
						</Row>
					</CardBody>
				</Card>
				{ this.state.showProgress &&
					<Modal isOpen={this.state.showProgress} className="modal-md">
						<ModalBody>
							<p>Please wait while uploading...</p>
							<Progress animated value={this.props.progress} />
							<div className="text-right p-1">
								<Button type="button" onClick={() => {
									cancelUpload('Operation canceled by the user.');
									this.setState({showProgress: false});
								}}>
									<T>cancel</T>
								</Button>
							</div>
						</ModalBody>
					</Modal>
				}
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({
	rules: state.forms.validation.rules,
	validationPending: state.forms.validation.pending,
	contentPending: state.forms.pending,
	content: state.forms.content,
	validationScope: state.forms.validation.scope,
	valid: state.forms.valid,
	messages: state.forms.validation_msgs,
	progress: state.upload.progress,
	activeCategory: state.project.activeCategory,
	isForumEnabled: state.ui.settings.values.isForumEnabled,
});

EditCategory = connect(mapStateToProps)(EditCategory);

export default EditCategory;
