import React, { Component } from 'react';
import Control from 'react-leaflet-control';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

import layersImg from '../img/layers.png';
import '../style/layersControl.scss';

const Button = (props) => {
	if (props.fontImg) {
		return <i className={`border bg-white p-2 rounded fa fa-${props.fontImg}`} role="button" style={{height: 32, width: 32}} onClick={props.handleClick} />;
	} else {
		return <img className="border bg-white p-2 rounded" role="button" src={layersImg} alt="layers" height={32} width={32} onClick={props.handleClick} />;
	}
}

class LayersControl extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showMenu: false
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleCheckChange = this.handleCheckChange.bind(this);
	}

	handleClick() {
		this.setState({
			showMenu: !this.state.showMenu
		});
	}

	handleCheckChange(event) {
		const { value, checked, type } = event.target;
		if (type === 'checkbox') {
			this.props.onLayerChange({
				[value]: {
					...this.props.layers[value],
					checked,
				}
			});
		} else {
			this.props.onLayerChange(
				Object.keys(this.props.layers).reduce((obj, key) => ({
					...obj,
					[key]: {
						...this.props.layers[key],
						checked: key===value,
					}
				}), {})
			);
		}
	}

	render() {
		const { layers, label, type, position, disabled } = this.props;

		return (
			<Control className="leaflet-controls layers-control" position={position} >
				{ position.substring(0,3) === 'top' && <Button fontImg={this.props.fontImg} handleClick={this.handleClick}/> }
				{ this.state.showMenu &&
					<Container className="border rounded-bottom p-2" style={{backgroundColor: 'rgba(200,200,200,0.5)'}}>
						{ label && <p className="mb-1">{label}</p>}
						{Object.keys(layers).map(key => (
							<label key={`layer_${layers[key].name}`} className={disabled ? 'input-container disabled mb-3' : 'input-container active mb-3'}>
								{` ${layers[key].name}`}
								<input
									type={type}
									checked={layers[key].checked}
									onChange={this.handleCheckChange}
									value={key}
									name={layers[key].name}
									disabled={disabled}
								/>
								<span
									className={type === 'checkbox' ? 'checkmark' : 'checkmark radio'}
									style={layers[key].checked ? {backgroundColor: (layers[key] && layers[key].style) ? layers[key].style.color : 'blue'} : {}}
								/>
							</label>
						))}
					</Container>
				}
				{ position.substring(0,6) === 'bottom' && <Button fontImg={this.props.fontImg} handleClick={this.handleClick}/> }
			</Control>
		);
	}
}

LayersControl.propTypes = {
	position: PropTypes.oneOf(['topleft', 'topright', 'bottomleft', 'bottomright']),
	layers: PropTypes.object.isRequired,
	onLayerChange: PropTypes.func.isRequired,
	fontImg: PropTypes.string,
	type: PropTypes.oneOf(['checkbox', 'radio']),
	label: PropTypes.string,
	disabled: PropTypes.bool,
};

LayersControl.defaultProps = {
	type: 'checkbox',
	position: 'topleft',
	disabled: false,
};

export default LayersControl;
