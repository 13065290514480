import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../style/pseudoLink.scss';

class PseudoLink extends Component {

	render() {

		let { className, icon, children, onClick, otherProps } = this.props;
		className = className || '';

		return (
			<div className="pseudo-link">
				<span className={`link ${className}`} {...otherProps} onClick={onClick}>
					{icon && <i className={`fa fa-${icon}`} />}{' '}
					{children}
				</span>
			</div>
		);
	}
}

PseudoLink.propTypes = {
	className: PropTypes.string,
	icon: PropTypes.string,
	onClick: PropTypes.func.isRequired,
};

export default PseudoLink;
