import * as L from 'leaflet';
import proj4 from 'proj4';

export const createHexColor = (str) => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	let colour = '#';
	for (let i = 0; i < 3; i++) {
		let value = (hash >> (i * 8)) & 0xFF;
		colour += ('00' + value.toString(16)).substr(-2);
	}
	return colour;
};

export const getContrast = (hexcolor) => {
	if (hexcolor.slice(0, 1) === '#') {
		hexcolor = hexcolor.slice(1);
	}
	if (hexcolor.length === 3) {
		hexcolor = hexcolor.split('').map(function (hex) {
			return hex + hex;
		}).join('');
	}
	const r = parseInt(hexcolor.substr(0, 2), 16);
	const g = parseInt(hexcolor.substr(2, 2), 16);
	const b = parseInt(hexcolor.substr(4, 2), 16);
	const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
	return (yiq >= 128) ? 'black' : 'white';
};

export const getLayerType = (layer) => {
	if (layer instanceof L.Marker)
		return 'POINT';
	if ((layer instanceof L.Polyline) && ! (layer instanceof L.Polygon))
		return 'LINESTRING';
	if (layer instanceof L.Polygon)
		return 'POLYGON';
	if (layer instanceof L.Circle)
		return 'CIRCLE';
};

export const toWKT = (layer, projection) => {
	const coords = [];
	const type = getLayerType(layer);
	let latlngs;
	switch (type) {
		case 'POLYGON':
			latlngs = layer.getLatLngs();
			latlngs.forEach((polygon, index) => {
				coords[index] = [];
				polygon.forEach(latlng => {
					let projected = proj4('EPSG:4326', projection, [latlng.lng, latlng.lat]);
					coords[index].push(projected[0] + ' ' + projected[1]);
				});
			});
			if (coords.length === 0) {
				return null;
			} else if (coords.length === 1) {
				coords[0].push(coords[0][0]);
				return "POLYGON((" + coords[0].join(",") + "))";
			} else {
				const polygons = [];
				coords.forEach(polygon => {
					polygon.push(polygon[0]);
					polygons.push("(" + polygon.join(",") + ")");
				});
				return "MULTIPOLYGON((" + polygons.join(",") + "))";
			}


		case 'LINESTRING':
			latlngs = layer.getLatLngs();
			latlngs.forEach(latlng => {
				let projected = proj4('EPSG:4326', projection, [latlng.lng, latlng.lat]);
				coords.push(projected[0] + ' ' + projected[1]);
			});
			return type + "(" + coords.join(",") + ")";

		default:
			let { lat, lng } = layer.getLatLng()
			let projected = proj4('EPSG:4326', projection, [lng, lat]);
			return "POINT(" + projected[0] + " " + projected[1] + ")";
	}
};
