import * as roles from '../model/roles';

const Admin = '/admin';
// const PassRecovery = '/password_recovery';

export const Pages = {
	Admin
};

/**
 * Static routes
 */

// Admin
const AdminDashboard = '/admin/dashboard';
// const Dashboard = '/dashboard';
const Profile = '/profile';
const Root = '/';
const Settings = '/admin/settings';
const GeneralSettings = Settings + '/general';
const FrontPageSettings = Settings + '/frontpage';
const Templates =  Settings + '/templates';
const GDPRPolicy = '/policy';
const GDPRPolicySettings = Settings + '/policy';

export const StaticRoutes = {
	AdminDashboard,
	// Dashboard,
	Profile,
	Root,
	Settings,
	GeneralSettings,
	FrontPageSettings,
	Templates,
	GDPRPolicy,
	GDPRPolicySettings,
};

const TemplatesDynamic = Templates + '/(template)?/:template?';

export const DynamicRoutes = {
	Templates: TemplatesDynamic,
};

/**
 * Routes for error pages
 */

const Forbidden = '/error/403';
const NotFound = '/error/404';

export const ErrorPages = {
	Forbidden,
	NotFound,
};

export const routes = [
	{
		url: Root,
		name: 'home',
		component: 'Front',
		exact: true,
		role: [roles.GUEST, roles.AUTHORIZED]
	},
	{
		url: Root,
		name: 'home',
		to: AdminDashboard,
		exact: true,
		role: roles.REVIEWER
	},
	{
		url: AdminDashboard,
		name: 'dashboard',
		component: 'Dashboard',
		role: roles.REVIEWER
	},
	{
		url: Settings,
		name: 'settings',
		to: GeneralSettings,
		exact: true,
	},
	{
		url: GeneralSettings,
		name: 'general',
		component: 'GeneralSettings',
		role: roles.ADMIN,
	},
	{
		url: FrontPageSettings,
		name: 'front',
		component: 'FrontPageSettings',
		role: roles.ADMIN,
	},
	{
		url: Profile,
		name: 'profile',
		component: 'Profile',
		role: roles.AUTHORIZED
	},
	{
		url: TemplatesDynamic,
		name: 'email templates',
		contextComponent: 'SelectTemplate',
		component: 'Templates',
		role: roles.ADMIN,
		exact: true,
	},
	{
		url: GDPRPolicy,
		name: 'policy',
		component: 'GDPRPolicy',
	},
	{
		url: GDPRPolicySettings,
		name: 'policy',
		component: 'GDPRPolicySettings',
		role: roles.ADMIN,
	},
];

export default routes;
