import React, { Component } from 'react';
import { Input, Label } from 'reactstrap';
import uniqid from 'uniqid';

class Transparency extends Component {

	constructor(props) {
		super(props);
		this.inputId = uniqid();
	}

	render() {

		const { attributes } = this.props;

		return (
			<>
				{ attributes &&
					<Label>{attributes.label}</Label>
				}
				<Input
					id={this.inputId}
					className="d-inline-block"
					type="range"
					name={this.props.name}
					onChange={this.props.onChange}
					min={0}
					max={1}
					step={0.02}
					value={this.props.value}
				/>
			</>
		);
	}
}

export default Transparency;
