import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';

import { requestData } from 'core/ducks/list';
import { getData, updateData } from 'core/ducks/update';
import { Loading } from 'core/components';
import { buildPath } from 'core/model/lib/urlTools';
import { ExportToCSV } from 'core/model/lib';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';
import { DragNdrop, Draggable, Handle } from '../../components/dnd';
import T from 'modules/i18n';

class ListProject extends Component {

	constructor(props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.handleExport = this.handleExport.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.activeCategory !== this.props.activeCategory)
			this.fetchData();
	}

	fetchData() {
		if (this.props.activeCategory) {
			this.props.dispatch(requestData('project', `project/category/${this.props.activeCategory}`))
		} else {
			this.props.dispatch(requestData('project'));
		}
	}

	onDragEnd(result) {
		const { source, destination, draggableId } = result;
		if (!destination)
			return;

		const sortOrder = {};
		Object.values(this.props.projects).forEach((item, index) => {
			if (destination.index === index)
				sortOrder[draggableId] = index;
			if (source.index === index)
				return;
			if (destination.index <= index && source.index >= index) {
				sortOrder[item.token] = index+1;
			} else {
				sortOrder[item.token] = index;
			}
		});
		this.props.dispatch( updateData('project/sortOrder/', sortOrder, false) )
			.then(() => this.fetchData());
	}

	handleExport(token) {
		const csv = new ExportToCSV('export.csv');
		this.props.dispatch(
			getData(`export/project/${token}/output_format/csv`, true, 'blob')
		).then(data => csv.createLink(data));
	}

	render() {

		if (this.props.pending)
			return (<Loading/>);

		const { projects } = this.props;

		return (
			<div className="animated fadeIn">
				{ (this.props.activeCategory && this.props.activeCategory !== '') &&
					<>
						<Button className="float-right" color="success" onClick={() => this.props.history.push(StaticRoutes.ProjectAdd)}>
							<T>add</T>
						</Button>
						<div className="clearfix" />
					</>
				}
				<DragNdrop
					onDragEnd={this.onDragEnd}
					droppableId="projects_droppable_area"
					className="row"
				>
					{ Object.values(projects).map((item, index) => (
						<Draggable
							key={`item_${item.token}`}
							draggableId={item.token}
							index={index}
							className="col col-12"
						>
							<Card>
								<CardHeader>
									<Handle className="float-left"/>
									<div className="float-right">
										<a
											href={`/api/export/project/${item.mname}/output_format/geojson`}
											target="_blank"
											rel="noopener noreferrer" title="GeoJSON"
										>
											<i style={{fontSize: '120%'}} className="fa fa-code mr-2"/>
										</a>
										<i
											role="link"
											style={{fontSize: '120%'}}
											className="fa fa-file-excel-o mr-2"
											onClick={() => this.handleExport(item.token)}
											title="CSV"
										/>
										<Link to={buildPath(DynamicRoutes.ProjectEdit, [item.token])}>
											<i
												className="fa fa-pencil buttons"
												title="Edit"
											/>
										</Link>
									</div>
								</CardHeader>
								<CardBody>
									<Row>
										<Col lg={4}>
											<Link to={buildPath(DynamicRoutes.ProjectPlan, [item.mname])}>
												<img style={{height: 'auto'}} className="w-100" src={item.summary_img} alt={`${item.title} Summary`} />
											</Link>
										</Col>
										<Col>
											<Link className="font-weight-bold" to={buildPath(DynamicRoutes.ProjectPlan, [item.mname])}>{item.title}</Link>
											<p>{item.summary}</p>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Draggable>
					))}
				</DragNdrop>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	projects: state.list.project.data,
	pending: state.list.project.pending,
	activeCategory: state.project.activeCategory,
});

ListProject = connect(mapStateToProps)(ListProject);

export default ListProject;
