import React, { Component } from 'react';
import {
	EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailIcon, FacebookIcon, LinkedinIcon, TwitterIcon,
} from 'react-share';

class SocialIntegration extends Component {

	constructor(props) {
		super(props);
		this.initialOpacity = 0.3;
		this.state = {
			isExpanded: false,
			opacity: this.initialOpacity,
		};
	}

	render() {
		const { messages, url } = this.props;
		const { isExpanded, opacity } = this.state;

		return (
			<div
				className={this.props.className}
				style={{...this.props.style, opacity}}
				onMouseEnter={() => this.setState({isExpanded: true, opacity: 1})}
				onMouseLeave={() => this.setState({isExpanded: false, opacity: this.initialOpacity})}
			>
				{ isExpanded &&
					<div className="d-inline-block animated" style={{opacity: 0.85}}>
						<EmailShareButton url={url} title="Email">
							<EmailIcon/>
						</EmailShareButton>
						<FacebookShareButton url={url} title="Facebook">
							<FacebookIcon/>
						</FacebookShareButton>
						<LinkedinShareButton url={url} title="LinkedIn">
							<LinkedinIcon/>
						</LinkedinShareButton>
						<TwitterShareButton url={url} title="Twitter">
							<TwitterIcon/>
						</TwitterShareButton>
					</div>
				}
				<i
					role="link"
					title={messages.share || 'Share'}
					className="fa fa-share d-inline-block"
					style={{fontSize: '200%'}}
					onClick={() => this.setState({isExpanded: !isExpanded})}
				/>
			</div>
		);
	}
}

export default SocialIntegration;
