import * as roles from 'core/model/roles';

const Library = '/admin/library';
const Gallery = Library + '/gallery';
let Upload = Library + '/upload';
const DocumentationAdmin = Library + '/documentation';
let Documentation = '/documentation';
const LibraryViewAdmin = Library + '/views';
let EditLibraryView = LibraryViewAdmin + '/edit';
let LibraryView = '/library';
const LibraryExport = Library + '/export';

export const StaticRoutes = {
	Library,
	Gallery,
	Upload,
	DocumentationAdmin,
	Documentation,
	LibraryViewAdmin,
	EditLibraryView,
	LibraryView,
	LibraryExport,
};

const regex = '/(item)?/:item([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?';
Upload = Upload + regex;
Documentation += '/:documentation';
EditLibraryView += regex;
LibraryView += '/:library';

export const DynamicRoutes = {
	Upload,
	LibraryView,
	EditLibraryView,
};

export const routes = [
	{
		url: Library,
		name: 'library',
		role: roles.REVIEWER,
		to: Gallery,
		exact: true,
	},
	{
		url: Gallery,
		name: 'gallery',
		role: roles.REVIEWER,
		component: 'Gallery',
		contextComponent: 'GallerySidebar',
		exact: true,
	},
	{
		url: Upload,
		name: 'upload',
		role: roles.REVIEWER,
		component: 'Upload',
		contextComponent: 'UploadSidebar',
		exact: true,
	},
	{
		url: DocumentationAdmin,
		name: 'documentation',
		role: roles.REVIEWER,
		component: 'DocumentationAdmin',
		exact: true,
	},
	{
		url: Documentation,
		name: 'documentation',
		role: roles.AUTHORIZED,
		component: 'Documentation',
	},
	{
		url: LibraryViewAdmin,
		name: 'views',
		role: roles.REVIEWER,
		component: 'LibraryViewAdmin',
		exact: true,
	},
	{
		url: EditLibraryView,
		name: 'edit',
		role: roles.REVIEWER,
		component: 'EditLibraryView',
		exact: true,
	},
	{
		url: LibraryView,
		name: 'library',
		role: roles.AUTHORIZED,
		component: 'LibraryView',
	},
	{
		url: LibraryExport,
		name: 'backups',
		role: roles.ADMIN,
		component: 'LibraryExport',
		contextComponent: 'LibraryImportSidebar',
	},
];

const combined = {StaticRoutes, routes};

export default combined;
