import React, { Component } from 'react';

class Title extends Component {

	render() {

		return (
			<span style={{fontSize: '125%', fontStyle: 'italic', color: this.props.color}} className="title">
				{this.props.children}
			</span>
		);
	}
}

export default Title;
