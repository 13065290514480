import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { getData } from 'core/ducks/update';
import { buildPath } from 'core/model/lib/urlTools';
import { DynamicRoutes } from '../../model/routes';

import T from 'modules/i18n';

class Upload extends Component {

	constructor(props) {
		super(props);
		this.state = {
			photos: [],
		};

		this.handleClick = this.handleClick.bind(this);
		this.fetchData = this.fetchData.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.tags !== this.props.tags)
			this.fetchData(this.props.tags);
	}

	handleClick(uuid) {
		const url = buildPath(DynamicRoutes.Upload, [uuid]);
		this.props.history.push(url);
	}

	fetchData(tags) {
		const url = ['library']
		url.push('limit/5')
		if (tags && tags !== '') url.push(`/taglist/${tags}`);
		url.push('sort/created/sort_method/DESC');
		this.props.dispatch(
			getData(url.join('/'))
		).then(response => {
			this.setState({
				photos: [...response.values]
			});
		});
	}

	render() {

		return (
			<Row>
				<Col className="mx-1">
					<div className="text-center font-weight-bold"><T>recent relevant posts</T></div>
					{ this.state.photos.map(photo => (
						<img key={`photo_${photo.uuid}`} role="button" className="p-2 d-block w-100" src={photo.resource} onClick={() => this.handleClick(photo.uuid)}/>
					))}
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	tags: state.library.tags
});

Upload = connect(mapStateToProps)(Upload);

export default Upload;
