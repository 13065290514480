import * as roles from 'core/model/roles';
/**
 * Static routes
 */

// Admin
const Browse = '/admin/browse';

export const StaticRoutes = {
	Browse,
};

/**
 * Dynamic routes
 */

export const DynamicRoutes = {};

export const routes = [
	{
		url: Browse,
		name: 'browse',
		component: 'Browser',
		role: roles.ADMIN,
		exact: true,
	},
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
