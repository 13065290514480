import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardText, Row, Col } from 'reactstrap';

import { StaticRoutes } from '../model/routes';
import { StaticRoutes as Routes } from 'ppcity/model/routes';
import { Dashboard as Template, SecureContent } from 'core/components';
import * as roles from 'core/model/roles';
import { getData } from 'core/ducks/update';
import T from 'modules/i18n';

class Dashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			stats: {},
			pending: true,
		};
	}

	componentDidMount() {
		this.props.dispatch( getData('stats') )
			.then(stats => this.setState({stats, pending: false}));
	}

	render() {
		const { stats, pending } = this.state;

		if (pending)
			return null;

		return (
			<Template>
				<SecureContent role={roles.REVIEWER}>
					<Card>
						<CardHeader>
							<Link to={StaticRoutes.Category} className="no-decoration">
								<i className="fa fa-database"></i> { stats.categories } <T>categorie_s</T>
							</Link>
						</CardHeader>
						<CardBody className="text-white bg-info">
							<Row>
								<Col>
									<Link to={StaticRoutes.ProjectList}>
										<CardText className="text-light text-lowercase"><big>{stats.projects.total}</big> <T>projects</T></CardText>
									</Link>
									<ul>
										<li><big>{stats.projects.active}</big> <T>active_projects</T></li>
										<li><big>{stats.projects.public}</big> <T>public_projects</T></li>
										<li><big>{stats.projects.hidden}</big> <T>hidden_projects</T></li>
									</ul>
									<Link to={Routes.AdminDataStatic}>
										<CardText className="text-light text-lowercase"><big>{stats.answers.total}</big> <T>answers</T></CardText>
									</Link>
									<ul>
										<li><big>{stats.answers.week}</big> <T>last week</T></li>
										<li><big>{stats.answers.month}</big> <T>last month</T></li>
									</ul>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</SecureContent>
			</Template>
		);
	}

}

const mapStateToProps = (state) => ({
	role: state.profile.user.role,
});

Dashboard = connect(mapStateToProps)(Dashboard);

export default Dashboard;
