import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';

import { getParameters } from 'core/model/lib/urlTools';
import { Loading } from 'core/components';
import { getData } from 'core/ducks/update';
import { routes } from '../../model/routes';

class Documentation extends Component {

	constructor(props) {
		super(props);
		let re = /:[^\/]+/;
		let route = this.props.match.path.replace(re, '');
		re = `${route}`;
		this.params = this.props.location.pathname.replace(re, '');

		this.state = {
			content: {},
			file: {},
			pending: true,
		};
	}

	componentDidMount () {
		this.props.dispatch(
			getData(`?action=documentation&uri=${encodeURIComponent(this.params)}`)
		).then(content => {
			if (content.type === 'html') {
				this.setState({
					content,
					pending: false,
				});
			} else {
				this.props.dispatch(
					getData(content.resource, false, 'blob')
				).then(file => {
					const url = window.URL.createObjectURL(new Blob([file]));
					const link = document.createElement('a');
					link.href = url;
					let filename = this.params.split('/');
					filename = filename[filename.length - 1]
					link.setAttribute('download', filename);
					document.body.appendChild(link);
					link.click();
					link.parentNode.removeChild(link);
					this.setState({
						content,
						file,
						pending: false,
					});
				});
			}
		});
	}

	render() {

		if (this.state.pending)
			return (<Loading/>);

		const { content } = this.state;

		if (content.type === 'html') {

			return (
				<Card className="library animated fadeIn">
					<CardHeader>{content.title}</CardHeader>
					<CardBody dangerouslySetInnerHTML={{__html: content.html}}/>
				</Card>
			);

		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({});

Documentation = connect(mapStateToProps)(Documentation);

export default Documentation;
