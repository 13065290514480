import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'administration',
		url: StaticRoutes.Administration,
		icon: 'icon-people',
		role: roles.ADMIN,
		children: [
			{
				name: 'Projects',
				icon: 'fa fa-book',
				url: StaticRoutes.ProjectList
			},
			{
				name: 'members',
				icon: 'fa fa-address-book',
				url: StaticRoutes.Participants,
			},
			{
				name: 'enroll',
				icon: 'fa fa-user-plus',
				url: StaticRoutes.Enroll,
			},
			{
				name: 'profile',
				icon: 'fa fa-id-card-o',
				url: StaticRoutes.Profile,
			},
			{
				name: 'invitations',
				icon: 'fa fa-paper-plane-o',
				url: StaticRoutes.Invitations,
			}
		]
	},
];

export default menu;
