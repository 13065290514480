import React, { Component } from 'react';
import { Input, FormGroup, Label, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import InputArray from './inputArray';
import Checkbox from './checkbox';
import Bool from './bool';
import Geometry from './geometry';
import DateInput from './dateInput';
import LibraryInput from './libraryInput';
import Marker from './marker';
import Color from './color';
import Transparency from './transparency';

class InputForOptions extends Component {

	render() {

		const { components, ...otherProps } = this.props;
		const { type } = this.props.attributes;

		switch (type) {
			case 'array':
				return (
					<InputArray type={type} {...otherProps}/>
				);

			case 'checkbox':
				return (
					<Checkbox type={type} {...otherProps} />
				);

			case 'boolean':
				return (
					<Bool type={type} {...otherProps} />
				);

			case 'geometry':
				return (
					<Geometry type={type} {...otherProps} />
				);

			case 'date':
				return (
					<DateInput type={type} {...otherProps} />
				);

			case 'marker':
				return (
					<Marker type={type} {...otherProps} />
				);

			case 'color':
				return (
					<Col md={6}>
						<Color type={type} {...otherProps} />
					</Col>
				);

			case 'transparency':
				return (
					<Col md={6}>
						<Transparency type={type} {...otherProps} />
					</Col>
				);

			case 'library':
				return (
					<LibraryInput type={type} Gallery={components.Gallery} T={components.T} Element={components.Element} {...otherProps} />
				);

			default:
				return (
					<FormGroup className="py-0">
						<Label style={{opacity: this.props.value==='' ? 0 : 1, fontSize: "85%"}} className="animated fadeIn fadeOut my-0">
							{this.props.attributes.label}
						</Label>
						<Input placeholder={this.props.attributes.label} type={type} {...otherProps} className="w-75" />
					</FormGroup>
				);
		}
	}
}

InputForOptions.propTypes = {
	attributes: PropTypes.object.isRequired,
	value: PropTypes.any.isRequired,
	name: PropTypes.node.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default InputForOptions;
