import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Card, CardHeader, CardBody, Form, FormGroup, FormFeedback, Modal, ModalHeader, ModalBody,
	InputGroup, InputGroupAddon, InputGroupText, Label, Button,
} from 'reactstrap';

import { Input, Loading } from 'core/components';
import { getValidation, validate } from 'core/ducks/forms';
import { getData, postData, updateData } from 'core/ducks/update';
import { Editor } from 'editor';
import { File } from 'input-fields';
import { getBaseUrl } from 'core/model/lib/urlTools';
import { Element } from '../../components';
import { StaticRoutes } from '../../model/routes';
import Gallery from './gallery';

import T from 'modules/i18n';

class EditLibraryView extends Component {

	constructor(props) {
		super(props);
		this.emptyValues = {
			uri: '',
			title: '',
			description: '',
			items: [],
		};
		this.initialValues = {...this.emptyValues};
		this.state = {
			values: {...this.initialValues},
			textCopied: null,
			method: 'post',
			item: props.match.params.item || null,
			isSelectModalOpen: false,
			library: []
		};
		this.baseUrl = getBaseUrl() + StaticRoutes.LibraryView + '/';
		this.fullUriRef = React.createRef();

		this.initialize = this.initialize.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
		this.handlePost = this.handlePost.bind(this);
		this.handlePut = this.handlePut.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.copyToClipboard = this.copyToClipboard.bind(this);
	}

	componentDidMount() {
		this.props.dispatch( getValidation('libraryView') );
		if (this.state.item && this.state.item !== '') {
			if (this.props.location.state.item) {
				this.initialize(this.props.location.state.item);
			} else {
				this.props.dispatch(
					getData(`libraryView/uuid/${this.props.match.params.item}`)
				).then(data => this.initialize(data))
				.catch(err => console.warn(err));
			}
		}
	}

	initialize(data) {
		const items = JSON.parse(data.items);
		this.initialValues = {...data, items};
		this.getLibraryData(items);
		this.setState({
			values: {
				...this.state.values,
				...this.initialValues,
			},
			method: 'put',
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		dispatch(validate(this.state.values, rules, 'libraryView', this.initialValues)).then(() => {
			if (this.props.valid)
				this.handleUpload();
		});
	}

	handleUpload() {
		if (this.state.method === 'post') {
			this.handlePost();
		} else {
			this.handlePut();
		}
	}

	handlePost() {
		const data = {...this.state.values, items: JSON.stringify(this.state.values.items)};
		this.props.dispatch(
			postData('libraryView', data)
		);
	}

	handlePut() {
		const { values } = this.state;
		let changed = false;
		const data = {};
		Object.keys(this.initialValues).forEach(key => {
			if (values[key] !== this.initialValues[key]) {
				changed = true;
				data[key] = key === 'items' ? JSON.stringify(values[key]) : values[key];
			}
		});
		if (changed)
			this.props.dispatch(
				updateData(`libraryView/uuid/${this.state.item}`, data)
			);
	}

	handleChange(event) {
		const { name, value, type, files } = event.target;
		const newState = {
			values: {
				...this.state.values,
				[name]: value,
			},
		};
		this.setState({...newState});
	}

	copyToClipboard() {
		const elem = this.fullUriRef.current;
		elem.select();
		elem.setSelectionRange(0, 99999);
		document.execCommand('copy');
		this.setState({textCopied: elem.value});
	}

	getModalValues = (items) => {
		this.setState({isSelectModalOpen: false});
		this.setState({
			values: {
				...this.state.values,
				items,
			}
		});
		this.getLibraryData(items);
	}

	getLibraryData = (items) => {
		this.props.dispatch(
			getData(`library/uuid/${items.join(';')}`)
		).then(library => this.setState({library}))
		.catch(err => console.warn(err));
	}

	render() {
		const { rules, validationMsg } = this.props;
		const { values, textCopied, isSelectModalOpen } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		if (this.props.validationPending || this.props.validationScope !== 'libraryView')
			return (<Loading/>);

		const library = Array.isArray(this.state.library) ? this.state.library : [this.state.library];

		return (
			<Card className="library animated fadeIn">
				<CardHeader><T>views</T></CardHeader>
				<CardBody>
					<Form onSubmit={this.handleSubmit}>
						<Button className="mr-2 float-right" color="success"><T>submit</T></Button>
						<div className="clearfix"/>
						<FormGroup>
							<Label className="mb-0" htmlFor="uri_input">URI</Label>
							<InputGroup>
								<InputGroupAddon addonType="prepend" className="pr-0">
									<InputGroupText className="text-muted" style={{paddingRight: '1px', borderRightWidth: 0, backgroundColor: 'white'}}>
										<label htmlFor="uri_input" className="mb-0">{this.baseUrl}</label>
									</InputGroupText>
								</InputGroupAddon>
								<Input
									id="uri_input"
									className="pl-0 no-outline-on-focus border-left-0"
									style={{height: 'inherit'}}
									name="uri"
									value={values.uri}
									onChange={this.handleChange}
									pattern={rules.uri.validation}
									valid={validationMsg.uri === ''}
								/>
								<InputGroupAddon addonType="append">
									<InputGroupText>
										<i
											className="fa fa-clipboard"
											role="button"
											title={textCopied ? `Copied: ${textCopied}` : 'Copy to clipboard'}
											onClick={this.copyToClipboard}
										/>
									</InputGroupText>
								</InputGroupAddon>
								<FormFeedback><T>{validationMsg.uri || rules.uri.message}</T></FormFeedback>
							</InputGroup>
							<input style={{position: 'absolute', left: '-9999px'}} value={this.baseUrl + values.uri} onChange={() => {}} ref={this.fullUriRef} />
						</FormGroup>
						<FormGroup>
							<Label className="mb-0 w-100">
								<T>title</T>
								<Input
									value={values.title}
									name="title"
									onChange={this.handleChange}
									pattern={rules.title.validation}
									valid={validationMsg.title === ''}
								/>
								<FormFeedback><T>{validationMsg.title || rules.title.message}</T></FormFeedback>
							</Label>
						</FormGroup>
						<FormGroup>
							<Label className="mb-0 w-100">
								<T>description</T>
								<Input
									type="textarea"
									value={values.description}
									name="description"
									onChange={this.handleChange}
									pattern={rules.description.validation}
									valid={validationMsg.description === ''}
								/>
								<FormFeedback><T>{validationMsg.description || rules.description.message}</T></FormFeedback>
							</Label>
						</FormGroup>
						<Button type="button" className="d-block mx-auto px-5" color="warning" onClick={() => this.setState({isSelectModalOpen: true})}>
							<T>select from library</T>
						</Button>
					</Form>
					{ library.map((elem, index) => (
						<Element key={`element_${elem.uuid}`} {...elem} index={index}/>
					))}
					{ isSelectModalOpen &&
						<Modal isOpen={isSelectModalOpen} className="modal-xl" scrollable>
							<ModalHeader toggle={() => this.setState({isSelectModalOpen: false})}><T>select from library</T></ModalHeader>
							<ModalBody>
								<Gallery isModal={true} getValues={this.getModalValues} items={values.items}/>
							</ModalBody>
						</Modal>
					}
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	rules: state.forms.validation.rules,
	validationPending: state.forms.validation.pending,
	validationScope: state.forms.validation.scope,
	validationMsg: state.forms.validation_msgs,
	valid: state.forms.valid,
});

EditLibraryView = connect(mapStateToProps)(EditLibraryView);

export default EditLibraryView;
