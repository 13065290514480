import React, {Component} from 'react';
import {
	ModalHeader, Modal, ModalBody, Button, Form, FormGroup, Label, Input, Card, CardBody
} from 'reactstrap';
import {connect} from 'react-redux';
import T from 'modules/i18n';
import {postData} from 'core/ducks/update';
import {pushNotification} from "core/ducks/notifications";
import {fetchAppProfile} from "../../ducks/categoriesprofile";

class ConsultationUserProfileForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			profile: this.props.profile,
			answers: props.data
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		const {id, name, value, type} = event.target;
		if (type === 'radio')
			this.setState({
				[name]: {
					question: name,
					choice: parseInt(id)
				}
			});
		else
			this.setState({
				[name]: {
					question: name,
					choice: value
				}
			});
	}

	handleSubmit(event) {
		event.preventDefault();

		let answers = JSON.parse(JSON.stringify(this.state));
		delete answers.profile;
		delete answers.answers;
		let data = Object.values(answers);

		const len = data.length;
		if (len === this.state.profile.length) {
			this.props.dispatch(
				postData(`data/scope/profile/category/${this.props.category}`, data)
			).then((e) => {
				this.props.dispatch(
					fetchAppProfile(true)
				);
				const {toggle} = this.props;
				toggle();
			});


		} else {
			this.props.dispatch(pushNotification({
				body: 'you must answer all questions',
				type: "warning"
			}))
		}
	}

	render() {
		const {isOpen} = this.props;

		return (
			<Modal isOpen={isOpen} className="modal-lg">
				<ModalHeader>
					<T>consultation profile</T>
				</ModalHeader>
				<ModalBody>
					<p><T>consultation_profile_instructions</T></p>
					<p><T>consultation_profile_subinstructions</T></p>
					<Form onSubmit={this.handleSubmit} style={{maxHeight: '35em', overflow: 'auto'}}>
						<Card>
							<CardBody>
								{this.state.profile ?
									this.state.profile.map((question) => (
										<FormGroup key={`formGroup_${question.token}`}>
											<legend>{question.question}</legend>
											{
												question.choices.map((choice, index) => (
													<FormGroup check key={`${question.token}_${index}`}
															   style={{paddingBottom: '0.5em'}}>
														<Label check>
															<Input
																id={choice.id}
																type="radio"
																name={question.token}
																onChange={this.handleChange}
															/>{' '}
															<img src={choice.icon} alt={choice.content}
																 className={'image'}
																 style={{width: '1.5em'}}/>
															{' '}
															{choice.content}
														</Label>
													</FormGroup>
												))
											}
										</FormGroup>
									))
									: ''}
								<div className="text-right">
									<Button color="warning" className="m-2"><T>submit</T></Button>
								</div>
							</CardBody>
						</Card>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n
});

ConsultationUserProfileForm = connect(mapStateToProps)(ConsultationUserProfileForm);

export default ConsultationUserProfileForm;
