import React, { Component } from 'react';
import {
	Form, FormGroup, Input, Label, Button, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { DynamicRoutes } from 'core/model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import T from 'modules/i18n';
import csvTemplate from '../../public/template.csv';

class Invitations extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: '0',
			values: {
				message: '',
				locale: props.context.locale,
				csv: null,
			}
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	toggle(tab) {
		if (this.state.activeTab !== tab)
			this.setState({activeTab: tab});
	}

	handleChange(event) {
		const { name, type, value, files } = event.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: type !== 'file' ? value : files[0],
			},
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const formData = new FormData();
		const { values } = this.state;
		Object.keys(values).forEach(key => {
			formData.append(key, values[key]);
		});
		this.props.context.handleValidation(formData);
	}

	render() {
		const { values } = this.state;
		if (!this.props.context.locales)
			return null;

		return (
			<div className="animated fadeIn">
				<Nav tabs>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='0' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('0'); }}
						>
							<T>invitations</T>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='1' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('1'); }}
						>
							<T>email</T>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='2' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('2'); }}
						>
							<T>instructions</T>
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={this.state.activeTab} className="scroller mx-2 mt-2">
					<TabPane tabId="0">
						<Form onSubmit={this.handleSubmit}>
							<FormGroup>
								<Label>
									<T>message</T>
									<Input type="textarea" name="message" value={values.message} onChange={this.handleChange} rows="5"/>
								</Label>
							</FormGroup>
							<FormGroup>
								<Label>
									<T>locale</T>
									<Input type="select" name="locale" value={values.locale} onChange={this.handleChange}>
										{ this.props.context.locales.map(locale => (
											<option key={`locale_option_${locale}`} value={locale}>{locale}</option>
										))}
									</Input>
								</Label>
							</FormGroup>
							<FormGroup>
								<Label>
									CSV
									<Input required type="file" accept=".csv" name="csv" onChange={this.handleChange}/>
								</Label>
							</FormGroup>
							<div className="text-center">
								<Button color="success"><T>validation</T></Button>
							</div>
						</Form>
					</TabPane>
					<TabPane tabId="1">
						<p className="text-justify"><T>you should have already created a template email named</T>{' '}<b>invitation</b>. <T>this template should contain the following shortcode</T>:</p>
						<p className="text-center font-weight-bold">/[[invitation_url]]</p>
						<p className="text-justify"><T>which would be replaced automatically with the url which will be created for each invitation</T>.</p>
						<p className="text-justify"><T>additionally, you could include the following shortcodes</T>:</p>
						<dl>
							<dt>[[firstname]]</dt>
							<dd className="font-italic"><T>firstname</T></dd>
							<dt>[[lastname]]</dt>
							<dd className="font-italic"><T>lastname</T></dd>
							<dt>[[custom_message]]</dt>
							<dd className="font-italic"><T>the optional message that you enter during the sending of invitations</T></dd>
						</dl>
						<Link to={buildPath(DynamicRoutes.Templates, ['invitation'])}>
							<Button outline color="primary">
								<T>press here to create the template</T>
							</Button>
						</Link>
					</TabPane>
					<TabPane tabId="2">
						<p className="text-justify">
							<T>The csv file should contain at least the following fields in first line</T>:
						</p>
						<ul>
							<li className="font-weight-bold">firstname</li>
							<li className="font-weight-bold">lastname</li>
							<li className="font-weight-bold">email</li>
						</ul>
						<p className="text-justify">
							<T>and in each following line the corresponding fields should be placed in the same column</T>.
						</p>
						<p className="text-justify">
							<T>Additionally, in order to enroll each user automatically, you could use the fields</T> <b>category_n,</b> <b>role_n</b> <T>where n is an incremental number</T>, <T>eg</T>
						</p>
						<ul>
							<li className="font-weight-bold">category_0</li>
							<li className="font-weight-bold">role_0</li>
							<li className="font-weight-bold">category_1</li>
							<li className="font-weight-bold">role_1</li>
							<li className="font-weight-bold">&#8942;</li>
						</ul>
						<p className="text-justify">
							<T>In the category column you should place the shortname of the category and role could have one of the values</T> <em>expert</em> <T>or</T> <em>normal</em>. <b><T>All other columns will be ignored</T></b>.
						</p>
						<div className="text-center">
							<a href={csvTemplate} download="template.csv"><T>download here an example</T></a>
						</div>
					</TabPane>
				</TabContent>
			</div>
		);
	}
}

export default Invitations;
