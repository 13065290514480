import { Api } from 'core/api';
import { checkCookie } from 'core/ducks/profile';
import { pushNotification } from 'core/ducks/notifications';


// Actions

const REQUESTING = 'api/browser/REQUESTING';
const FAILED = 'api/browser/FAILED';
const LS = 'api/browser/LS';


// Initial state

const initialState = {
	pending: true,
	sending: false,
	status: '',
	tree: {},
	response: {},
	message: {},
	errorMsg: ''
};


// Reducer

export default (state=initialState, action) => {
	switch (action.type) {
		case LS:
			return {
				...state,
				pending: action.pending,
				status: action.status,
				tree: action.tree,
			}

		case REQUESTING:
			return {
				...state,
				pending: action.pending,
				status: action.status,
				errorMsg: action.errorMsg
			};

		case FAILED:
			return {
				...state,
				sending: action.sending,
				status: action.status,
				errorMsg: action.errorMsg,
			}

		default:
			return state;
	}
}


// Action creators

const requesting = () => ({
	type: REQUESTING,
	pending: true,
	status: initialState.status,
	errorMsg: initialState.errorMsg,
});

const listFiles = (status, json) => ({
	type: LS,
	pending: false,
	status,
	tree: json,
});

const failed = (status, errorMsg) => ({
	type: FAILED,
	sending: false,
	status,
	errorMsg
});


// Thunk action creators

export const ls = (path) => (dispatch) => {
	let promise = new Promise((resolve, reject) => {
		dispatch( checkCookie() ).then(() => {
			dispatch(requesting());
			let a = new Api('?action=fileBrowser&dir=' + encodeURIComponent(path));
			let status = '';
			a.Get().then((response) => {
				status = response.status;
				return response.json();
			}).then((json) => {
				if (status === 200) {
					dispatch(listFiles(status, json));
					resolve(json);
				} else {
					dispatch(failed(status, json.Error));
					dispatch(pushNotification({body: 'action denied', type: 'warning'}));
					reject(status);
				}
			});
		});
	});

	return promise;
}
