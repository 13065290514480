import React, { Component } from 'react';
import { FormGroup, Label, Button, Input, Row, Col } from 'reactstrap';
import PolygonInput from 'input-fields/components/polygonInput';
import Marker from './marker';
import Color from './color';
import Transparency from './transparency';

class Geometry extends Component {

	constructor(props) {
		super(props);

		const emptyPoint = {
			type: 'MultiPoint',
			geojson: [],
			properties: {
				label: '',
				marker: '#ffffff'
			}
		};

		const emptyLine = {
			type: 'MultiLine',
			geojson: [],
			properties: {
				label: '',
				color: '#ffffff'
			}
		};

		const emptyPolygon = {
			type: 'MultiPolygon',
			geojson: [],
			properties: {
				label: '',
				color: '#ffffff',
				transparency: 1
			}
		};

		this.emptyElem = {
			point: emptyPoint,
			line: emptyLine,
			polygon: emptyPolygon,
		};

		this.state = {
			values: props.value !== '' ? props.value : [],
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleAddition = this.handleAddition.bind(this);
	}

	handleChange(event, index) {
		let { value, name } = event.target;
		let values;
		if (name === 'geojson') {
			values = [
				...this.state.values.slice(0, index),
				{
					...this.state.values[index],
					[name]: value,
				},
				...this.state.values.slice(index + 1),
			];
		} else {
			values = [
				...this.state.values.slice(0, index),
				{
					...this.state.values[index],
					properties: {
						...this.state.values[index].properties,
						[name]: value,
					}
				},
				...this.state.values.slice(index + 1),
			];
		}
		this.setState({
			values,
		});
		this.props.onChange({
			...event,
			target: {
				...event.target,
				name: this.props.name,
				value: values,
			}
		});
	}

	handleAddition(entity) {
		this.setState({
			values: [
				...this.state.values,
				this.emptyElem[entity],
			]
		});
	}

	render() {

		const { values } = this.state;

		return (
			<FormGroup>
				<Label>{this.props.attributes.label}</Label>
				{ Object.values(values).map((element, index) => (
					<fieldset key={`element_${index}`} className="p-2">
						<legend>{`Choice ${index+1}`}</legend>
						<Row>
							{ Object.keys(element.properties).map(key => {
								switch (key) {
									case 'label':
										return (
											<Col xs={4} key={`element_${index}_${key}`}>
												<FormGroup>
													<Label className="w-100">
														Label
														<Input
															name={key}
															onChange={(e) => {this.handleChange(e, index)}}
															value={element.properties[key]}
														/>
													</Label>
												</FormGroup>
											</Col>
										);

									case 'color':
										return (
											<Col xs={4} key={`element_${index}_${key}`}>
												<FormGroup>
													<Label className="w-100">
														Color
														<Color
															name={key}
															onChange={(e) => {this.handleChange(e, index)}}
															value={element.properties[key]}
															opacity={element.properties.transparency || 1}
														/>
													</Label>
												</FormGroup>
											</Col>
										);

									case 'transparency':
										return (
											<Col xs={4} key={`element_${index}_${key}`}>
												<FormGroup>
													<Label className="w-100">
														Transparency
														<Transparency
															name={key}
															onChange={(e) => {this.handleChange(e, index)}}
															value={element.properties[key]}
														/>
													</Label>
												</FormGroup>
											</Col>
										);

									case 'marker':
										return (
											<Col xs={4} key={`element_${index}_${key}`}>
												<FormGroup>
													<Label htmlFor="fileBrowser">Marker icon</Label>
													<Marker name={key} value={element.properties[key]} onChange={(e) => {this.handleChange(e, index)}} />
												</FormGroup>
											</Col>
										);

									default:
										return null;
								}
							})}
							<Col xs={12}>
								<FormGroup>
									<PolygonInput
										draw={{
											polygon: element.type==='MultiPolygon' ? {showArea: true, metric: ['km', 'm'], precision: {km: 2, m: 1}} : false,
											rectangle: false,
											marker: element.type==='MultiPoint',
											polyline: element.type==='MultiLine',
											circlemarker: false,
											circle: false
										}}
										center={[41.120164, 25.404698]}
										zoom={14}
										height={500}
										onChange={(e) => {this.handleChange(e, index)}}
										name="geojson"
										geojson={element.geojson}
										returnGeoJSON={true}
									/>
								</FormGroup>
							</Col>
						</Row>
					</fieldset>
				))}
				<Row>
					<Col>
						<Button color="success" className="mr-2" onClick={() => {this.handleAddition('point')}}>Add Point</Button>
						<Button color="success" className="mr-2" onClick={() => {this.handleAddition('line')}}>Add Line</Button>
						<Button color="success" className="mr-2" onClick={() => {this.handleAddition('polygon')}}>Add Polygon</Button>
					</Col>
				</Row>
			</FormGroup>
		);
	}
}

export default Geometry;
