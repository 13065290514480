import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Form, FormGroup, Input, Label, Col, Nav, NavItem, NavLink, TabContent, TabPane, Button,
	Modal, ModalBody, Progress, Spinner,
} from 'reactstrap';

import { uploadData, cancelUpload } from 'core/ducks/upload';
import { pushNotification } from 'core/ducks/notifications';
import { File } from 'input-fields';
import T from 'modules/i18n';

class LibraryImport extends Component {

	constructor(props) {
		super(props);
		this.state = {
			importType: 'local',
			file: {},
			activeTab: '0',
			values: {
				domain: '',
				api_client: '',
				api_secret: '',
			},
			showProgress: false,
			waiting: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.progress < 100 && this.props.progress === 100)
			this.setState({
				showProgress: false,
				submitted: true,
			});
	}

	toggle = (tab) => {
		if (this.state.activeTab !== tab)
			this.setState({activeTab: tab});
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const { importType, values } = this.state;
		const formData = new FormData();
		formData.append('_method', 'put');
		if (importType === 'local') {
			formData.append('archive', this.state.file);
			this.setState({showProgress: true, waiting: true}, () => {
				this.props.dispatch(uploadData('libraryExport/scope/restore-from-local-file', formData, false))
					.then(() => {
						this.setState({waiting: false});
						this.props.dispatch(pushNotification({body: "library restored", type: "success"}));
						this.props.context.fetchData();
					})
					.catch(httpStatus => {
						this.setState({waiting: false});
						this.props.dispatch(pushNotification({body: "library restore failed", type: "danger"}));
					});
			});
		} else {
			Object.keys(values).forEach(key => {
				formData.append(key, values[key]);
			});
			this.setState({showProgress: true, waiting: true}, () => {
				this.props.dispatch(uploadData('libraryExport/scope/restore-from-api', formData, false))
					.then(() => {
						this.setState({waiting: false});
						this.props.dispatch(pushNotification({body: "library restored", type: "success"}));
						this.props.context.fetchData();
					})
					.catch(httpStatus => {
						this.setState({waiting: false});
						this.props.dispatch(pushNotification({body: "library restore failed", type: "danger"}));
					});
			});
		}
	}

	hadleImportTypeChange = (event) => {
		const { value, checked } = event.target;
		if (checked && this.state.importType !== value)
			this.setState({
				importType: value,
			});
	}

	handleFileChange = (event) => {
		const { files } = event.target;
		this.setState({file: files[0]});
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: value,
			}
		});
	}

	render() {
		const { isOpen, toggle } = this.props;
		const { importType, values, showProgress, waiting } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<div className="animated fadeIn">
				<Nav tabs>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='0' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('0'); }}
						>
							<T>restore</T>
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={this.state.activeTab} className="scroller mx-2 mt-2">
					<TabPane tabId="0">
						<Form onSubmit={this.handleSubmit}>
							<FormGroup row>
								<Col>
									<FormGroup tag="fieldset">
										<FormGroup check>
											<Label check>
												<Input
													type="radio"
													name="importType"
													onChange={this.hadleImportTypeChange}
													value="local"
													checked={importType==='local'}
												/>
												<span className="font-weight-bold"><T>import from local file</T></span>
											</Label>
										</FormGroup>
										<FormGroup>
											<File
												accept=".tar.gz"
												id="library_file_input"
												placeholder={messages['choose file']}
												onChange={this.handleFileChange}
												buttonText={messages.browse}
												required
												readOnly={importType !== 'local'}
											/>
										</FormGroup>
									</FormGroup>
									<FormGroup tag="fieldset">
										<FormGroup check>
											<Label check>
												<Input
													type="radio"
													name="importType"
													onChange={this.hadleImportTypeChange}
													value="remote"
													checked={importType==='remote'}
												/>
												<span className="font-weight-bold"><T>import from another installation</T></span>
											</Label>
										</FormGroup>
											<FormGroup>
												<Label htmlFor="domain_input">Domain</Label>
												<Input
													id="domain_input"
													name="domain"
													value={values.domain}
													onChange={this.handleChange}
													required
													disabled={importType!=='remote'}
												/>
											</FormGroup>
											<FormGroup>
												<Label htmlFor="api_client_input">Api-Client</Label>
												<Input
													id="api_client_input"
													name="api_client"
													value={values.api_client}
													onChange={this.handleChange}
													required
													disabled={importType!=='remote'}
												/>
											</FormGroup>
											<FormGroup>
												<Label htmlFor="api_secret_input">Api-Secret</Label>
												<Input
													id="api_secret_input"
													type="textarea"
													rows={6}
													name="api_secret"
													value={values.api_secret}
													onChange={this.handleChange}
													required
													disabled={importType!=='remote'}
												/>
											</FormGroup>
									</FormGroup>
								</Col>
							</FormGroup>
							<div className="text-center">
								<Button color="warning"><T>import</T></Button>
							</div>
						</Form>
					</TabPane>
				</TabContent>
				<Modal isOpen={showProgress} className="modal-md">
					<ModalBody>
						<p><T>please wait while uploading</T>...</p>
						<Progress animated value={this.props.progress} />
						<div className="text-right p-1">
							<Button type="button" onClick={() => {
								cancelUpload('Operation canceled by the user.');
								this.setState({showProgress: false});
							}}>
								<T>cancel</T>
							</Button>
						</div>
					</ModalBody>
				</Modal>
				<Modal isOpen={!showProgress && waiting}>
					<ModalBody>
						<div className="text-center">
							<Spinner type="grow" color="warning" />
						</div>
						<div className="text-center">
							<T>please wait for the process to complete</T>
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	progress: state.upload.progress,
});

LibraryImport = connect(mapStateToProps)(LibraryImport);

export default LibraryImport;
