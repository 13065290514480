import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	UncontrolledDropdown as Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, FormGroup, Input
} from 'reactstrap';

import { setTarget, toggleEdit, editSettings } from '../ducks/project';

import '../style/targetSetting.scss';
import discourseImg from '../img/discourse.png';

class TargetSetting extends Component {

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	handleChange(event) {
		const { value, checked } = event.target;
		if (checked)
			this.props.dispatch( setTarget(value) );
	}

	handleClick(event) {
		const { name } = event.target;
		const { dispatch } = this.props;
		switch (name) {
			case 'edit':
				dispatch( editSettings(true) );
				break;

			case 'toggleEdit':
				dispatch( toggleEdit() );
				break;

			default:

		}
	}

	render() {

		const { target, isEditOn } = this.props;

		return (
			<Dropdown direction="left" className="target-setting d-inline-block">
				<DropdownToggle caret>
					<i className="fa fa-cog" />
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem header >Open menu items:</DropdownItem>
					<FormGroup check >
						<Label check >
							<Input type="radio" name="settings" value="modal" checked={target==='modal'} onChange={this.handleChange} />{' '}
							in modals.
						</Label>
					</FormGroup>
					<FormGroup check >
						<Label check >
							<Input type="radio" name="settings" value="tab" checked={target==='tab'} onChange={this.handleChange} />{' '}
							in new tabs.
						</Label>
					</FormGroup>
					<FormGroup check >
						<Label check >
							<Input type="radio" name="settings" value="same" checked={target==='same'} onChange={this.handleChange} />{' '}
							in the same tab.
						</Label>
					</FormGroup>
					<DropdownItem divider/>
					<DropdownItem name="edit" onClick={this.handleClick}><i className="fa fa-cog"/>{' '}Edit settings</DropdownItem>
					<DropdownItem name="toggleEdit" onClick={this.handleClick}>
						<i className="fa fa-pencil"/>{' '}Turn editing {isEditOn ? 'off' : 'on'}
					</DropdownItem>
					<DropdownItem divider/>
					<DropdownItem name="createThread" onClick={this.props.onThreadCreation} disabled={!this.props.isNewThreadAllowed}>
						<i><img src={discourseImg} width="12" height="12" alt=""/></i>{' '}Create thread
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
}

const mapStateToProps = (state) => ({
	target: state.project.target,
	isEditOn: state.project.isEditOn,
});

TargetSetting = connect(mapStateToProps)(TargetSetting);

export default TargetSetting;
