import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import Droppable from './droppable';

class DragNdrop extends Component {

	render() {

		const { onDragEnd, droppableId, direction, getListStyle, isDropDisabled, ...otherProps } = this.props;

		return (
			<DragDropContext onDragEnd={onDragEnd} isDropDisabled={isDropDisabled}>
				<Droppable droppableId={droppableId} direction={direction} isDropDisabled={isDropDisabled} {...otherProps}>
					{this.props.children}
				</Droppable>
			</DragDropContext>
		);
	}
}

DragNdrop.propTypes = {
	onDragEnd: PropTypes.func.isRequired,
	droppableId: PropTypes.node.isRequired,
	direction: PropTypes.string,
	getListStyle: PropTypes.func,
	isDropDisabled: PropTypes.bool,
};

export default DragNdrop;
