import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Chart from 'react-apexcharts';
import PhotoGallery from 'react-photo-gallery';

import { ImageWithText } from 'library/components';
import { getData } from 'core/ducks/update';

class VariableStats extends Component {

	constructor(props) {
		super(props);
		this.state = {
			stats: {},
			ready: false,
		};
	}

	componentDidMount() {
		const { attribute, variable } = this.props;
		let url = `data/scope/ensemble/attribute/${attribute}/variable/${variable}`;
		if (this.props.filter)
			url += `/within/${this.props.filter}`;
		this.props.dispatch(
			getData(url)
		).then(stats => this.setState({stats, ready: true}))
		.catch(err => console.warn(err));
	}

	imageRenderer = ({index, left, top, key, photo}) => {
		return (
			<ImageWithText
				key={key}
				margin="2px"
				index={index}
				photo={photo}
				left={left}
				top={top}
				content={photo.content}
			/>
		);
	};

	render() {
		const { stats, ready } = this.state;
		if (!ready || [].concat(...Object.values(stats)).length === 0) return null;
		return (
			<div className={this.props.className ? `variable-stats ${this.props.className}` : 'variable-stats'}>
				<Row>
					{Object.keys(stats).filter(type => Object.keys(stats[type]).length > 0 && ['radio', 'checkbox'].includes(type)).map(type => (
						<Col key={`chart_${type}`}>
							<Chart
								series={Object.values(stats[type])}
								options={{labels: Object.keys(stats[type])}}
								type="pie"
								height="200px"
							/>
						</Col>
					))}
				</Row>
				{ stats.library_details &&
					<Row>
						<Col>
							<PhotoGallery
								photos={
									stats.library_details.map(elem => ({
										src: elem.resource,
										width: 2,
										height: 2,
										content: elem.percent + '%',
									}))
								}
								renderImage={this.imageRenderer}
							/>
						</Col>
					</Row>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

VariableStats = connect(mapStateToProps)(VariableStats);

export default VariableStats;
