import {StaticRoutes} from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'othercategories',
		url: StaticRoutes.OtherCategories,
		role: [roles.GUEST, roles.AUTHORIZED],
	},
	// {
	// 	name: 'categories',
	// 	url: StaticRoutes.Categories,
	// 	role: [roles.GUEST, roles.AUTHORIZED],
	// },
	{
		name: 'data',
		url: StaticRoutes.AdminDataStatic,
		role: roles.REVIEWER,
		icon: 'fa fa-table',
	},
];

export default menu;
