import * as reducers from './ducks';
import { default as routes } from './model/routes';
import { default as menuItems } from './menu';
import * as layout from './views/layout';
import * as sidebars from './views/sidebars';
import { default as Dashboard } from './views/dashboard';

const dashboardItems = [Dashboard];

export default { reducers, sidebars, ...routes, menuItems, layout, dashboardItems };
