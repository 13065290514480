import React, { Component } from 'react';
import { Button, Collapse, CustomInput } from 'reactstrap';

import { getBaseUrl } from 'core/model/lib/urlTools';
import { RelativeDocuments, SocialIntegration, ForumIntegration } from '../../components';
import { getContrast } from '../../model/lib';
import T from 'modules/i18n';

import '../../style/sidebar.scss';

class Questionnaire extends Component {

	constructor(props) {
		super(props);
		this.state = {
			collapse: null,
		};
	}

	toggleTopicPanel = (e, token) => {
		const { topics } = this.props.context;
		const collapse = this.state.collapse === token ? null : token;
		const activeTopic = collapse ? topics.find(topic => topic.token === token) : null;
		this.setState({collapse});
		const newContext = {activeTopic};
		if (!activeTopic)
			newContext.activeAttribute = null;
		this.props.context.set(newContext);
	}

	handleVisibilitySwitch = (event) => {
		const { checked, name } = event.target;
		const { context } = this.props;
		const index = context.attributesToHide.indexOf(name);
		const attributesToHide = checked ? [
			...context.attributesToHide.slice(0, index),
			...context.attributesToHide.slice(index + 1),
		] : [
			...context.attributesToHide,
			name,
		];
		context.set({attributesToHide});
	}

	render() {

		const { messages } = this.props.i18n || {messages: {}};
		const { category, project, topics, attributes, ready, visibleLayers, attributesToHide } = this.props.context;
		if (ready !== 0)
			return null;

		return (
				<div className="text-center aside-menu-in-full-content py-2 px-4">
					<h4>{category.title || ''}</h4>
					<SocialIntegration className="position-absolute" style={{top: '1.5em', right: '2em'}} url={getBaseUrl() + this.props.location.pathname} messages={messages}/>
					<p>{category.summary || ''}</p>
					<h5>{project.title || ''}</h5>
					{ project.legislation && project.legislation.length === 1 &&
						<a
							href={project.legislation[0].file}
							target="_blank"
							rel="noopener noreferrer"
							className="d-block my-1 py-1 w-100 text-warning border-warning border-top border-bottom"
						>
							{project.legislation[0].title}
						</a>
					}
					<p className="text-justify">{project.summary || ''}</p>
					<div className="text-right">
						<ForumIntegration scope="project" topicId={project.forum_topic_id} topicUrl={project.forum_topic_url} messages={messages}/>
					</div>
					{ project.legislation && project.legislation.length > 1 &&
						<>
							<Button outline color="info" size="sm" data-tip data-for={`relative_documents_${project.token}`}>
								<T>relative_documents</T>
							</Button>
							<RelativeDocuments
								title={messages.relative_documents || 'Relative documents'}
								token={project.token}
								documents={project.legislation}
							/>
						</>
					}
					<hr/>
					{topics.map((topic) => (
						<div key={`topics_${topic.token}`}>
							<Button
								color="primary"
								id={`topics_${topic.token}`}
								className="attribute-button d-inline-block"
								onClick={(e) => this.toggleTopicPanel(e, topic.token)}
							>
								{topic.title}
							</Button>
							<Collapse isOpen={this.state.collapse === topic.token}>
								{ topic.relative_documents &&
									<div className="text-right mr-5 pr-5" data-tip data-for={`relative_documents_${topic.token}`}>
										<i role="link" className="animated fadeIn fa fa-files-o ml-2 text-primary"/>
										<span className="text-primary"><T>relative_documents</T></span>
										<RelativeDocuments
											title={messages.relative_documents || 'Relative documents'}
											token={topic.token}
											documents={topic.relative_documents}
										/>
									</div>
								}
								<div>
									<p>{topic.description}</p>
									<div className="text-right">
										<ForumIntegration scope="topic" topicId={topic.forum_topic_id} topicUrl={topic.forum_topic_url} messages={messages}/>
									</div>
									{attributes.filter(attr => attr.node === topic.token && attr.is_visible).map(attr => (
										<div key={`attribute_${attr.token}`} className="d-flex">
											<Button
												className="topic-button"
												onClick={(e) => this.props.context.set({activeAttribute: attr})}
												style={{
													background: attr.geom_type === 'polygon' || attr.geom_type === 'line' ? attr.geom_color : '',
													color: attr.geom_type === 'polygon' || attr.geom_type === 'line' ? getContrast(attr.geom_color) : 'black'
												}}
											>
												{attr.name || ''}
												{attr.icon && <img src={attr.icon} alt={attr.icon_alt} className="image"/>}
											</Button>
											{ (attr.has_geometry || topic.has_geometry) &&
												<CustomInput
													type="switch"
													id={`switch_${attr.token}`}
													name={attr.token}
													checked={!attributesToHide.includes(attr.token)}
													onChange={this.handleVisibilitySwitch}
												/>
											}

										</div>
									))}
								</div>
							</Collapse>
							<hr/>
						</div>
					))}
				</div>
		);
	}
}

export default Questionnaire;
