import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import Social from './social';

class PopupContent extends Component {

	render() {

		const { title, messages, isDeleteEnabled, onDetailsClick, onDeleteClick, isOwner, choice, hasSocial } = this.props;

		return (
			<>
				<h5>{title}</h5>
				<hr/>
				<div className="text-center">
					<Button className="mr-2" color="success" size="sm" onClick={onDetailsClick}>
						{messages.details || 'Details'}
					</Button>
					{ isOwner &&
						<Button
							color="danger"
							size="sm"
							disabled={!isDeleteEnabled}
							onClick={onDeleteClick}
						>
							{messages.delete || 'Delete'}
						</Button>
					}

				</div>
				<hr/>
				{ hasSocial &&
					<Social token={choice} isOwner={isOwner} scope="choice" />
				}
			</>
		);
	}
}

PopupContent.propTypes = {
	title: PropTypes.string.isRequired,
	messages: PropTypes.object.isRequired,
	isDeleteEnabled: PropTypes.bool.isRequired,
	onDetailsClick: PropTypes.func.isRequired,
	onDeleteClick: PropTypes.func.isRequired,
	choice: PropTypes.string.isRequired,
	hasSocial: PropTypes.bool.isRequired,
	isOwner: PropTypes.bool.isRequired,
};

export default PopupContent;
