import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { showMenu } from 'react-contextmenu/modules/actions';
import uniqid from 'uniqid';

import { Title, PseudoLink, ContextMenuTrigger } from '../components';
import { Handle } from '../components/dnd';

class Attribute extends Component {

	constructor(props) {
		super(props);
		this.contextMenuId = uniqid();
	}

	render() {

		const { title, children, handleContextMenuClick, isEditOn, icon, icon_alt, isVisible } = this.props;

		return (
			<Row className="mb-2">
				<Col className="py-0">
					<ContextMenuTrigger isEditOn={isEditOn} id={this.contextMenuId}>
						{ isEditOn && <span style={{marginLeft: '-1rem', marginRight: '1rem'}}><Handle/></span> }
						<span className={isVisible ? '' : 'opacity-50'}>
							{ (icon && icon !== '/data/') &&
								<span className="icon-box border-0">
									<img
										style={{width: '100%', maxWidth: '100%', height: 'auto', maxHeight: '100%'}}
										src={icon}
										alt={icon_alt || 'icon'}
									/>
								</span>
							}{' '}
							<Title color="#20a8d8">{title}</Title>
						</span>
						{ isEditOn &&
							<PseudoLink
								id="context_menu"
								icon="ellipsis-v"
								onClick={(e) => {
									showMenu({
										position: {x: e.clientX, y: e.clientY},
										id: this.contextMenuId,
										target: e.target,
									})
								}}
							/>
						}
					</ContextMenuTrigger>
					<div className={isVisible ? 'ml-5' : 'ml-5 opacity-50'}>
						{children}
					</div>
					{ isEditOn &&
						<ContextMenu id={this.contextMenuId}>
							<MenuItem onClick={() => {handleContextMenuClick('Variable')}}><i className="fa fa-plus"/>{' '}Add variable</MenuItem>
							<MenuItem onClick={() => {handleContextMenuClick('Attribute')}}><i className="fa fa-pencil"/>{' '}Edit</MenuItem>
							<MenuItem onClick={() => {handleContextMenuClick('toggleAttributeVisibility')}}>
								<i className={isVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}/>
								{isVisible ? ' Hide' : ' Show'}
							</MenuItem>
							<MenuItem onClick={() => {handleContextMenuClick('deleteAttribute')}}><i className="fa fa-trash-o"/>{' '}Delete</MenuItem>
						</ContextMenu>
					}
				</Col>
			</Row>
		);
	}
}

Attribute.propTypes = {
	title: PropTypes.node.isRequired,
	handleContextMenuClick: PropTypes.func.isRequired,
	isEditOn: PropTypes.bool.isRequired,
	icon: PropTypes.node,
	icon_alt: PropTypes.string,
	isVisible: PropTypes.bool.isRequired,
};

export default Attribute;
