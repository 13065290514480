import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Button, Row, Col } from 'reactstrap';
import PhotoGallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';

import { requestData } from 'core/ducks/list';
import { getData } from 'core/ducks/update';
import { Loading } from 'core/components';
import { TagInput, SelectedImage } from '../../components';
import { selectPhoto, slideShow } from '../../ducks/library';

import T from 'modules/i18n';

function columns(containerWidth) {
	let columns = 1;
	if (containerWidth >= 500) columns = 2;
	if (containerWidth >= 900) columns = 3;
	if (containerWidth >= 1500) columns = 4;
	return columns;
}

class Gallery extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tags: '',
			photos: [],
			pending: true,
			currentImage: 0,
			viewerIsOpen: false,
			selected: props.items || [],
		};

		this.fetchData = this.fetchData.bind(this);
		this.processData = this.processData.bind(this);
		this.handleTaglistChange = this.handleTaglistChange.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.tags !== this.state.tags)
			this.fetchData();

		if (!prevProps.library.isSlideShowOpen && this.props.library.isSlideShowOpen)
			this.openLightbox({}, { index: this.props.library.activePhoto });
	}

	fetchData(page=null) {
		let url = ['library'];
		// url.push('limit/20');
		if (page) url.push(`page/${page}`);
		if (this.state.tags !== '') url.push(`taglist/${this.state.tags}`)
		this.props.dispatch(
			requestData(url[0], url.join('/'))
		).then(() => this.processData(page))
		.catch(err => console.warn(err));
	}

	processData(page) {
		const { list } = this.props;
		const photos = list.library.data.values.map(elem => ({
			src: elem.resource,
			width: Math.floor(Math.random() * 3) + 2,
			height: 2,
			uuid: elem.uuid,
		}));
		if (!page) {
			this.setState({
				photos,
			}, () => this.setState({pending: false}));
		} else {
			this.setState({
				photos: [...this.state.photos, ...photos],
			});
		}
	}

	handleSelect(event, { photo, index }) {
		if (this.props.isModal) {
			const { selected } = this.state;
			const index = selected.indexOf(photo.uuid);
			if (index === -1) {
				this.setState({
					selected: [...selected, photo.uuid],
					photos: [...this.state.photos],
				});
			} else {
				this.setState({
					selected: [...selected.slice(0, index), ...selected.slice(index + 1)],
					photos: [...this.state.photos],
				});
			}
		} else {
			this.props.dispatch(selectPhoto(photo.uuid, index));
		}
	}

	handleTaglistChange({target}) {
		const { value } = target;
		this.setState({
			tags: value,
		});
	}

	getTagList = (query) => {
		const promise = new Promise((resolve, reject) => {
			if (query === '') {
				resolve([]);
				return;
			}
			const { tags } = this.state;
			const url = tags !== '' ? `library/autocomplete/tags/query/${query}/tags/${tags}` : `library/autocomplete/tags/query/${query}`;
			this.props.dispatch(
				getData(url)
			).then(response => {
				const suggestions = response.map(name => ({id: name, name}));
				resolve(suggestions);
			}).catch(err => {
				reject(err);
				console.warn(err);
			});
		});

		return promise;
	}

	openLightbox = (event, { index } = { index: 0}) => {
		this.setState({
			currentImage: index,
			viewerIsOpen: true,
		});
	};

	closeLightbox = () => {
		this.setState({
			currentImage: 0,
			viewerIsOpen: false,
		});
		this.props.dispatch(slideShow(false));
	};

	imageRenderer = ({index, left, top, key, photo}) => {
		return (
			<SelectedImage
				selected={this.state.selected.includes(photo.uuid)}
				key={key}
				margin="2px"
				index={index}
				photo={photo}
				left={left}
				top={top}
				onClick={this.handleSelect}
			/>
		);
	};

	render() {
		if (this.state.pending)
			return (<Loading/>);

		const { photos, currentImage, viewerIsOpen } = this.state;
		const { page, total_pages } = this.props.list.library.data;
		const { messages } = this.props.i18n || {messages: {}};
		const { isModal } = this.props;
		const galleryAttributes = isModal ? {renderImage: this.imageRenderer} : {direction: 'column', columns: (w) => columns(w) };

		return (
			<div className="ppcity-admin library animated fadeIn">
				<Card>
					<CardHeader className="py-2 px-3">
						<Row>
							<Col xs="10" className="py-0">
								<TagInput
									placeholder={messages['select tag'] || 'Select tag'}
									handleInputChange={this.getTagList}
									onChange={this.handleTaglistChange}
									allowNew={false}
								/>
							</Col>
							<Col xs="2" className="py-0 text-right">
								{ isModal ?
									<Button color="success" className="mr-2" onClick={() => this.props.getValues(this.state.selected)}>
										<T>accept</T>
									</Button>
									:
									<Button color="success" className="mr-2" onClick={this.openLightbox}>Slide Show</Button>
								}
							</Col>
						</Row>
					</CardHeader>
					<CardBody>
						{ photos.length > 0 &&
							<>
								<PhotoGallery photos={photos} onClick={this.handleSelect} {...galleryAttributes} />
								<ModalGateway>
									{ viewerIsOpen &&
										<Modal onClose={this.closeLightbox}>
											<Carousel
												currentIndex={currentImage}
												views={photos.map(x => ({
													...x,
													srcset: x.srcSet,
													caption: x.title
												}))}
											/>
										</Modal>
									}
								</ModalGateway>
								{ page < total_pages &&
									<div>
										<Button className="d-block mx-auto" color="primary" onClick={() => this.fetchData(page + 1)}><T>load more</T></Button>
									</div>
								}
							</>
						}
					</CardBody>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	list: state.list,
	library: state.library,
	i18n: state.i18n
});

Gallery = connect(mapStateToProps)(Gallery);

export default Gallery;
