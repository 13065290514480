import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format, parseISO } from 'date-fns';
import { FormGroup, Label } from 'reactstrap';
import { el } from 'date-fns/locale';

import "react-datepicker/dist/react-datepicker.css";
registerLocale('el', el);

class DateInput extends Component {

	constructor(props) {
		super(props);
		let value;
		if (props.value && props.value !== '') {
			value = parseISO(props.value);
		} else {
			value = '';
			this.props.onChange({target: {name: props.name, value}});
		}
		this.state = {
			value,
		}

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value)
			this.setState({
				value: parseISO(this.props.value),
			});
	}

	handleChange = (value) => {
		this.setState({value});
		this.props.onChange({target: {name: this.props.name, value: format(value, 'yyyy-MM-dd')}});
	}

	render() {

		const { attributes, ...otherProps } = this.props;

		return (
			<FormGroup>
				<Label>
					{attributes.label}:{' '}
					<DatePicker
						{...otherProps}
						selected={this.state.value}
						onChange={this.handleChange}
						allowSameDay={true}
						showTimeSelect={false}
						dateFormat="d MMMM yyyy"
						className="form-control"
					/>
				</Label>
			</FormGroup>
		);
	}
}

export default DateInput;
