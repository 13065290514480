import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardText, Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { getData, postData } from 'core/ducks/update';
import { ErrorPage } from 'core/views/pages';
import { Loading } from 'core/components';
import { pushNotification } from 'core/ducks/notifications';
import { fetchAppProfile } from '../../ducks/categoriesprofile';
import { StaticRoutes } from '../../model/routes';
import { ProjectCard } from '../../components';
import T from 'modules/i18n';

import '../../style/general.scss';

class Category extends Component {

	constructor(props) {
		super(props);
		this.state = {
			httpStatus: 200,
			details: {},
			ready: false,
			isModalOpen: false,
		};

		this.handleAutoSubscribe = this.handleAutoSubscribe.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
	}

	componentDidMount() {
		const category = this.props.match.params.category;
		if (!category)
			this.props.history.push(StaticRoutes.OtherCategories);
		this.props.dispatch(
			getData(`category/mname/${category}`)
		).then(
			details => {
				const httpStatus = details.mname ? 200 : 404;
				this.setState({details, httpStatus, ready: true});
			}
		).catch(httpStatus => this.setState({httpStatus, ready: true}));
	}

	handleAutoSubscribe() {
		this.props.dispatch(
			postData('enrolment', {category: this.state.details.token}, false)
		).then((e) => {
			this.props.dispatch(fetchAppProfile(true))
				.then(() => this.setState({isModalOpen: false}));
		}).catch(e => {
			this.props.dispatch(pushNotification({
				body: "autosubscribe error",
				type: "warning"
			}))
		});
	}

	toggleModal() {
		this.setState({
			isModalOpen: false,
			chosenCategory: null,
		});
	}

	render() {

		const { messages } = this.props.i18n || {messages: {}};
		const { details, ready } = this.state;
		const { enrolled } = this.props.categoriesProfile;

		if (this.state.httpStatus !== 200)
			return (<ErrorPage status={this.state.httpStatus}/>);

		if (!ready)
			return <Loading/>

		return (
			<Card>
				<CardHeader>
					{details.title}
				</CardHeader>
				<CardBody>
					{ (details.autosubscribe && !enrolled.map(elem => elem.token).includes(details.token)) &&
						<Row>
							<Col>
								<Button className="float-right mx-3" onClick={() => this.setState({isModalOpen: true})}><T>enroll</T></Button>
							</Col>
						</Row>
					}
					<Row>
						<Col>
							<img width="100%" className="py-2 px-5" src={details.summary_img} alt={details.summary_img_alt}/>
						</Col>
						<Col>
							<CardText className="p-2">{details.summary}</CardText>
						</Col>
					</Row>
					{ enrolled.map(elem => elem.token).includes(details.token) &&
						<>
							<Row>
								<Col>
									<CardText className="text-warning text-center"><T>click on the following to participate</T></CardText>
								</Col>
							</Row>
							<Row className="ppcity">
								{ enrolled.find(elem => elem.token === details.token).projects.map(project => (
									<Col sm="4" key={`project_card_${project.token}`} className="card-group">
										<ProjectCard
											categoryToken={details.token}
											categoryMname={details.mname}
											projectToken={project.token}
											projectMname={project.mname}
											projectTitle={project.title}
											projectSummary={project.summary}
											icon={project.summary_img}
											icon_alt={project.summary_img_alt}
											role={this.props.role}
										/>
									</Col>
								))}
							</Row>
						</>
					}
				</CardBody>
				<Modal isOpen={this.state.isModalOpen} className="modal-md">
					<ModalHeader toggle={this.toggleModal}><T>do autosubscribe</T></ModalHeader>
					<ModalBody>
						<T>do you wish to subscribe</T>
					</ModalBody>
					<ModalFooter>
						<Button color="success" onClick={this.handleAutoSubscribe}>
							<T>yes</T>
						</Button>
						<Button color="danger" onClick={this.toggleModal}>
							<T>no</T>
						</Button>
					</ModalFooter>
				</Modal>
			</Card>
		);
	}

}

const mapStateToProps = (state) => ({
	profile: state.profile,
	categoriesProfile: state.categoriesprofile,
	role: state.profile.user.role,
});

Category = connect(mapStateToProps)(Category);

export default Category;
