import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Card, CardBody, CardHeader, Form, FormGroup, FormFeedback, Row, Col, Label, Button,
	Modal, ModalBody, Progress
} from 'reactstrap';

import { getValidation, validate, getContent, clearMessages } from 'core/ducks/forms';
import { getData } from 'core/ducks/update';
import { ErrorPage } from 'core/views/pages';
import { buildPath } from 'core/model/lib/urlTools';
import { UploadImage } from 'core/views/modals';
import { uploadData, cancelUpload } from 'core/ducks/upload';
import { Input, Loading } from 'core/components';
import { TagInput } from '../../components';
import { selectPhoto, setGlobalTags } from '../../ducks/library';
import { DynamicRoutes } from '../../model/routes';

import T from 'modules/i18n';

class Upload extends Component {

	constructor(props) {
		super(props);
		this.emptyValues = {
			title: '',
			tags: '',
			tooltip: '',
			description: '',
			documentation: '',
			resource: '',
			alt_text: '',
		};
		this.initialValues = {...this.emptyValues};
		this.state = {
			values: {...this.initialValues},
			file: {},
			isUploadModalOpen: false,
			previewUrl: null,
			showProgress: false,
			submitted: false,
			item: props.match.params.item,
			method: 'POST',
			pending: true,
			httpStatus: 200,
		};

		this.initialize = this.initialize.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
		this.getModalValues = this.getModalValues.bind(this);
	}

	componentDidMount() {
		this.props.dispatch( getValidation('library') );
		this.initialize();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.item !== this.props.match.params.item)
			this.setState({pending: true, item: this.props.match.params.item}, this.initialize);

		if (prevProps.progress < 100 && this.props.progress === 100)
			this.setState({
				showProgress: false,
				submitted: true,
			});
	}

	initialize() {
		if (this.state.item) {
			this.props.dispatch(
				selectPhoto(this.state.item)
			).then(details => {
				this.initialValues = {...details};
				this.initialValues.tags = JSON.stringify(this.initialValues.tags);
				this.setState({
					values: {...this.initialValues},
					pending: false,
					method: 'PUT',
					httpStatus: this.props.httpStatus,
				});
				this.props.dispatch(setGlobalTags(this.initialValues.tags));
			}).catch(httpStatus => this.setState({httpStatus}));
		} else {
			this.initialValues = {...this.emptyValues};
			this.setState({
				values: {...this.initialValues},
				pending: false,
				method: 'POST',
				httpStatus: 200,
			});
			this.props.dispatch(setGlobalTags(this.initialValues.tags));
		}
	}

	handleChange(event) {
		const { name, value } = event.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: value,
			},
		});
		if (name === 'tags')
			this.props.dispatch(setGlobalTags(value));
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		dispatch(validate(this.state.values, rules, 'library', this.initialValues)).then(() => {
			if (this.props.valid)
				this.handleUpload();
		});
	}

	handleUpload() {
		const { method } = this.state;
		if (method === 'POST') {
			this.handlePOST();
		} else {
			this.handlePUT();
		}
	}

	handlePOST = () => {
		const formData = new FormData();
		const { values } = this.state;
		Object.keys(values).forEach(key => {
			if (key !== 'resource')
				formData.append(key, values[key]);
		});
		formData.append('resource', this.state.file);
		this.uploadData(formData, 'library');
	}

	handlePUT = () => {
		const formData = new FormData();
		formData.append('_method', 'PUT');
		const { values } = this.state;
		let changed = false;
		Object.keys(values).forEach(key => {
			if (key === 'resource')
				return;
			if (values[key] !== this.initialValues[key]) {
				formData.append(key, values[key]);
				changed = true;
			}
		});
		if (this.state.file.name) {
			formData.append('resource', this.state.file);
			changed = true;
		}
		if (changed)
			this.uploadData(formData, `library/uuid/${this.state.item}`);
	}

	uploadData = (formData, url) => {
		this.setState({showProgress: true}, () => {
			this.props.dispatch( uploadData(url, formData) )
				.then(response => {
					this.setState({file: []});
					if (this.state.method === 'PUT') {
						this.initialValues = { ...this.state.values };
					} else {
						this.props.history.push(buildPath(DynamicRoutes.Upload, [response]));
					}
				})
				.catch(error => {
					this.setState({showProgress: false});
					console.warn(error);
				});
		});
	}

	getModalValues(values) {
		const { file, alt, files } = values;
		this.setState({
			values: {
				...this.state.values,
				resource: file,
				alt_text: alt,
			},
			file: files[0],
			previewUrl: files[0] ? URL.createObjectURL(files[0]) : null,
		});
	}

	getTagList = (query) => {
		const promise = new Promise((resolve, reject) => {
			if (query === '') {
				resolve([]);
				return;
			}
			this.props.dispatch(
				getData(`library/autocomplete/tags/query/${query}`)
			).then(response => {
				const suggestions = response.map(name => ({id: name, name}));
				resolve(suggestions);
			}).catch(err => {
				reject(err);
				console.warn(err);
			});
		});

		return promise;
	}

	render() {

		const { values, isUploadModalOpen, previewUrl, httpStatus } = this.state;
		const { messages } = this.props.i18n || {messages: {}};
		const { validationMsg, rules } = this.props;

		if (httpStatus !== 200)
			return (<ErrorPage status={httpStatus}/>);
		if (this.props.validationPending || this.props.validationScope !== 'library' || this.state.pending)
			return (<Loading />);

		return (
			<>
				<Form className="ppcity-admin library animated fadeIn" onSubmit={this.handleSubmit}>
					<Button color="success" className="float-right m-2"><T>submit</T></Button>
					<div className="clearfix"/>
					<Card>
						<CardHeader className="py-2 px-3">
							<FormGroup row className="m-0">
								<Col className="p-0">
									<FormGroup>
										<Input
											style={{fontSize: '140%', fontWeight: 'bold', backgroundColor: '#f0f3f5'}}
											value={values.title}
											onChange={this.handleChange}
											name="title"
											placeholder={messages.title || 'Title'}
											pattern={rules.title.validation}
											valid={validationMsg.title === ''}
										/>
										<FormFeedback><T>{validationMsg.title || rules.title.message}</T></FormFeedback>
									</FormGroup>
								</Col>
							</FormGroup>
						</CardHeader>
						<CardBody>
							<FormGroup row>
								<Col sm="8" className="py-0 border" style={{minHeight: '200px'}} onClick={() => this.setState({isUploadModalOpen: true})}>
									{ (this.state.previewUrl || values.resource) ?
										<img
											style={{width: '100%', maxWidth: '100%', height: 'auto'}}
											src={this.state.previewUrl || values.resource}
											alt="Uploaded"
										/>
										:
										<div className="text-center" style={{margin: 0, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
											<T>Click to browse an image.</T>
											<FormGroup>
												<Input className="d-none" valid={validationMsg.resource === ''} value={values.resource}/>
												<FormFeedback><T>{validationMsg.resource || rules.resource.message}</T></FormFeedback>
											</FormGroup>
										</div>
									}
								</Col>
								<Col sm="4" className="py-0">
									<FormGroup className="px-2">
										<Label htmlFor="taginput" className="w-100 m-0">
											<T>tag</T>
										</Label>
										<TagInput
											id="taginput"
											placeholder={messages['select a tag or create new'] || 'Select at least one tag or create a new'}
											name="tags"
											defaultValue={values.tags}
											onChange={this.handleChange}
											handleInputChange={this.getTagList}
										/>
										<Input className="d-none" valid={validationMsg.tags === ''} value={values.tags}/>
										<FormFeedback><T>{validationMsg.tags || rules.tags.message}</T></FormFeedback>
									</FormGroup>
									<FormGroup>
										<Label className="w-100 px-2">
											<T>description</T>
											<Input
												type="textarea"
												name="description"
												value={values.description}
												onChange={this.handleChange}
												rows="5"
												pattern={rules.description.validation}
												valid={validationMsg.description === ''}
											/>
											<FormFeedback><T>{validationMsg.description || rules.description.message}</T></FormFeedback>
										</Label>
									</FormGroup>
									<FormGroup>
										<Label className="w-100 px-2">
											<T>tooltip</T>
											<Input
												name="tooltip"
												value={values.tooltip}
												onChange={this.handleChange}
												pattern={rules.tooltip.validation}
												valid={validationMsg.tooltip === ''}
											/>
											<FormFeedback><T>{validationMsg.tooltip || rules.tooltip.message}</T></FormFeedback>
										</Label>
									</FormGroup>
									<FormGroup>
										<Label className="w-100 px-2">
											<T>documentation</T>
											<Input
												name="documentation"
												value={values.documentation}
												onChange={this.handleChange}
												placeholder="http(s)://..."
												pattern={rules.documentation.validation}
												valid={validationMsg.documentation === ''}
											/>
											<FormFeedback><T>{validationMsg.documentation || rules.documentation.message}</T></FormFeedback>
										</Label>
									</FormGroup>
								</Col>
							</FormGroup>
						</CardBody>
					</Card>
					{ this.state.showProgress &&
						<Modal isOpen={this.state.showProgress} className="modal-md">
							<ModalBody>
								<p><T>Please wait while uploading</T>...</p>
								<Progress animated value={this.props.progress} />
								<div className="text-right p-1">
									<Button type="button" onClick={() => {
										cancelUpload('Operation canceled by the user.');
										this.setState({showProgress: false});
									}}>
										<T>cancel</T>
									</Button>
								</div>
							</ModalBody>
						</Modal>
					}
				</Form>
				<UploadImage
					getValues={this.getModalValues}
					isModalOpen={isUploadModalOpen}
					toggle={() => this.setState({isUploadModalOpen: false})}
					values={{file: values.resource, alt: values.alt_text}}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	rules: state.forms.validation.rules,
	validationPending: state.forms.validation.pending,
	validationScope: state.forms.validation.scope,
	validationMsg: state.forms.validation_msgs,
	valid: state.forms.valid,
	progress: state.upload.progress,
	httpStatus: state.library.httpStatus,
});

Upload = connect(mapStateToProps)(Upload);

export default Upload;
