import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Droppable as ReactDroppable } from 'react-beautiful-dnd';

class Droppable extends Component {

	getListStyle = (isDraggingOver) => {
		if (this.props.getListStyle)
			return this.props.getListStyle(isDraggingOver);
		return {
			background: isDraggingOver ? 'rgba(173,216,230,0.2)' : 'inherit',
			minHeight: 100 + 'px'
		}
	};

	render() {

		const { droppableId, direction, isDropDisabled, type, disabled, ...otherProps } = this.props;

		if (disabled)
			return this.props.children;

		return (
			<ReactDroppable droppableId={droppableId} direction={direction} isDropDisabled={isDropDisabled} type={type}>
				{(provided, snapshot) => (
					<div
						ref={provided.innerRef}
						style={this.getListStyle(snapshot.isDraggingOver)}
						{...provided.droppableProps}
						{...otherProps}
					>
						{this.props.children}
						{provided.placeholder}
					</div>
				)}
			</ReactDroppable>
		);
	}
}

Droppable.propTypes = {
	droppableId: PropTypes.node.isRequired,
	direction: PropTypes.string,
	getListStyle: PropTypes.func,
	isDropDisabled: PropTypes.bool,
	type: PropTypes.string,
	disabled: PropTypes.bool,
};

export default Droppable;
