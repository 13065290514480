import React, { Component } from 'react';
import Control from 'react-leaflet-control';
import { Container, Row, Col, Button as ReactstrapButton } from 'reactstrap';
import PropTypes from 'prop-types';

import layersImg from 'leaflet-controls/img/layers.png';
import { Table, Tr, Td } from 'css-table';
import 'leaflet-controls/style/layersControl.scss';

const Button = (props) => {
	return <i className="border bg-white p-2 rounded fa fa-users" role="button" style={{height: 32, width: 32}} onClick={props.handleClick} />;
}

class ProfileControl extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showMenu: false,
			layers: props.profile.reduce((obj, question) => ({
				...obj,
				[question.token]: 0
			}), {}),
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleCheckChange = this.handleCheckChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleClick() {
		this.setState({
			showMenu: !this.state.showMenu
		});
	}

	handleCheckChange(event) {
		const { name, value, checked } = event.target;
		this.setState({
			layers: {
				...this.state.layers,
				[name]: checked ? parseInt(value): 0,
			}
		});
	}

	handleSubmit() {
		const { layers } = this.state;
		let value = Object.keys(layers).filter(token => layers[token] !== 0).map(token => `${token}:${layers[token]}`);
		value = value.join(';');
		this.props.onSubmit(value);
		this.setState({showMenu: false});
	}

	render() {
		const { position, profile } = this.props;
		const { layers } = this.state;

		return (
			<Control className="leaflet-controls layers-control" position={position} >
				{ position.substring(0,3) === 'top' && <Button fontImg={this.props.fontImg} handleClick={this.handleClick}/> }
				{ this.state.showMenu &&
					<Container fluid={true} className="border border-success rounded-bottom py-2 pl-2 pr-3" style={{backgroundColor: 'rgba(200,200,200,0.8)'}}>
						<Table className="m-0">
							{ profile.map(question => (
								<Tr key={`question_${question.token}`}>
									{question.choices.map(choice => (
										<Td key={`choice_${question.token}_${choice.id}`} className="pb-0">
											<label className="input-container active">
												<img src={choice.icon} width="40px" alt={choice.content} title={choice.content}/>
												<input
													type="checkbox"
													checked={layers[question.token] === choice.id}
													onChange={this.handleCheckChange}
													value={choice.id}
													name={question.token}
												/>
												<span
													className="checkmark radio"
													style={layers[question.token] === choice.id ? {backgroundColor: 'blue'} : {}}
												/>
											</label>
										</Td>
									))}
								</Tr>
							))}
						</Table>
						<Row>
							<Col className="text-right p-0">
								<ReactstrapButton className="mr-2" color="success" outline onClick={this.handleSubmit}>Render</ReactstrapButton>
							</Col>
						</Row>
					</Container>
				}
				{ position.substring(0,6) === 'bottom' && <Button fontImg={this.props.fontImg} handleClick={this.handleClick}/> }
			</Control>
		);
	}
}

ProfileControl.propTypes = {
	position: PropTypes.oneOf(['topleft', 'topright', 'bottomleft', 'bottomright']),
	onSubmit: PropTypes.func.isRequired,
	profile: PropTypes.array.isRequired,
};

ProfileControl.defaultProps = {
	position: 'topleft',
};

export default ProfileControl;
