import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'file browser',
		url: StaticRoutes.Browse,
		icon: 'fa fa-folder-open',
		role: roles.ADMIN
	},
];

export default menu;
