import React, { Component } from 'react';
import { ButtonGroup, Button } from 'reactstrap';

import discourseImg from '../img/discourse-logo.jpg';

class DiscourseButton extends Component {

	constructor(props) {
		super(props);
		this.fullUrlRef = React.createRef();
	}

	handleCopy = (url) => {
		const elem = this.fullUrlRef.current;
		elem.select();
		elem.setSelectionRange(0, 99999);
		document.execCommand('copy');
	}

	render() {

		if (!this.props.url)
			return null;

		return (
			<ButtonGroup className={this.props.className}>
				<img src={discourseImg} height="30px" width="auto"/>
				<Button outline color="primary" size="sm" title="Follow">
					<a href={this.props.url} target="_blank" rel="noopener noreferrer"><i className="fa fa-external-link"/></a>
				</Button>
				<Button outline color="primary" size="sm" title="Copy" onClick={this.handleCopy}><i className="fa fa-clipboard"/></Button>
				<input ref={this.fullUrlRef} style={{position: 'absolute', left: '-99999px'}} defaultValue={this.props.url}/>
			</ButtonGroup>
		);
	}
}

export default DiscourseButton;
