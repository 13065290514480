import React, { Component } from 'react';
import { Input, FormGroup, Label } from 'reactstrap';

class Bool extends Component {

	render() {

		const { attributes, onChange, name, value } = this.props;

		return (
			<FormGroup check className="float-right">
				<Label check >
					<Input type="checkbox" checked={value} onChange={onChange} name={name}/>
					{attributes.label}
				</Label>
			</FormGroup>
		);
	}
}

export default Bool;
