import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { kml } from '@tmcw/togeojson';

const DropKMLContext = React.createContext();

export const DropKMLContextConsumer = DropKMLContext.Consumer;

class DropKML extends Component {

	constructor(props) {
		super(props);
		this.state = {
			kml: null,
		};
	}

	onDropAccepted = (accepted) => {
		if (this.props.disabled)
			return;
		accepted.forEach(file => {
			if (file.type !== 'application/vnd.google-earth.kml+xml')
				return;
			const reader = new FileReader();
			reader.onload = () => {
				const content = reader.result;
				const parser = new DOMParser();
				const xmlContent = parser.parseFromString(content,"text/xml");
				this.setState({
					kml: kml(xmlContent),
				});
			};
			reader.readAsText(file);
		});
	};

	render() {

		return (
			<Dropzone onDrop={this.onDropAccepted}>
				{({getRootProps}) => (
					<DropKMLContext.Provider value={{kml: this.state.kml}}>
						<div {...getRootProps()}>
							{this.props.children}
						</div>
					</DropKMLContext.Provider>
				)}
			</Dropzone>
		);
	}
}

export default DropKML;
