import { Api } from 'core/api';
import { checkCookie } from 'core/ducks/profile';
import * as roles from 'core/model/roles';

// Actions
const REQUEST_PROFILE = 'api/app_profile/REQUEST';
const SET_PROFILE = 'app_profile/SET';
const FAILED_REQUEST_PROFILE = 'api/app_profile/FAILED';
const SAME_PROFILE = 'app_profile/SAME'

// Reducer
const initialState = {
	pending: false,
	status: 200,
	errMsg: '',
	enrolled: [],
	requested: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_PROFILE:
			return {
				...state,
				pending: action.pending,
				status: action.status,
				errMsg: action.errMsg,
			};

		case SET_PROFILE:
		case FAILED_REQUEST_PROFILE:
			return {
				...state,
				pending: action.pending,
				status: action.status,
				errMsg: action.errMsg,
				enrolled: action.enrolled,
				requested: action.requested,
			};

		case SAME_PROFILE:
			return {
				...state,
				pending: action.pending,
			};

		default:
			return state;
	}
}

// Action Creators

const profileRequest = () => ({
	type: REQUEST_PROFILE,
	pending: true,
	status: 200,
	errMsg: '',
});

const profileSet = (enrolled) => ({
	type: SET_PROFILE,
	pending: false,
	status: 200,
	errMsg: '',
	enrolled,
	requested: true,
});

const profileRequestFailed = (status, errMsg) => ({
	type: FAILED_REQUEST_PROFILE,
	pending: false,
	status,
	errMsg,
	categories: [],
	requested: false,
});

const sameProfile = () => ({
	type: SAME_PROFILE,
	pending: false,
});

export const fetchAppProfile = (force=false, activeCategory=null) => (dispatch, getState) => {
	let promise = new Promise((resolve, reject) => {
		dispatch( checkCookie() ).then(() => {
			dispatch(profileRequest());
			if (!force && getState().categoriesprofile.requested) {
				dispatch(sameProfile());
				resolve(getState().categoriesprofile);
			} else {
				let url = activeCategory ? `data/scope/profile/activeCategory/${activeCategory}` : 'data/scope/profile';
				let a = new Api(url);
				let status = '';
				a.Get().then((response) => {
					status = response.status;
					return response.json();
				}).then((json) => {
					if (status === 200) {
						dispatch(profileSet(json));
						resolve(json);
					} else {
						dispatch(profileRequestFailed(status, json.Error));
						reject(status);
					}
				});
			}
		});
	});

	return promise;
}
