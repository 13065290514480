import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Card, CardBody, CardGroup, CardHeader, CardText, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import { ErrorPage } from 'core/views/pages';
import { Loading } from 'core/components';
import * as roles from 'core/model/roles';
import { buildPath } from 'core/model/lib/urlTools';
import { ProjectCard } from '../../components';
import { fetchAppProfile } from '../../ducks/categoriesprofile';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';
import T from 'modules/i18n';
import '../../style/general.scss';

class Categories extends Component {

	constructor(props) {
		super(props);
		this.state = {
			countdown: 20,
		};
		this.timerId = null;
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.role === roles.AUTHORIZED && prevProps.pending && !this.props.pending && this.props.status === 200 && Object.keys(this.props.enrolled).length === 0 )
			this.timerId = setInterval(() => this.setState({countdown: this.state.countdown - 1}), 1000);

		if (prevState.countdown === 1 && this.state.countdown === 0) {
			clearInterval(this.timerId)
			if (this.props.history)
				this.props.history.push(StaticRoutes.OtherCategories);
		}

		if (prevProps.activeCategory !== this.props.activeCategory)
			this.fetchData();
	}

	componentWillUnmount() {
		if (this.timerId)
			clearInterval(this.timerId);
	}

	fetchData = () => {
		if (this.props.role !== roles.AUTHORIZED) {
			if (this.props.activeCategory && (!this.props.match.params.category || this.props.match.params !== this.props.activeCategory)) {
				this.props.history.replace(buildPath(DynamicRoutes.AdminData, [this.props.activeCategory]));
				this.props.dispatch(fetchAppProfile(true, this.props.activeCategory));
			} else if (this.props.match.params.category) {
				this.props.dispatch(fetchAppProfile(true, this.props.match.params.category));
			} else {
				this.props.dispatch(fetchAppProfile(true));
			}
		} else {
			this.props.dispatch(fetchAppProfile());
		}
	}

	render() {

		const { pending, status, enrolled } = this.props;
		const scope = this.props.scope || 'normal';

		if (pending)
			return (<Loading/>);

		if (status !== 200)
			return (<ErrorPage status={status} />);

		return (
			<div className="ppcity">
				{(Object.keys(enrolled).length === 0 && scope !== "aside") &&
					<Card className="mx-auto" style={{maxWidth: '600px'}}>
						<CardBody>
							<CardText>
								<T>there are not active projects in which you participate</T>.{' '}
								<T>you will be redirected automatically in the page with the active projects in</T>{` ${this.state.countdown} `}s.
							</CardText>
							<CardText>
								<T>alternativelly</T> <T>click</T>{' '}
								<Link to={StaticRoutes.OtherCategories}><T>here</T></Link>.
							</CardText>
						</CardBody>
					</Card>
				}
				{Object.values(enrolled).filter(category => category.projects.length > 0).map((category, index) => (
					<Card key={`category_card_${category.token}`}>
						<CardHeader>{category.title}</CardHeader>
						<CardBody>
							{ scope === 'normal' &&
								<CardText>{category.summary}</CardText>
							}
							<CardGroup>
								<Row>
									{category.projects.map((e) => (
										<ProjectCard
											key={`project_card_${e.token}`}
											categoryToken={category.token}
											categoryMname={category.mname}
											projectToken={e.token}
											projectMname={e.mname}
											projectTitle={scope === 'normal' ? e.title : ''}
											projectSummary={scope === 'normal' ? e.summary : ''}
											icon={e.summary_img}
											icon_alt={e.summary_img_alt}
											role={this.props.role}
										/>
									))}
								</Row>
							</CardGroup>
						</CardBody>
					</Card>
				))}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	role: state.profile.user.role,
	pending: state.categoriesprofile.pending,
	status: state.categoriesprofile.status,
	enrolled: state.categoriesprofile.enrolled,
	activeCategory: state.project.activeCategoryName,
});

Categories = connect(mapStateToProps)(Categories);

export default Categories;
