import L from 'leaflet';
import dragHandle from 'leaflet-path-drag';

const Edit = L.EditToolbar.Edit.extend({
	_enableLayerEdit: function (e) {
		var layer = e.layer || e.target || e,
			pathOptions, poly;

		// Back up this layer (if haven't before)
		this._backupLayer(layer);

		if (this.options.poly) {
			poly = L.Util.extend({}, this.options.poly);
			layer.options.poly = poly;
		}

		// Set different style for editing mode
		if (this.options.selectedPathOptions) {
			pathOptions = L.Util.extend({}, this.options.selectedPathOptions);

			// Use the existing color of the layer
			if (pathOptions.maintainColor) {
				pathOptions.color = layer.options.color;
				pathOptions.fillColor = layer.options.fillColor;
			}

			layer.options.original = L.extend({}, layer.options);
			layer.options.editing = pathOptions;

		}

		if (layer.editing) {
			if (this.options.selectedPathOptions.editing) {
				layer.editing.enable();
			} else {
				layer.editing.disable();
			}
		}
		if (layer.dragging) {
			if (this.options.selectedPathOptions.dragging) {
				layer.dragging.enable();
			} else {
				layer.dragging.disable();
			}
		}
		if (layer instanceof L.Marker)
			layer
				.on('dragend', this._onMarkerDragEnd)
				// #TODO: remove when leaflet finally fixes their draggable so it's touch friendly again.
				.on('touchmove', this._onTouchMove, this)
				.on('MSPointerMove', this._onTouchMove, this)
				.on('touchend', this._onMarkerDragEnd, this)
				.on('MSPointerUp', this._onMarkerDragEnd, this);
	}
});

L.EditToolbar.Edit = Edit;