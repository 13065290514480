import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Form, FormGroup, FormFeedback, Button, Row, Col, Label, FormText, Card, CardBody
} from 'reactstrap';

import { Input, Loading } from 'core/components';
import { getValidation, validate, getContent, clearMessages } from 'core/ducks/forms';
import { characterConverter } from 'core/model/lib';
import { toggleModal } from 'core/ducks/ui/modal';
import { buildPath } from 'core/model/lib/urlTools';
import { ErrorPage } from 'core/views/pages';
import { UploadImage } from 'core/views/modals';
import { uploadData } from 'core/ducks/upload';
import { DynamicRoutes } from 'core/model/routes';
import { inputTypes, geomTypes, inputOptions } from '../../model/lib';
import { InputForOptions } from '../../components';
import T from 'modules/i18n';
import { Gallery } from 'library/views/layout';
import { Element } from 'library';

class Variable extends Component {

	constructor(props) {
		super(props);

		this.initialValues = {
			mname: '',
			icon: null,
			description: '',
			tooltip: '',
		};

		this.state = {
			values: {
				...this.initialValues,
			},
			variableType: [],
			options: {},
			shortname: '',
			files: [],
			previewUrl: null,
			underSubmit: false,
			showProgress: false,
			submitted: false,
			variable: props.match.params.variable ? props.match.params.variable : null,
			makeActive: false,
			httpStatus: 200,
		};

		this.actions = bindActionCreators({toggleModal}, props.dispatch);

		this.fetchContent = this.fetchContent.bind(this);
		this.uploadData = this.uploadData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleTypeChange = this.handleTypeChange.bind(this);
		this.handleOptionsChange = this.handleOptionsChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleShortnameChange = this.handleShortnameChange.bind(this);
		this.handleAddIcon = this.handleAddIcon.bind(this);
		this.getModalValues = this.getModalValues.bind(this);
		this.handleOptionsOrderChange = this.handleOptionsOrderChange.bind(this);
	}

	componentDidMount() {
		if (this.state.variable)
			this.fetchContent(this.state.variable);
		this.props.dispatch( getValidation('variable') );
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.variable !== this.props.match.params.variable) {
			const { variable } = this.props.match.params;
			this.setState({
				variable,
			});
			this.fetchContent(variable);
		}

		if ( !prevState.underSubmit && this.state.underSubmit === 'post')
			this.uploadData('post');

		if ( !prevState.underSubmit && this.state.underSubmit === 'put')
			this.uploadData('put');

		if (!prevState.submitted && this.state.submitted)
			this.setState({
				submitted: false,
			});

		if (prevProps.progress < 100 && this.props.progress === 100)
			this.setState({
				showProgress: false,
				submitted: true,
			});
	}

	fetchContent(variable) {
		this.props.dispatch( getContent(`variable/node/${this.props.match.params.topic}/token/${variable}`, 'variable') )
		.then(status => {
			this.initialValues = Object.keys(this.initialValues)
				.reduce((obj, key) => ({
					...obj,
					[key]: this.props.content.variable[key]
				}), {});

			this.setState({
				values: {...this.initialValues},
				shortname: this.initialValues.mname,
				variableType: {...this.props.content.variable.variable_type},
				options: {...this.props.content.variable.variable_type},
			});
		})
		.catch(status => {
			this.setState({httpStatus: status});
		});
	}

	uploadData(method) {
		const { params } = this.props.match;
		let formData = new FormData();
		const { values, variableType, options } = this.state;
		let variable_type = Object.keys(variableType)
			.filter(key => variableType[key])
			.map(kind => ({
				kind,
				options: options[kind],
			}));
		if (method === 'post')
			formData.append('attribute', params.attribute);
		Object.keys(values).forEach(key => {
			if (key !== 'icon') {
				if (method === 'put' && this.initialValues[key] !== values[key]) {
					formData.append(key, values[key]);
				} else {
					if (values[key] !== '')
						formData.append(key, values[key]);
				}
			}
		});
		if (this.state.files[0]) {
			formData.append('icon', this.state.files[0]);
		} else if (!this.state.values.icon) {
			formData.append('icon', null);
		}

		formData.append('variable_type', JSON.stringify(variable_type));

		this.setState({showProgress: true}, () => {
			if (method === 'put')
				formData.append('_method', 'put');
			const url = method === 'put' ? `variable/token/${this.state.variable}` : 'variable';
			this.props.dispatch( uploadData(url, formData) )
				.then(response => {
					this.initialValues = {
						...this.state.values,
					};
					this.setState({underSubmit: false});
					if (typeof this.props.toggle === 'function')
						this.props.toggle();
					if (method === 'post')
						this.props.history.push(buildPath(DynamicRoutes.VariableEdit, [params.project, params.topic, params.attribute, response.token]));
				})
				.catch(error => {
					console.warn(error);
				});
		});
	}


	handleChange(event) {
		let { name, type, value, checked } = event.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: (type === 'checkbox' || type === 'switch') ? checked : value
			},
			underSubmit: false
		});
	}

	handleShortnameChange(event) {
		const { value } = event.target;
		const { messages, dispatch } = this.props;
		if (messages.mname !== '')
			dispatch( clearMessages('mname') );
		this.setState({
			shortname: value,
			values: {
				...this.state.values,
				mname: characterConverter(value),
			}
		});
	}

	handleTypeChange(event) {
		let { name, checked } = event.target;
		const options = checked ?
			Object.keys(inputOptions)
				.filter(key => inputOptions[key].scope.indexOf(name) !== -1 || inputOptions[key].scope[0] === '*')
				.reduce((obj, key) => ({
					...obj,
					[key]: inputOptions[key].defaultValue,
				}), {})
			:
			{};
		this.setState({
			variableType: {
				...this.state.variableType,
				[name]: checked
			},
			options: {
				...this.state.options,
				[name]: {...options}
			}
		});
	}

	handleOptionsChange(event, variableType) {
		let {name, value, checked, type } = event.target;
		this.setState({
			options: {
				...this.state.options,
				[variableType]: {
					...this.state.options[variableType],
					[name]: (type === 'checkbox' || type === 'switch') ? checked : value,
				},
			}
		});
	}

	handleOptionsOrderChange(type, order) {
		const { variableType } = this.state;
		let supplement = 0;
		let found = false;
		let entry;
		const keys = Object.keys(variableType);
		const newVariableType = keys.map((key, index) => {
			if (!found && (key === type || index === order)) {
				supplement -= 1;
				found = true;
				entry = key === type ? keys[index + 1] : type;
			} else {
				entry = keys[index + supplement];
				supplement = 0;
			}
			return entry;
		}).reduce((obj, key) => ({
			...obj,
			[key]: variableType[key]
		}), {});
		this.setState({variableType: {...newVariableType}});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		const method = this.state.variable ? 'put' : 'post';
		dispatch(validate(this.state.values, rules, 'variable', this.initialValues)).then(() => {
			if (this.props.valid)
				this.setState({underSubmit: method});
		});
	}

	resetForm() {
		this.setState({
			values: {...this.initialValues},
			shortname: this.initialValues.mname,
			files: [],
			previewUrl: null,
		});
	}

	handleAddIcon() {
		this.actions.toggleModal(true,
			<UploadImage
				getValues={this.getModalValues}
				toggle={() => {this.actions.toggleModal()}}
				hasAlt={false}
				values={{file: this.state.values.icon}}
			/>
		);
	}

	getModalValues(values) {
		const { file, alt, files } = values;
		this.setState({
			values: {
				...this.state.values,
				icon: file,
			},
			files,
			previewUrl: files[0] ? URL.createObjectURL(files[0]) : null,
		});
	}

	render() {

		const { values, httpStatus, variableType, options } = this.state;
		const { rules } = this.props;
		const validationMsg = this.props.messages;
		const { messages } = this.props.i18n || {messages: {}};

		if (this.props.validationPending || this.props.validationScope !== 'variable')
			return (<Loading />);

		if (httpStatus !== 200)
			return (<ErrorPage status={httpStatus} />);

		return (
			<Card className="ppcity-admin">
				<CardBody>
					<Form onSubmit={this.handleSubmit}>
						<Row>
							<Col>
								<FormGroup row>
									<Col className="py-1" sm="3" lg="2" style={{margin: '-1.2rem 0 0 0'}}>
										<FormGroup>
											<Label
												style={{opacity: this.state.shortname==='' ? 0 : 1, fontSize: "75%"}}
												className="animated fadeIn fadeOut my-0"
											>
												Short name
											</Label>
											<Input
												style={{fontSize: 75+'%'}}
												onChange={this.handleShortnameChange}
												value={this.state.shortname}
												placeholder="Short name"
												className="d-inline-block"
												name="shortname"
												autoComplete="off"
												minLength={rules.mname.min_size}
												maxLength={rules.mname.max_size}
												valid={validationMsg.mname === ''}
											/>
											<FormFeedback><T>{validationMsg.mname || rules.mname.message}</T></FormFeedback>
										</FormGroup>
									</Col>
									<Col className="mx-1" sm="1" style={{padding: 0, margin: 'auto', textAlign: 'center'}}>
										<span className="icon-box" title="Click to add an icon." onClick={this.handleAddIcon}>
											{ (this.state.previewUrl || values.icon) ?
												<img
													style={{width: '100%', maxWidth: '100%', height: 'auto'}}
													src={this.state.previewUrl || values.icon}
													alt="icon"
												/>
												:
												<span className="text">icon</span>
											}
										</span>
									</Col>
									<Col className="px-0 mx-0">
										{ this.initialValues.icon &&
											<i className="fa fa-times" role="button" onClick={() => this.setState({values: {...values, icon: null}})}/>
										}
									</Col>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col className="py-0">
								<FormGroup>
									<Label
										style={{opacity: values.tooltip==='' ? 0 : 1, fontSize: "85%"}}
										className="animated fadeIn fadeOut my-0"
									>
										Tooltip
									</Label>
									<Input
										placeholder="Tooltip"
										name="tooltip"
										value={values.tooltip}
										onChange={this.handleChange}
										pattern={rules.tooltip.validation}
										valid={validationMsg.tooltip === ''}
									/>
									<FormFeedback><T>{validationMsg.tooltip || rules.tooltip.message}</T></FormFeedback>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col className="py-0">
								<FormGroup>
									<Label
										style={{opacity: values.description==='' ? 0 : 1, fontSize: "85%"}}
										className="animated fadeIn fadeOut my-0"
									>
										Description
									</Label>
									<Input
										type="textarea"
										placeholder="Description"
										name="description"
										value={values.description}
										onChange={this.handleChange}
										rows={4}
										pattern={rules.description.validation}
										valid={validationMsg.description === ''}
									/>
									<FormFeedback><T>{validationMsg.description || rules.description.message}</T></FormFeedback>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col className="py-0">
								<fieldset className="pb-2">
									<legend>Select type</legend>
									<Row className="m-1">
										{ Object.keys(inputTypes).map(type => (
											<Col key={`input_type_${type}`} xs="3" md="2" className="py-0">
												<FormGroup check >
													<Label check >
														<Input
															type="checkbox"
															value={variableType[type] || false}
															checked={variableType[type] || false}
															name={type}
															onChange={this.handleTypeChange}
														/>{' '}
														{type}
													</Label>
												</FormGroup>
											</Col>
										))}
									</Row>
									<Row>
										<FormText className="py-1 px-2">Geometric variables</FormText>
									</Row>
									<Row>
										{ Object.keys(geomTypes).map(type => (
											<Col key={`geom_type_${type}`} xs="3" md="2" className="py-0">
												<FormGroup check >
													<Label check >
														<Input
															type="checkbox"
															value={variableType[type] || false}
															checked={variableType[type] || false}
															name={type}
															onChange={this.handleTypeChange}
														/>{' '}
														{type}
													</Label>
												</FormGroup>
											</Col>
										))}
									</Row>
								</fieldset>
							</Col>
						</Row>
						{ Object.keys(variableType).filter(type => variableType[type] || false).map((type, sortOrder) => (
							<Row key={`options_for_${type}`} className="pl-5">
								<Col>
									<fieldset className="px-3 py-2">
									<legend>
										<span className="mr-5">
											Options for {type}
										</span>
										<span>
											{ sortOrder !== 0 &&
												<i
													className="fa fa-long-arrow-up mx-0 px-0"
													role="button"
													onClick={() => this.handleOptionsOrderChange(type, sortOrder - 1)}
													title={messages['move up'] || 'Move up'}
												/>
											}
											{ sortOrder !== Object.keys(variableType).length - 1 &&
												<i
													className="fa fa-long-arrow-down mx-0 px-0"
													role="button"
													onClick={() => this.handleOptionsOrderChange(type, sortOrder + 1)}
													title={messages['move down'] || 'Move down'}
												/>
											}
										</span>
									</legend>
										{ Object.keys(inputOptions)
											.filter(key => inputOptions[key].scope.indexOf(type) !== -1 || inputOptions[key].scope[0] === '*')
											.map(key => (
												<InputForOptions
													key={`input_${inputOptions[key].label}`}
													attributes={inputOptions[key]}
													value={options[type] ? (options[type][key] || '') : ''}
													name={key}
													components={{Gallery, T, Element}}
													onChange={(e) => {this.handleOptionsChange(e, type)}}
												/>
											))
										}
									</fieldset>
								</Col>
							</Row>
						))}
						<Row>
							<Col className="text-right">
								<Button className="mr-2" type="submit" color="primary">Add</Button>
								{ typeof this.props.toggle === 'function' &&
									<Button type="button" color="warning" onClick={this.props.toggle}>Cancel</Button>
								}
							</Col>
						</Row>
					</Form>
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({
	rules: state.forms.validation.rules,
	validationPending: state.forms.validation.pending,
	contentPending: state.forms.pending,
	content: state.forms.content,
	validationScope: state.forms.validation.scope,
	valid: state.forms.valid,
	messages: state.forms.validation_msgs,
	progress: state.upload.progress,
	i18n: state.i18n
});

Variable = connect(mapStateToProps)(Variable);

export default Variable;
