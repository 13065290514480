import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getData, postData, deleteData } from 'core/ducks/update';
import '../style/social.scss';

class Social extends Component {

	constructor(props) {
		super(props);
		this.state = {
			social: null,
			disableMouseOut: false,
		};

		this.handleReaction = this.handleReaction.bind(this);
	}

	componentDidMount() {
		const { dispatch, token, scope, data } = this.props;
		if (!data) {
			this.props.dispatch(
				getData(`social/${scope}/${this.props.token}`)
			).then((social) => this.setState({social}))
			.catch(err => console.warn(err));
		} else {
			this.setState({social: data});
		}
	};

	handleReaction(event, reaction) {
		const { target } = event;
		const { token, isOwner, scope } = this.props;
		if (isOwner) return;
		if (this.state.social.reaction === reaction) {
			this.props.dispatch(
				deleteData(`social/${scope}/${token}`, false)
			).then(() => {
				this.toggleClass({target});
				this.setState({
					social: {
						...this.state.social,
						[reaction + 's']: this.state.social[reaction + 's'] - 1,
						reaction: false,
					},
					disableMouseOut: true,
				});
			});
		} else {
			this.props.dispatch(
				postData(`social/scope/${scope}`, {token, reaction}, false)
			).then(() => {
				this.toggleClass({target});
				this.setState({
					social: {
						...this.state.social,
						[reaction + 's']: this.state.social[reaction + 's'] + 1,
						reaction,
					},
					disableMouseOut: true,
				});
			});
		}
	}

	toggleClass = (e) => {
		if (this.state.disableMouseOut) {
			this.setState({disableMouseOut: false});
			if (e.type === 'mouseout') return;
		}
		const { className } = e.target;
		if (e.target.classList.contains('fa-thumbs-up') || e.target.classList.contains('fa-thumbs-down')) {
			e.target.className = className.replace(/fa-thumbs-(up|down)/g, "fa-thumbs-o-$1");
		} else {
			e.target.className = className.replace(/fa-thumbs-o-(up|down)/g, "fa-thumbs-$1");
		}
	}

	render() {

		const { social } = this.state;
		const { isOwner } = this.props;
		let likeClassName, dislikeClassName;
		if (social) {
			likeClassName = social.reaction === 'like' ? 'fa-thumbs-up' : 'fa-thumbs-o-up';
			dislikeClassName = social.reaction === 'dislike' ? 'fa-thumbs-down' : 'fa-thumbs-o-down';
		}
		if (!social) return null;

		return (
			<div className="social py-0">
				<span className="mr-2 text-success" style={{fontSize: '140%'}}>
					<i
						role="reaction"
						className={`fa ${likeClassName} ${isOwner || social.reaction === 'dislike' ? ' disabled' : ''}`}
						onMouseOver={this.toggleClass}
						onMouseOut={this.toggleClass}
						onClick={(e) => this.handleReaction(e, 'like')}
					/>
					<span>{social.likes}</span>
				</span>
				<span className="mr-2 text-danger" style={{fontSize: '140%'}}>
					<i
						role="reaction"
						className={`fa ${dislikeClassName} ${isOwner || social.reaction === 'like' ? ' disabled' : ''}`}
						onMouseOver={this.toggleClass}
						onMouseOut={this.toggleClass}
						onClick={(e) => this.handleReaction(e, 'dislike')}
					/>
					<span>{social.dislikes}</span>
				</span>
			</div>
		);
	}
}

Social.propTypes = {
	token: PropTypes.string.isRequired,
	scope: PropTypes.string.isRequired,
	data: PropTypes.object,
	isOwner: PropTypes.bool.isRequired,
};

Social = connect(null)(Social);

export default Social;
