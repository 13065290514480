import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody, CardText } from 'reactstrap';

import { getData } from 'core/ducks/update';
import { Loading } from 'core/components';
import { Element } from '../../components';

class LibraryView extends Component {

	constructor(props) {
		super(props);
		let re = /:[^\/]+/;
		let route = this.props.match.path.replace(re, '');
		re = `${route}`;
		this.params = this.props.location.pathname.replace(re, '');

		this.state = {
			content: {},
			items: [],
			pending: true,
		};
	}

	componentDidMount () {
		this.props.dispatch(
			getData(`?action=libraryView&uri=${encodeURIComponent(this.params)}`)
		).then(content => {
			this.setState({content});
			this.props.dispatch(
				getData(`library/uuid/${JSON.parse(content.items).join(';')}`)
			).then(items => this.setState({items, pending: false}));
		}).catch(err => console.warn(err));
	}

	render() {

		const { pending, content } = this.state;

		if (pending)
			return (<Loading/>);

		const items = Array.isArray(this.state.items) ? this.state.items : [this.state.items];

		return (
			<Card className="library animated fadeIn">
				<CardHeader>{content.title}</CardHeader>
				<CardBody>
					<CardText>{content.description}</CardText>
				</CardBody>
				<CardBody>
					{ items.map((elem, index) => (
						<Element key={`element_${elem.uuid}`} {...elem} index={index}/>
					))}
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({});

LibraryView = connect(mapStateToProps)(LibraryView);

export default LibraryView;
