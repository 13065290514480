import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Tooltip from 'react-tooltip';

import { buildPath } from 'core/model/lib/urlTools';
import { getData } from 'core/ducks/update';
import { setActiveCategory } from 'ppcity-admin/ducks/project';
import { DynamicRoutes } from '../../model/routes';

class AdminCategories extends Component {

	constructor(props) {
		super(props);
		this.state = {
			categories: [],
			ready: false,
		};
	}

	componentDidMount() {
		this.props.dispatch(getData('category'))
			.then(categories => this.setState({categories, ready: true}));
	}

	handleClick = (category) => {
		const url = buildPath(DynamicRoutes.AdminData, [category.mname]);
		this.props.dispatch(setActiveCategory(category.token, category.mname));
		this.props.history.push(url);
	}

	render() {
		const { categories, ready, activeCategory } = this.state;
		if (!ready) return null;

		return (
			<div>
				{ categories.map(category => (
					<Row key={`category_data_${category.mname}`} role="link">
						<Col
							data-tip
							data-for={`category_data_${category.mname}`}
							onClick={() => this.handleClick(category)}
						>
							<img src={category.summary_img} alt={category.summary_img_alt} width="100%" height="auto"/>
						</Col>
						<Tooltip id={`category_data_${category.mname}`} delayShow={200} delayUpdate={5000}>
							<h5>{category.title}</h5>
							<p className="text-justify mx-auto" style={{maxWidth: '300px', wordBreak: 'break-word'}}>{category.summary}</p>
						</Tooltip>
					</Row>
				))}
			</div>
		);
	}
}

AdminCategories = connect(null)(AdminCategories);

export default withRouter(AdminCategories);
