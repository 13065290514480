import React, { Component } from 'react';
import { Label } from 'reactstrap';
import uniqid from 'uniqid';

class Color extends Component {

	constructor(props) {
		super(props);
		this.inputRef = React.createRef();
		this.inputId = uniqid();

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		this.inputRef.current.click();
	}

	render() {

		const { attributes } = this.props;

		return (
			<>
				{ attributes &&
					<Label htmlFor={this.inputId}>{attributes.label}</Label>
				}
				<div
					className="form-control"
					style={{
						padding: '0.5rem 1rem',
					}}
					onClick={this.handleClick}
				>
					<div
						style={{
							width: '100%',
							height: '100%',
							backgroundColor: this.props.value,
							opacity: this.props.opacity,
						}}
					/>
				</div>
				<input
					id={this.inputId}
					className="d-none"
					type="color"
					name={this.props.name}
					onChange={this.props.onChange}
					value={this.props.value}
					ref={this.inputRef}
				/>
			</>
		);
	}
}

export default Color;
