import React from 'react';
import { ContextMenuTrigger as OriginalContextMenuTrigger } from 'react-contextmenu';
import PropTypes from 'prop-types';

const ContextMenuTrigger = (props) => {
	if (props.isEditOn) {
		return (
			<OriginalContextMenuTrigger id={props.id}>
				{props.children}
			</OriginalContextMenuTrigger>
		);
	} else {
		return (
			<>
				{props.children}
			</>
		);
	}
};

ContextMenuTrigger.propTypes = {
	isEditOn: PropTypes.bool.isRequired,
};

export default ContextMenuTrigger;
