import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Draggable as DraggableConsumer } from 'react-beautiful-dnd';

const DraggableContext = React.createContext();

export const DraggableContextConsumer = DraggableContext.Consumer;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	padding: 8 + 'px',
	margin: '4px 0',

	// styles we need to apply on draggables
	...draggableStyle,
});

class Draggable extends Component {

	render() {

		const { draggableId, index, isDragDisabled, type, disabled, ...otherProps } = this.props;

		if (disabled)
			return this.props.children;

		return (
			<DraggableConsumer
				draggableId={draggableId}
				index={index}
				isDragDisabled={isDragDisabled}
				type={type}
			>
				{(provided, snapshot) => (
					<DraggableContext.Provider value={provided}>
						<div
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...otherProps}
							style={getItemStyle(
								snapshot.isDragging,
								provided.draggableProps.style
							)}
						>
							{this.props.children}
						</div>
					</DraggableContext.Provider>
				)}
			</DraggableConsumer>
		);
	}
}

Draggable.propTypes = {
	draggableId: PropTypes.node.isRequired,
	index: PropTypes.number.isRequired,
	isDragDisabled: PropTypes.bool,
	type: PropTypes.string,
	disabled: PropTypes.bool,
};

export default Draggable;
