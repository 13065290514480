import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class Element extends Component {

	render() {

		const { tags, title, tooltip, description, resource, alt_text, documentation, index } = this.props;

		return (
			<Row className="border p-5 m-2">
				<Col>
					<div style={{fontSize: '150%', fontWeight: 'bold'}} className="text-center">{title}</div>
					<div className={index%2===0 ? 'float-left w-50' : 'float-right w-50 p-5'}>
						<img className="w-100" src={resource} alt={alt_text} title={tooltip}/>
					</div>
					<div className={index%2!==0 ? 'float-left w-50' : 'float-right w-50 p-5'}>
						<p className="text-justify">{description}</p>
						<a className="d-block mx-auto" href={documentation} target="_blank" rel="noopener noreferrer">{documentation}</a>
					</div>
					<div className="clearfix"/>
				</Col>
			</Row>
		);
	}
}

export default Element;
