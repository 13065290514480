import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import uniqid from 'uniqid';

import { inputTypes } from '../model/lib';
import { ContextMenuTrigger } from '../components';
import { Handle } from '../components/dnd';

const Icon = (props) => {
	if (typeof props.type === 'string') {
		if (!inputTypes[props.type])
			return '';
		return <i className={`fa fa-${inputTypes[props.type].icon}`} />;
	} else if (typeof props.type === 'object') {
		return (
			<>
				{ Object.values(props.type)
					.map(type => {
						if (inputTypes[type])
							return (<i key={uniqid()} className={`fa fa-${inputTypes[type].icon} pr-1`} title={type} />);
						return null;
					})
				}
			</>
		);
	}

	return null;
};

class Variable extends Component {

	constructor(props) {
		super(props);
		this.contextMenuId = uniqid();
	}

	render() {

		const { mname, type, handleContextMenuClick, isEditOn, isVisible } = this.props;

		return (
			<div>
				<ContextMenuTrigger isEditOn={isEditOn} id={this.contextMenuId}>
					<span style={{opacity: isVisible ? 1 : 0.3}}>
						{ isEditOn && <span style={{marginLeft: '-1rem', marginRight: '1rem'}}><Handle/></span> }
						<Icon type={type} />{' '}
						{mname}
					</span>
					{ isEditOn &&
						<div className="float-right">
							<i role="button" onClick={() => {handleContextMenuClick('Variable')}} className="fa fa-pencil" title="Edit"/>
							<i
								role="button"
								onClick={() => {handleContextMenuClick('toggleVariableVisibility')}}
								className={isVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}
								title={isVisible ? ' Hide' : ' Show'}
							/>
							<i role="button" onClick={() => {handleContextMenuClick('deleteVariable')}} className="fa fa-trash-o" title="Delete"/>
						</div>
					}
				</ContextMenuTrigger>
				{ isEditOn &&
					<ContextMenu id={this.contextMenuId}>
						<MenuItem onClick={() => {handleContextMenuClick('Variable')}}><i className="fa fa-external-link"/>{' '}Open link in new tab</MenuItem>
						<MenuItem onClick={() => {handleContextMenuClick('Variable')}}><i className="fa fa-pencil"/>{' '}Edit</MenuItem>
						<MenuItem onClick={() => {handleContextMenuClick('toggleVariableVisibility')}}>
							<i className={isVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}/>
							{isVisible ? ' Hide' : ' Show'}
						</MenuItem>
						<MenuItem onClick={() => {handleContextMenuClick('deleteVariable')}}><i className="fa fa-trash-o"/>{' '}Delete</MenuItem>
					</ContextMenu>
				}
			</div>
		);
	}
}

Variable.propTypes = {
	mname: PropTypes.string.isRequired,
	type: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array
	]).isRequired,
	handleContextMenuClick: PropTypes.func.isRequired,
	isEditOn: PropTypes.bool.isRequired,
	isVisible: PropTypes.bool.isRequired,
};

export default Variable;
