import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Row, Col, Button as ReactstrapButton, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { bindActionCreators } from 'redux';

import { requestData, getData, getInfo } from 'core/ducks/list';
import { getData as getAdditionalData, postData } from 'core/ducks/update';
import { requestProfile } from 'core/ducks/profile';
import { Loading } from 'core/components';
import { ErrorPage } from 'core/views/pages';
import View from 'users/views/modals/viewUser';
import { rolesStyle, rolesList } from 'users/model/constants';
import { buildPath } from 'core/model/lib/urlTools';
import {
	Filter,	FilterGroup, Table,	Title, Button, Tbody, Thead, Toolbox, Tr, Search, Pagination, Checkbox
} from 'table';
import { toggleModal } from 'core/ducks/ui/modal';
import { projectRoles, rolesStyle as projectRolesStyle } from '../../model/lib';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';

import T from 'modules/i18n';

class Enroll extends Component {

	constructor(props) {
		super(props);
		const { params } = props.match;
		this.initialState = {
			query: '',
			role: params.role || '',
			sort: 'lastname',
			sort_method: 'asc',
			toEnroll: [],
			projectRole: null,
		};
		this.state = {...this.initialState, page: 1, refreshing: false};
		this.fields = ['enroll', 'username', 'firstname', 'lastname', 'email', 'role'];

		const { dispatch } = props;
		this.actions = bindActionCreators({toggleModal, requestProfile}, dispatch);
		this.layout = {
			username: {sortable: true},
			firstname: {sortable: true},
			lastname: {sortable: true},
			email: {sortable: true},
			role: {type: 'translatable'},
		};
		this.badge_colors = {
			...rolesStyle,
		}

		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleToolboxReset = this.handleToolboxReset.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.postData = this.postData.bind(this);
	}

	componentDidMount() {
		const { activeCategory } = this.props;
		if (activeCategory && activeCategory !== '')
			this.fetchData(false);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.activeCategory !== this.props.activeCategory && this.props.activeCategory !== '')
			this.fetchData(false);

		if (prevState.role !== this.state.role) {
			if (this.state.role !== '') {
				this.props.history.push(buildPath(DynamicRoutes.EnrollWithSystemRole, [this.state.role]));
			} else {
				this.props.history.push(StaticRoutes.Enroll);
			}
		}

		if (
			prevState.query !== this.state.query
			|| prevProps.match.params.role !== this.props.match.params.role
			|| prevState.page !== this.state.page
			|| prevState.sort !== this.state.sort
			|| prevState.sort_method !== this.state.sort_method
		)
			this.fetchData();
	}

	handleFilterChange(event) {
		const { name, value } = event.target;
		if (this.state[name] !== value) {
			this.setState({
				[name]: value,
				page: 1
			});
		}
	}

	handleToolboxReset() {
		this.setState({
			...this.initialState,
			page: 1
		}, this.requestData);
		this.props.history.push(StaticRoutes.Enroll);
	}

	handlePageChange(page) {
		this.setState({ page }, this.requestData);
	}

	handleSortChange(sort) {
		if (sort===this.state.sort) {
			this.setState({ sort_method: this.state.sort_method==='asc' ? 'desc' : 'asc'}, this.requestData);
		} else {
			this.setState({ sort }, this.requestData);
		}
	}

	fetchData(refreshing=true) {
		const { page, sort, sort_method } = this.state;
		let url = [`enrolment/category/${this.props.activeCategory}/page/${page}/sort/${sort}/sort_method/${sort_method}`];
		if (this.state.query !== '')
			url.push(`query/${this.state.query}`);
		if (this.state.role !== '')
			url.push(`fq/core.users.role:${this.state.role}`);
		url = url.join('/');
		this.setState({refreshing}, () => {
			this.props.dispatch(
				requestData('users', url)
			).then(() => this.setState({refreshing: false}));
		});
	}

	handleCheckboxChange(args) {
		const { index, checked } = args;
		if (checked) {
			this.setState({
				toEnroll: [...this.state.toEnroll, index]
			});
		} else {
			const indx = this.state.toEnroll.indexOf(index);
			if (indx !== -1)
				this.setState({
					toEnroll: [
						...this.state.toEnroll.slice(0, indx),
						...this.state.toEnroll.slice(indx + 1)
					]
				})
		}
	}

	postData() {
		const { activeCategory } = this.props;
		const { toEnroll, projectRole } = this.state;
		const data = {category: activeCategory, uuids: toEnroll, role: projectRole};
		this.props.dispatch(
			postData('enrolment', {category: activeCategory, uuids: toEnroll, role: projectRole})
		).then(this.fetchData);
	}

	render() {

		const { pending, info, httpStatus, activeCategory } = this.props;
		const { messages } = this.props.i18n || {messages: {}};
		const { toEnroll, projectRole, refreshing } = this.state;

		if (!activeCategory || activeCategory === '')
			return (
				<Card className="animated fadeIn">
					<CardBody>
						<T>please check or create a category</T>
					</CardBody>
				</Card>
			);

		if (pending && !refreshing)
			return (<Loading/>);

		if (httpStatus !== 200 && httpStatus !== '')
			return (<ErrorPage status={httpStatus} />);

		const data = Object.keys(this.props.data).reduce((obj, key) => ({
			...obj,
			[key]: Object.keys(this.props.data[key]).filter(field => this.fields.indexOf(field) !== -1).reduce((obj2, key2) => ({
				...obj2,
				[key2]: this.props.data[key][key2]
			}), {})
		}), {});

		return (
			<div className="animated fadeIn">
				<Row>
					<Col>
						<Table>
							<Title>
								<T>enroll</T>
								<Button type="toolbox" title="filters" className="float-right"/>
							</Title>
							<Toolbox onReset={this.handleToolboxReset}>
								<Row>
									<Col className="form-group text-right">
										<Search placeholder={`${messages.search || 'search'}...`} onChange={this.handleFilterChange} name="query" />
										<FilterGroup className="w-50 mt-2 mb-0">
											<Filter onChange={this.handleFilterChange} name="role" defaultValue={this.state.role} >
												<option value="">{`${messages['choose role'] || 'choose role'}`}</option>
												{ Object.keys(rolesList).map((index) =>
													<option key={`option_${rolesList[index]}`} value={rolesList[index]}>{messages[rolesList[index]] || rolesList[index]}</option>
												)}
											</Filter>
										</FilterGroup>
										<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
									</Col>
								</Row>
							</Toolbox>
							<Thead>
								<Tr
									data={this.fields}
									sortBy={this.state.sort}
									sortMethod={this.state.sort_method}
									onClick={this.handleSortChange}
									layout={this.layout}
								/>
							</Thead>
							<Tbody refreshing={refreshing}>
								<Tr data={data} layout={this.layout} badge_colors={this.badge_colors} >
									<Button
										type="view"
										onClick={
											(data) => {
												this.props.dispatch(getAdditionalData(`users/uuid/${data.index}`))
													.then((additionalData) => {
														const joined = {...data, ...additionalData};
														let {index, ...complete_data} = joined;
														this.actions.toggleModal(true, <View data={complete_data}/>);
													})
													.catch(err => console.warn(err));
											}
										}
										title={messages.view || 'view'}
									/>
									<Checkbox list={toEnroll} onChange={this.handleCheckboxChange}/>
								</Tr>
							</Tbody>
							<Pagination
								className="mx-auto"
								page={info.page}
								total={info.total_pages}
								onClick={(page) => {
									if (page !== info.page)
										this.handlePageChange(page);
								}}
							/>
						</Table>
					</Col>
				</Row>
				{ toEnroll.length > 0 &&
					<Row>
						<Col className="text-right">
							{ projectRoles.map(role => (
								<ReactstrapButton
									key={`enroll_as_${role}_button`}
									color={projectRolesStyle[role]}
									className="mr-2"
									onClick={() => {
										this.actions.toggleModal(true,
											<Modal isOpen={true} className="modal-md">
												<ModalHeader toggle={this.actions.toggleModal}><T>enroll confirm</T></ModalHeader>
												<ModalBody>
													<T>you are about to enroll</T>{` ${toEnroll.length} `}<T>users</T>{' '}
													<T>with role</T>{' '}<em><T>{role}</T></em>.<br/>
													<T>press OK to confirm</T>.
												</ModalBody>
												<ModalFooter>
													<ReactstrapButton
														color="danger"
														onClick={() => {
															this.actions.toggleModal();
															this.setState({projectRole: role}, this.postData);
														}}
													>
														OK
													</ReactstrapButton>
												</ModalFooter>
											</Modal>
										);
									}}
								>
									<T>enroll</T>{' '}<T>as</T>{' '}<T>{role}</T>
								</ReactstrapButton>
							))}
						</Col>
					</Row>
				}
			</div>
		);
	}

}

const mapStateToProps = (state) => ({
	pending: state.list.users.pending,
	data: getData(state, 'users'),
	info: getInfo(state, 'users'),
	httpStatus: state.list.users.status,
	i18n: state.i18n,
	activeCategory: state.project.activeCategory,
});

Enroll = connect(mapStateToProps)(Enroll);

export default Enroll;