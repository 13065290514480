import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Row, Col, Card, CardBody} from 'reactstrap';
import { bindActionCreators } from 'redux';

import {
	Filter,	FilterGroup, Table,	Title, Button, Tbody, Thead, Toolbox, Tr, Search, Pagination, Checkbox
} from 'table';
import EditUser from 'users/views/modals/editUser';
import Alert from 'core/views/modals/alert';
import { ViewUser as View } from 'users/views/modals/';
import { Loading, Error } from 'core/components';
import { requestData, getData, getInfo } from 'core/ducks/list';
import { deleteData, updateData, getData as getAdditionalData } from 'core/ducks/update';
import { requestProfile } from 'core/ducks/profile';
import { toggleModal } from 'core/ducks/ui/modal';
import { ExportToCSV } from 'core/model/lib';
import { buildPath } from 'core/model/lib/urlTools';
import { projectRoles as rolesList, rolesStyle } from '../../model/lib';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';
import T from 'modules/i18n';

class Participants extends Component {

	constructor(props) {
		super(props);
		const { params } = props.match;
		this.initialState = {
			query: '',
			sort: 'lastname',
			sort_method: 'asc',
			role: params.role || '',
		};
		this.state = {...this.initialState, page: 1, refreshing: false};
		this.fields = ['username', 'firstname', 'lastname', 'email', 'enrol_role', 'weight'];

		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleToolboxReset = this.handleToolboxReset.bind(this);
		this.handleCSVExport = this.handleCSVExport.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
		this.changeRole = this.changeRole.bind(this);
		this.createUrl = this.createUrl.bind(this);

		const { dispatch } = props;
		this.actions = bindActionCreators({toggleModal, deleteData, updateData, requestProfile}, dispatch);
		this.layout = {
			username: {sortable: true},
			firstname: {sortable: true},
			lastname: {sortable: true},
			email: {sortable: true},
			enrol_role: {type: 'translatable', select: rolesList, onClick: this.changeRole},
			weight: {sortable: true},
		};
		this.badge_colors = {
			true: 'success',
			false: 'danger',
			...rolesStyle,
		}
	}

	componentDidMount() {
		const { activeCategory } = this.props;
		if (activeCategory && activeCategory !== '')
			this.fetchData(false);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.activeCategory !== this.props.activeCategory && this.props.activeCategory !== '')
			this.fetchData(false);

		if (prevState.role !== this.state.role) {
			if (this.state.role !== '') {
				this.props.history.push(buildPath(DynamicRoutes.ParticipantsWithRole, [this.state.role]));
			} else {
				this.props.history.push(StaticRoutes.Participants);
			}
		}

		if (
			prevState.query !== this.state.query
			|| prevProps.match.params.role !== this.props.match.params.role
			|| prevState.page !== this.state.page
			|| prevState.sort !== this.state.sort
			|| prevState.sort_method !== this.state.sort_method
		)
			this.fetchData();
	}

	handleFilterChange(event) {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: 1
		});
	}

	handleToolboxReset() {
		this.setState({
			...this.initialState,
			page: 1
		}, this.fetchData);
		this.props.history.push(StaticRoutes.UsersList);
	}

	handleCSVExport() {
		const { messages } = this.props.i18n || {messages: {}};
		const url = this.createUrl();
		this.props.dispatch(requestData('csv', url)).then(() => {
			let elem = ['', ...this.fields];
			elem.shift();
			let data = Object.keys(this.props.list.csv.data.values).map((index) => {
				let row = this.props.list.csv.data.values[index];
				return elem.map((col) => {
					if ( col === 'status' )
						row[col] = row['active'] ? (messages.active || 'active') : (messages.inactive || 'inactive');
					return (this.layout[col] && this.layout[col].type === 'translatable') ? messages[row[col]] : row[col];
				});
			});
			let fields = elem.map((field) => {
				return messages[field] ? messages[field] : field;
			});
			let csv = new ExportToCSV('participants.csv', fields, data);
			csv.createLink();
		});
	}

	handlePageChange(page) {
		this.setState({ page }, this.fetchData);
	}

	handleSortChange(sort) {
		if (sort===this.state.sort) {
			this.setState({ sort_method: this.state.sort_method==='asc' ? 'desc' : 'asc'}, this.fetchData);
		} else {
			this.setState({ sort }, this.fetchData);
		}
	}

	changeRole(index, role) {
		this.actions.updateData(`enrolment/category/${this.props.activeCategory}/userid/${index}`, {role}).then(() => {
			this.fetchData();
		});
	}

	fetchData(refreshing=true) {
		const url = this.createUrl();
		let promise = new Promise((resolve, reject) => {
			this.setState({refreshing}, () => {
				this.props.dispatch(requestData('users', url))
					.then(() => {
						this.setState({refreshing: false});
						resolve();
					}).catch(err=> {
						reject();
					});
			});
		});
		return promise;
	}

	createUrl() {
		let { query, page, sort, sort_method, role } = this.state;
		let url = [`enrolment/category/${this.props.activeCategory}/enrolled/true/page/${page}/sort/${sort}/sort_method/${sort_method}`];
		if (query !== '')
			url.push(`/query/${query}`);
		if (role !== '')
			url.push(`/fq/app.enrolment.role:${role}`);

		url = url.join('/');

		return url;
	}

	render() {

		const { pending, info, httpStatus, errorMsg, activeCategory } = this.props;
		const { messages } = this.props.i18n || {messages: {}};
		const { refreshing } = this.state;

		if (!activeCategory || activeCategory === '')
			return (
				<Card className="animated fadeIn">
					<CardBody>
						<T>please check or create a category</T>
					</CardBody>
				</Card>
			);

		if (pending && !refreshing)
			return (<Loading/>);

		if (httpStatus !== 200 && httpStatus !== '')
			return (<Error status={httpStatus} errorMsg={errorMsg} />);

		const data = Object.keys(this.props.data).reduce((obj, key) => ({
			...obj,
			[key]: Object.keys(this.props.data[key]).filter(field => this.fields.indexOf(field) !== -1).reduce((obj2, key2) => ({
				...obj2,
				[key2]: this.props.data[key][key2]
			}), {})
		}), {});

		return (
			<Row className="animated fadeIn">
				<Col xs="12" lg="12">
					<Table>
						<Title>
							<T>members</T>
							<Button type="toolbox" title="filters" className="float-right"/>
						</Title>
						<Toolbox onReset={this.handleToolboxReset}>
							<Row>
								<Col sm="6" className="form-group text-right">
									<Search placeholder={`${messages.search || 'search'}...`} onChange={this.handleFilterChange} name="query" />
									<FilterGroup className="w-50 mt-2 mb-0">
										<Filter onChange={this.handleFilterChange} name="role" defaultValue={this.state.role} >
											<option value="">{`${messages['choose role'] || 'choose role'}`}</option>
											{ Object.keys(rolesList).map((index) =>
												<option key={`option_${rolesList[index]}`} value={rolesList[index]}>{messages[rolesList[index]] || rolesList[index]}</option>
											)}
										</Filter>
									</FilterGroup>
								</Col>
								<Col sm="6" className="form-group py-0">
									<Row>
										<Col className="m-0 py-0 text-right">
											<Button type="csv" title={`${messages.export || 'export'} csv`} onClick={this.handleCSVExport}>
												<T>export</T> csv
											</Button>
										</Col>
									</Row>
									<Row>
										<Col className="m-0 py-0 text-right">
											<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Toolbox>
						<Thead>
							<Tr data={['', ...this.fields]} sortBy={this.state.sort} sortMethod={this.state.sort_method} onClick={this.handleSortChange} layout={this.layout} />
						</Thead>
						<Tbody refreshing={refreshing}>
							<Tr data={data} layout={this.layout} badge_colors={this.badge_colors} >
								<Button
									type="view"
									onClick={
										(data) => {
											this.props.dispatch(getAdditionalData(`users/uuid/${data.index}`)).then(() => {
												const joined = {...data, ...this.props.additionalData};
												let {index, ...complete_data} = joined;
												this.actions.toggleModal(true, <View data={complete_data}/>);
											});
										}
									}
									title={messages.view || 'view'}
								/>
								<Button
									type="drop"
									onClick={
										(index) => this.actions.toggleModal(true,
											<Alert
												toggle={() => {this.actions.toggleModal()}}
												title="unenroll confirm"
												message="do you wish to continue"
												onConfirm={() => this.actions.deleteData(`enrolment/category/${activeCategory}/userid/${index}`, false).then(() => this.fetchData())}
											/>
										)
									}
									title={messages.drop || 'drop'}
								/>
							</Tr>
						</Tbody>
						<Pagination
							className="mx-auto"
							page={info.page}
							total={info.total_pages}
							onClick={(page) => {
								if (page !== info.page)
									this.handlePageChange(page);
							}}
						/>
					</Table>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	pending: state.list.users.pending,
	data: getData(state, 'users'),
	info: getInfo(state, 'users'),
	httpStatus: state.list.users.status,
	errorMsg: state.list.users.errorMsg,
	i18n: state.i18n,
	list: state.list,
	modalOpen: state.ui.modal.modalOpen,
	additionalData: state.update.response,
	user: state.profile.user,
	activeCategory: state.project.activeCategory,
});

Participants = connect(mapStateToProps)(Participants);

export default Participants;
