// Actions

const SET_TARGET = 'admin/project/TARGET_SET';
const TOGGLE_EDIT = 'admin/project/EDIT_TOGGLED';
const EDIT_SETTINGS = 'admin/project/SETTINGS_EDIT';
const SET_ACTIVE_CATEGORY = 'admin/project/ACTIVE_CATEGORY_SET';


// Initial state

const initialState = {
	target: 'modal',
	isEditOn: true,
	editSettings: false,
	activeCategory: '',
	activeCategoryName: '',
};


// Reducer

export default (state=initialState, action) => {
	switch (action.type) {

		case SET_TARGET:
			return {
				...state,
				target: action.target,
			};

		case TOGGLE_EDIT:
			return {
				...state,
				isEditOn: !state.isEditOn,
			};

		case EDIT_SETTINGS:
			return {
				...state,
				editSettings: action.editSettings,
			};

		case SET_ACTIVE_CATEGORY:
			return {
				...state,
				activeCategory: action.activeCategory,
				activeCategoryName: action.activeCategoryName
			};

		default:
			return state;
	}
}


// Action creators

export const setTarget = (target) => ({
	type: SET_TARGET,
	target,
});

export const toggleEdit = () => ({
	type: TOGGLE_EDIT,
});

export const editSettings = (edit) => ({
	type: EDIT_SETTINGS,
	editSettings: edit,
});

export const setActiveCategory = (activeCategory, activeCategoryName='') => ({
	type: SET_ACTIVE_CATEGORY,
	activeCategory,
	activeCategoryName,
});
