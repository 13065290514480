import React, { Component } from 'react';

const Content = ({ content }) => (
	<div style={{ left: "30px", top: "30px", position: "absolute", zIndex: "1" }}>
		<span
			style={{
				fill: "#06befa",
				position: "absolute",
				color: "blue",
				fontSize: "200%",
				padding: "5px 10px",
				backgroundColor: "rgba(0,0,0,0.2)",
				borderRadius: "30px",
			}}
		>
			{content}
		</span>
	</div>
);

const imgStyle = {
	transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
};
const selectedImgStyle = {
	transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
	transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
};
const cont = {
	backgroundColor: "#eee",
	cursor: "pointer",
	overflow: "hidden",
	position: "relative"
};

class ImageWithText extends Component {

	render() {

		const {	index, photo, margin, direction, top, left, content, onClick, ...otherProps } = this.props;

		//calculate x,y scale
		const sx = (100 - (30 / photo.width) * 100) / 100;
		const sy = (100 - (30 / photo.height) * 100) / 100;
		selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;

		if (direction === "column") {
			cont.position = "absolute";
			cont.left = left;
			cont.top = top;
		}

		return (
			<div
				style={{ margin, height: photo.height, width: photo.width, ...cont }}
			>
				<Content content={content} />
				<img
					alt={photo.alt_text}
					style={{ ...imgStyle }}
					{...photo}
					{...otherProps}
				/>
			</div>
		);
	}
}

export default ImageWithText;
